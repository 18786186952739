import SVTrackedUpgradeButtonContainer from '@apps/www/src/www/containers/SVTrackedUpgradeButtonContainer';
import SVModal, { useModalClose } from '@pkgs/shared-client/components/SVModal';
import config from '@pkgs/shared-client/config';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import AllowedFeature from '@pkgs/shared/enums/AllowedFeature';
import SubscriptionTier from '@pkgs/shared/enums/SubscriptionTier';
import UpgradeButtonSource from '@pkgs/shared/enums/UpgradeButtonSource';
import SVCheckoutSubscriptionModal from './SVCheckoutSubscriptionModal';

function sourceFromAllowedFeature(reasonType: ValueOf<typeof AllowedFeature>) {
	switch (reasonType) {
		default:
		case AllowedFeature.ADD_ITEM:
			return UpgradeButtonSource.UPGRADE_REQUIRED_ITEM_LIMIT_MODAL;
		case AllowedFeature.ADD_BOARD:
			return UpgradeButtonSource.UPGRADE_REQUIRED_BOARD_LIMIT_MODAL;
		case AllowedFeature.PRINT:
			return UpgradeButtonSource.UPGRADE_REQUIRED_PRINT_MODAL;
		case AllowedFeature.PRIVATE_ITEM:
		case AllowedFeature.PRIVATE_BOARD:
			return UpgradeButtonSource.UPGRADE_REQUIRED_PRIVATE_MODAL;
		case AllowedFeature.SHARED_BOARD:
			return UpgradeButtonSource.UPGRADE_REQUIRED_SHARED_MODAL;
		case AllowedFeature.MANAGE_TEAM:
			return UpgradeButtonSource.UPGRADE_REQUIRED_MANAGE_TEAM_MODAL;
		case AllowedFeature.CREATE_SITE:
			return UpgradeButtonSource.UPGRADE_REQUIRED_CREATE_SITE_MODAL;
		case AllowedFeature.RIGHT_CLICK:
			return UpgradeButtonSource.UPGRADE_REQUIRED_RIGHT_CLICK_MODAL;
	}
}

const SVUpgradeRequiredModal = SVModal.create(
	({ reasonType }: { reasonType: ValueOf<typeof AllowedFeature> }) => {
		const close = useModalClose();

		const handleClick = useEventCallback(() => {
			if (reasonType == AllowedFeature.MANAGE_TEAM) {
				SVModal.open(SVCheckoutSubscriptionModal, { tier: SubscriptionTier.TEAM });

				// Return false to prevent the default action that navigates to /billing/ page
				return false;
			}

			close();
		});

		let title = 'You are inspired';

		if (
			reasonType == AllowedFeature.PRINT ||
			reasonType == AllowedFeature.PRIVATE_ITEM ||
			reasonType == AllowedFeature.PRIVATE_BOARD ||
			reasonType == AllowedFeature.SHARED_BOARD
		) {
			title = 'Premium only';
		} else if (reasonType == AllowedFeature.MANAGE_TEAM) {
			title = 'Team tier only';
		} else if (reasonType == AllowedFeature.CREATE_SITE) {
			title = 'Site maker tier only';
		} else if (reasonType == AllowedFeature.RIGHT_CLICK) {
			title = 'You discovered a Premium only feature.';
		}

		return (
			<>
				<SVModal.Title>{title}</SVModal.Title>
				<div className="mb-6">
					{reasonType == AllowedFeature.ADD_ITEM && (
						<>
							<p>
								You already reached your free account limit of{' '}
								{config.payment.maxFreeItems} saves. Upgrade and save unlimited
								saves.
							</p>
						</>
					)}
					{reasonType == AllowedFeature.ADD_BOARD && (
						<>
							<p>
								You already reached your free account limit of{' '}
								{config.payment.maxFreeBoards} boards. Upgrade and create unlimited
								boards.
							</p>
						</>
					)}
					{reasonType == AllowedFeature.PRINT && (
						<>
							<p>
								Upgrade to be able to create a print friendly version of your saves.
							</p>
						</>
					)}
					{reasonType == AllowedFeature.PRIVATE_ITEM && (
						<>
							<p>
								Private saves are only available on Premium accounts. Upgrade to
								start saving private saves.
							</p>
						</>
					)}
					{reasonType == AllowedFeature.PRIVATE_BOARD && (
						<>
							<p>
								Private boards are only available on Premium accounts. Upgrade to
								start creating private boards.
							</p>
						</>
					)}
					{reasonType == AllowedFeature.SHARED_BOARD && (
						<>
							<p>
								Shared boards are only available on Premium accounts. Upgrade to
								start collaborating.
							</p>
						</>
					)}
					{reasonType == AllowedFeature.MANAGE_TEAM && (
						<>
							<p>
								Teams is a feature only available for Team subscriptions. Get the
								Team plan to start adding members.
							</p>
						</>
					)}
					{reasonType == AllowedFeature.CREATE_SITE && (
						<>
							<p>
								In order to create your site you need a Site Maker subscription.
								Upgrade to the Site Maker plan and start building your site in a few
								clicks.
							</p>
						</>
					)}
					{reasonType === AllowedFeature.RIGHT_CLICK && (
						<>
							<p>
								This feature is reserved for Premium users only. You need to upgrade
								to access features like this and much more for less than $2 a week.
							</p>
						</>
					)}
				</div>
				<div>
					<SVTrackedUpgradeButtonContainer
						source={sourceFromAllowedFeature(reasonType)}
						onClick={handleClick}
					>
						{reasonType == AllowedFeature.MANAGE_TEAM
							? 'Get Team plan'
							: reasonType == AllowedFeature.CREATE_SITE
							? 'Get Site Maker plan'
							: 'Upgrade'}
					</SVTrackedUpgradeButtonContainer>
				</div>
			</>
		);
	},
	{
		className: 'max-w-[240px]',
	},
);

export default SVUpgradeRequiredModal;

import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type StartStripeCheckoutMutationMutation,
	type StartStripeCheckoutMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const StartStripeCheckoutMutation: TypedDocumentNode<
	StartStripeCheckoutMutationMutation,
	StartStripeCheckoutMutationMutationVariables
> = gql`
	mutation StartStripeCheckoutMutation($input: StartStripeCheckoutInput!) {
		startStripeCheckout(input: $input) {
			sessionID
			url
		}
	}
`;

export default StartStripeCheckoutMutation;

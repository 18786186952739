import clsx from 'clsx';
import memoize from 'lodash/memoize';
import { type SyntheticEvent } from 'react';
import animatedScrollToElement from 'scroll-to-element';
import noop from './noop';

export const isTouch = memoize(
	() => typeof document === 'object' && 'ontouchstart' in document.documentElement,
);

export const isSSR = typeof window !== 'object';

export const supportsPassive = memoize(() => {
	let supports = false;
	try {
		// @ts-ignore `test` is not a real event
		addEventListener('test', null, {
			get passive() {
				return (supports = true);
			},
		});
	} catch (e) {} // eslint-disable-line no-empty

	return supports;
});

export function getWindowScrollLeft() {
	return window.pageXOffset !== undefined
		? window.pageXOffset
		: ((document.documentElement || document.body.parentNode || document.body) as HTMLElement)
				.scrollLeft;
}

export function getWindowScrollTop() {
	return window.pageYOffset !== undefined
		? window.pageYOffset
		: ((document.documentElement || document.body.parentNode || document.body) as HTMLElement)
				.scrollTop;
}

export function setBodyClasses(classes) {
	const body = document.body;

	getClassesArray(classes).forEach((klass) => body.classList.add(klass));
}

// Uses classNames and breaks down parsed classes into an array
export function getClassesArray(classes) {
	const parsedClasses = clsx(classes);

	if (parsedClasses == null || parsedClasses == '') {
		return [];
	} else {
		return parsedClasses.split(' ');
	}
}

export function openDialog(url: string, id: string, width: number, height: number) {
	const windowOptions = 'scrollbars=yes,resizable=yes,toolbar=no,location=yes';
	const winHeight = screen.height;
	const winWidth = screen.width;
	const left = Math.round(winWidth / 2 - width / 2);
	let top = 0;
	if (winHeight > height) {
		top = Math.round(winHeight / 2 - height / 2);
	}
	return window.open(
		url,
		id,
		windowOptions + ',width=' + width + ',height=' + height + ',left=' + left + ',top=' + top,
	);
}

export function scrollToElementAnimated(element: HTMLElement, align = 'middle') {
	animatedScrollToElement(element, {
		ease: 'out-quart',
		align,
		duration: 800,
	});
}

export function scrollToElement(
	element: HTMLElement,
	offset = 0,
	smooth = true,
	scrollInElement?: boolean,
) {
	if (!element) {
		console.error('scrollToElement: Invalid element');
		return;
	}
	const metrics = getComputedStyle(element);
	const windowHeight = window.innerHeight;
	const scrollTop =
		Math.max(
			0,
			Math.min(
				document.body.scrollHeight - windowHeight,
				offsetTop(element) - windowHeight * 0.5 + parseInt(metrics.height) * 0.5,
			),
		) + offset;

	if (scrollInElement) {
		element.scrollTo({ top: scrollTop, behavior: smooth ? 'smooth' : undefined });
		return;
	}
	window.scrollTo({ top: scrollTop, behavior: smooth ? 'smooth' : undefined });
}

export function offsetTop(element: HTMLElement, stopElement: HTMLElement | null = null) {
	if (!element || (stopElement && element === stopElement)) {
		return 0;
	}

	return element.offsetTop + offsetTop(element.offsetParent as HTMLElement, stopElement);
}

export function offsetLeft(element: HTMLElement, stopElement: HTMLElement | null = null) {
	if (!element || (stopElement && element === stopElement)) {
		return 0;
	}

	return element.offsetLeft + offsetLeft(element.offsetParent as HTMLElement, stopElement);
}

export function preventDefault<T, E extends Event | SyntheticEvent>(
	fn: (event: E, ...args: T[]) => void = noop,
	...args: T[]
) {
	return function (event: E) {
		event.stopPropagation();
		event.preventDefault();

		if (fn) {
			fn(event, ...args);
		}

		// return false;
	};
}

export const emptyPreventDefault = preventDefault();

export function matchesClosest(element, selector) {
	while (element !== document) {
		if (!element || element.matches(selector)) {
			return element;
		}

		element = element.parentNode;
	}

	return null;
}

export function isModifiedEvent(event) {
	// const { target } = event.currentTarget;
	return (
		//   (target && target !== '_self') ||
		event.metaKey ||
		event.ctrlKey ||
		event.shiftKey ||
		event.altKey || // triggers resource download
		(event.nativeEvent && event.nativeEvent.which === 2)
	);
}

function isScrollableElement(element: HTMLElement) {
	const style = window.getComputedStyle(element);
	const overflowY = style.overflowY;
	const overflow = style.overflow;

	// Check for vertical scrollability
	const isVerticallyScrollable =
		overflowY === 'scroll' ||
		overflowY === 'auto' ||
		(overflowY === 'visible' && element.scrollHeight > element.clientHeight);

	// Check for scrollable overflow
	const isOverflowScrollable = overflow === 'scroll' || overflow === 'auto';

	return isVerticallyScrollable || isOverflowScrollable;
}

export function findClosestScrollableElement(element: HTMLElement) {
	// Start with the given element
	let current: HTMLElement | null = element;

	// Traverse up the DOM tree
	while (current && current !== document.body) {
		// Move to parent
		current = current.parentElement;

		// If parent exists and is scrollable, return it
		if (current && isScrollableElement(current)) {
			return current;
		}
	}

	// Fallback to window if no scrollable parent found
	return window;
}

const preloadedImages: Record<string, Promise<void>> = {};

export function preloadImage(source: string) {
	if (!preloadedImages[source]) {
		preloadedImages[source] = new Promise((resolve) => {
			const image = new Image();
			image.onload = () => {
				image.decode();

				resolve();
			};
			// Ignore errors here
			image.onerror = () => resolve();
			image.src = source;
		});
	}

	return preloadedImages[source];
}

import { useQuery } from '@apollo/client';
import AuthQuery from '@apps/www/src/www/queries/AuthQuery';

const useAuthTeam = () => {
	const { data } = useQuery(AuthQuery);

	return data?.auth?.team;
};

export default useAuthTeam;

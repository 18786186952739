import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type RemoveItemsFromBoardMutationMutation,
	type RemoveItemsFromBoardMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const RemoveItemsFromBoardMutation: TypedDocumentNode<
	RemoveItemsFromBoardMutationMutation,
	RemoveItemsFromBoardMutationMutationVariables
> = gql`
	mutation RemoveItemsFromBoardMutation($input: RemoveItemsFromBoardInput!) {
		removeItemsFromBoard(input: $input) {
			auth {
				user {
					_id
					itemsCount
				}
				settings {
					_id
					needsUpgradeReason
				}
			}
			board {
				_id
				thumbnails {
					image {
						thumbnail
					}
				}
				itemsCount
				lastItemAddedOrder
			}
			assets {
				_id
				ownBoards {
					_id
				}
			}
		}
	}
`;

export default RemoveItemsFromBoardMutation;

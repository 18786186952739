import { SVKeyboardContext } from '@pkgs/shared-client/components/SVKeyboardKey';
import clsx from 'clsx';
import { SVTransition_Child, SVTransition_Root } from './SVTransition';

const POSITIONS = {
	TOP: 'top',
	BOTTOM: 'bottom',
	RIGHT: 'right',
} as const;

type Props = {
	render: () => JSX.Element;
	isOpen?: boolean;
	position?: ValueOf<typeof POSITIONS>;
	onClose?: (event: React.UIEvent) => void;
};

const SVRetractableBar = ({ isOpen = false, position = POSITIONS.TOP, render, onClose }: Props) => (
	<>
		<SVTransition_Root show={isOpen}>
			<SVTransition_Child
				className={clsx(
					'transform-all duration-slide ease-in-out',
					'z-index-retractable-bar prevent-grid-select fixed',
					position === POSITIONS.TOP && 'top-0 left-0 w-full',
					position === POSITIONS.BOTTOM && 'bottom-0 left-0 w-full',
					position === POSITIONS.RIGHT && 'bottom-0 right-0 h-full',
				)}
				enterFrom={
					position === POSITIONS.TOP
						? '-translate-y-full'
						: position === POSITIONS.BOTTOM
						? 'translate-y-full'
						: 'translate-x-full'
				}
				enterTo={position === POSITIONS.RIGHT ? 'translate-x-0' : 'translate-y-0'}
			>
				<SVKeyboardContext>{render()}</SVKeyboardContext>
			</SVTransition_Child>
			{onClose && (
				<SVTransition_Child
					className="z-index-retractable-bar-bg transform-all duration-slide fixed inset-0 h-full w-full bg-black bg-opacity-80 ease-in-out"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					onClick={onClose}
				/>
			)}
		</SVTransition_Root>
	</>
);

SVRetractableBar.POSITIONS = POSITIONS;

export default SVRetractableBar;

import { gql } from '@apollo/client';

const UserCompanyFragment = gql`
	fragment UserCompanyFragment on Company {
		_id
		name
		url
	}
`;

export default UserCompanyFragment;

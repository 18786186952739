import { gql } from '@apollo/client';

const InviteFragment = gql`
	fragment InviteFragment on Invite {
		_id
		user {
			_id
			name
			username
			url
			avatarURL
		}
		email
	}
`;

export default InviteFragment;

import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type Auth,
	type AuthSettings,
	type UpdateAuthSettingsMutation_MutationVariables,
} from '@apps/www/src/__generated__/graphql';
import { wrap } from 'optimism';

type QueryType<K extends keyof AuthSettings> = {
	updateAuthSettings: Pick<Auth, '__typename' | '_id'> & {
		settings: Pick<Auth['settings'], '__typename' | '_id'> & Pick<AuthSettings, K>;
	};
};

const createUpdateAuthSettingsMutation = wrap(
	<T extends keyof AuthSettings>(
		fields: T[],
	): TypedDocumentNode<QueryType<T>, UpdateAuthSettingsMutation_MutationVariables> => {
		const fieldsArray = fields.sort();

		return gql`
			mutation UpdateAuthSettingsMutation_${fieldsArray.join('_')}($input: UpdateAuthSettingsInput!) {
				updateAuthSettings(input: $input) {
					_id
					settings {
						_id
						${fieldsArray.join('\n')}
					}
				}
			}
		`;
	},
	{
		makeCacheKey: (fields) => fields.sort().join('-') || '__EMPTY__',
	},
);

export default createUpdateAuthSettingsMutation;

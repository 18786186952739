import { type ViewportNameType } from '@pkgs/shared/constants';
import useUIState, { UIStateKeys } from './useUIState';

const useViewportName = (): ViewportNameType | null => {
	const [viewportName] = useUIState(UIStateKeys.VIEWPORT_NAME);

	return viewportName;
};

export default useViewportName;

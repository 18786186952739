import config from '@pkgs/shared-client/config';
import addDays from 'date-fns/add_days';
import Cookies from 'js-cookie';

const COOKIE_AGE_IN_DAYS = 365; // 1 year

const COOKIE_CLIENT_CONFIG = {
	secure: false,
	expires: addDays(new Date(), COOKIE_AGE_IN_DAYS),
	path: '/',
};

const { maxAge: _, expires: __, ...COOKIE_CLIENT_CLEAR_CONFIG } = COOKIE_CLIENT_CONFIG;

// const COOKIE_SERVER_CONFIG = {
// 	httpOnly: true,
// 	maxAge: COOKIE_AGE_IN_DAYS * 24 * 60 * 60,
// 	path: '/',
// 	sameSite: 'lax',
// 	secure: process.env.NODE_ENV === 'production',
// };

let clientAuthToken = null;

// export const setAuthTokenOnServer = (req, res, token) => {
// 	req._authToken = token;

// 	res.setHeader(
// 		'Set-Cookie',
// 		cookieParser.serialize(config.authSession.tokenKey, token, COOKIE_SERVER_CONFIG),
// 	);
// };

export const setAuthTokenOnClient = (token) => {
	clientAuthToken = token;
	Cookies.set(config.authSession.tokenKey, token, COOKIE_CLIENT_CONFIG);
};

export const getAuthTokenFromClient = () => {
	if (!clientAuthToken) {
		clientAuthToken = Cookies.get(config.authSession.tokenKey) || null;
	}
	return clientAuthToken;
};

export const clearAuthTokenOnClient = () => {
	clientAuthToken = null;

	Cookies.remove(config.authSession.tokenKey, COOKIE_CLIENT_CLEAR_CONFIG);
};

import type SVNav from '@apps/www/src/www/components/SVNav';
import { getWindowScrollTop } from '@pkgs/shared-client/helpers/dom';
import { unit } from '@pkgs/shared-client/styles/mixins';
import clsx from 'clsx';
import documentReady from 'document-ready';
import prefixAll from 'inline-style-prefixer/static';
import React, { forwardRef } from 'react';
import setStyles from 'set-styles';
import { twMerge } from 'tailwind-merge';

const POSITIONS = {
	TOP: 'top',
	BOTTOM: 'bottom',
} as const;

const _DefaultTopSpacer = forwardRef<HTMLDivElement>((props, ref) => (
	<div ref={ref} className="h-0" {...props} />
));

const defaultProps: {
	position: ValueOf<typeof POSITIONS>;
	TopSpacerComponent: typeof _DefaultTopSpacer | typeof SVNav.TopSpacer;
} = {
	position: POSITIONS.TOP,
	TopSpacerComponent: _DefaultTopSpacer,
};

type Props = Partial<typeof defaultProps> & {
	className?: string;
	isOpen: boolean;
	render: (state: State) => JSX.Element;
};

type State = {
	isHidden: boolean;
	isAtTop: boolean;
};

class SVStickyBar extends React.Component<Props, State> {
	static POSITIONS = POSITIONS;

	static defaultProps = defaultProps;

	elementRef = React.createRef<HTMLDivElement>();
	spacerRef = React.createRef<HTMLDivElement>();

	y = 0;
	top = 0;
	height = 0;

	state: State = {
		isHidden: false,
		isAtTop: true,
	};

	componentDidMount() {
		this.resize();

		documentReady(this.resize);
		window.addEventListener('resize', this.resize);
		window.addEventListener('scroll', this.update);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.resize);
		window.removeEventListener('scroll', this.update);
	}

	resizeSpacer = (height: number) => {
		const spacer = this.spacerRef.current;

		if (spacer) {
			spacer.style.height = unit(height);
			this.height = height;
		}
	};

	resize = () => {
		const element = this.elementRef.current;

		if (!element) {
			setTimeout(this.resize, 100);

			return;
		}

		const computedStyle = getComputedStyle(element);

		const newHeight = parseInt(computedStyle.height) + parseInt(computedStyle.paddingBottom);

		this.resizeSpacer(newHeight);

		this.update();
	};

	update = () => {
		const { isHidden, isAtTop } = this.state;

		const isBottom = this.props.position === POSITIONS.BOTTOM;

		const scrollY = Math.max(0, getWindowScrollTop());
		const min = Math.min(this.y, scrollY);

		const t = Math.min(0, Math.max(this.height * -1, min - scrollY));
		this.y = scrollY + t;

		if (this.top != t) {
			this.top = t;

			if (this.elementRef.current && !this.props.isOpen) {
				setStyles(
					this.elementRef.current,
					prefixAll({
						transform: `translateY(${t * (isBottom ? -1 : 1)}px)`,
					}),
				);
			}

			if (t <= this.height * -1) {
				if (!isHidden) {
					this.setState({
						isHidden: true,
					});
				}
			} else if (isHidden) {
				this.setState({
					isHidden: false,
				});
			}
		}

		if (this.y <= 0 || scrollY <= this.height) {
			if (!isAtTop) {
				this.setState({
					isAtTop: true,
				});
			}
		} else if (isAtTop) {
			this.setState({
				isAtTop: false,
			});
		}
	};

	render() {
		const { render, className, TopSpacerComponent, position } = this.props;
		const { isHidden } = this.state;

		return (
			<>
				{position === POSITIONS.TOP && TopSpacerComponent && (
					<TopSpacerComponent ref={this.spacerRef} />
				)}
				<div
					ref={this.elementRef}
					className={twMerge(
						clsx(
							'prevent-grid-select fixed top-0 left-0 right-0',
							isHidden && 'pointer-events-none',
							position === POSITIONS.TOP && 'z-index-nav',
							position === POSITIONS.BOTTOM && 'z-index-nav-small bottom-0 top-auto',
						),
						className,
					)}
				>
					{render(this.state)}
				</div>
			</>
		);
	}
}

export default SVStickyBar;

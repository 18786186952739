import usePersistentSetting, {
	PersistentSettingKeys,
} from '@apps/www/src/www/hooks/usePersistentSetting';
import useViewportName from '@apps/www/src/www/hooks/useViewportName';
import { type RootState } from '@apps/www/src/www/reducers';
import { closeGridSettings } from '@apps/www/src/www/reducers/ui';
import SVGridSettingsBar, {
	SVGridSettingsBarItem,
} from '@pkgs/shared-client/components/SVGridSettingsBar';
import SVInputSlider from '@pkgs/shared-client/components/SVInputSlider';
import SVKeyboardKey from '@pkgs/shared-client/components/SVKeyboardKey';
import SVRetractableBar from '@pkgs/shared-client/components/SVRetractableBar';
import {
	columnsConfigIDToRatio,
	columnsRatioToConfigID,
	spacingsConfigIDToRatio,
	spacingsRatioToConfigID,
} from '@pkgs/shared-client/config';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import {
	GRID_SPACINGS,
	VIEWPORTS,
	type GridColumnsConfigID,
	type ViewportNameType,
} from '@pkgs/shared/constants';
import React, { useRef } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useUnmount } from 'react-use';

const _SliderItemColumns = ({
	onChange,
	viewportName,
	columnsRatio,
}: {
	onChange: React.ComponentProps<typeof SVInputSlider>['onChange'];
	viewportName: ViewportNameType;
	columnsRatio: number;
}) => {
	const viewport = VIEWPORTS[viewportName];

	const columnsValue = columnsRatioToConfigID(viewportName, columnsRatio);
	let columnsMin = viewport.columns;
	let columnsMax = viewport.columns;

	if (viewport.minColumns && viewport.maxColumns) {
		columnsMin = viewport.minColumns;
		columnsMax = viewport.maxColumns;
	}

	return (
		<SVGridSettingsBarItem label="Size">
			<SVInputSlider
				onChange={onChange}
				min={columnsMin}
				max={columnsMax}
				value={columnsValue}
				isInverted={true}
			/>
		</SVGridSettingsBarItem>
	);
};

const _SliderItemSpacing = ({
	onChange,
	spacingRatio,
}: {
	onChange: React.ComponentProps<typeof SVInputSlider>['onChange'];
	spacingRatio: number;
}) => {
	const spacings = GRID_SPACINGS;

	const spacingValue = spacingsRatioToConfigID(spacingRatio);
	const spacingMin = spacings[0].id;
	const spacingMax = spacings[spacings.length - 1].id;
	return (
		<SVGridSettingsBarItem label="Padding">
			<SVInputSlider
				onChange={onChange}
				min={spacingMin}
				max={spacingMax}
				value={spacingValue}
			/>
		</SVGridSettingsBarItem>
	);
};

const mapStateToProps = (state: RootState) => ({
	isOpen: state.ui.isGridSettingsOpen,
});

const connector = connect(mapStateToProps, {
	closeGridSettings,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

const _SVGridSettingsContainer = ({ isOpen, closeGridSettings }: PropsFromRedux) => {
	const viewportName = useViewportName() || 'xx-large';
	const [columnsRatio, setColumnsRatio] = usePersistentSetting(
		PersistentSettingKeys.GRID_COLUMNS_RATIO,
	);
	const [spacingRatio, setSpacingRatio] = usePersistentSetting(
		PersistentSettingKeys.GRID_SPACING_RATIO,
	);

	const handleColumnsValueChange = useEventCallback((value: number) => {
		// TODO: guard number to be a column config id
		// @ts-expect-error TODO:
		setColumnsRatio(columnsConfigIDToRatio(viewportName, value));
	});

	const handleSpacingValueChange = useEventCallback((value: number) => {
		// TODO: guard number to be a spacing config id
		// @ts-expect-error TODO:
		setSpacingRatio(spacingsConfigIDToRatio(value));
	});

	const setColumnsByOffset = useEventCallback((offset: number) => {
		if ((offset < 0 && columnsRatio > 0) || (offset > 0 && columnsRatio < 1)) {
			const viewport = VIEWPORTS[viewportName];

			const columnsValue = columnsRatioToConfigID(viewportName, columnsRatio);
			let columnsMin = viewport.columns;
			let columnsMax = viewport.columns;

			if (viewport.minColumns && viewport.maxColumns) {
				columnsMin = viewport.minColumns;
				columnsMax = viewport.maxColumns;
			}

			if (
				(offset < 0 && columnsValue > columnsMin) ||
				(offset > 0 && columnsValue < columnsMax)
			) {
				setColumnsRatio(
					columnsConfigIDToRatio(
						viewportName,
						(columnsValue + offset) as GridColumnsConfigID,
					),
				);
			}
		}
	});

	const handleColumnsLess = useEventCallback(() => {
		setColumnsByOffset(-1);
	});

	const handleColumnsMore = useEventCallback(() => {
		setColumnsByOffset(1);
	});

	const barRef = useRef<HTMLElement | null>(null);
	const setBarRef = useEventCallback((ref: HTMLElement | null) => {
		if (barRef.current) {
			barRef.current.removeEventListener('mouseleave', handleMouseLeave);
		}

		if (ref) {
			const element = ref;

			if (element) {
				element.addEventListener('mouseleave', handleMouseLeave);
			}

			// Save a reference to the node
			barRef.current = element;
		}
	});

	useUnmount(() => {
		if (barRef.current) {
			barRef.current.removeEventListener('mouseleave', handleMouseLeave);
		}
	});

	const handleMouseLeave = useEventCallback((event: MouseEvent) => {
		if (isOpen && barRef.current) {
			if (event.target === barRef.current) {
				closeGridSettings();
			}
		}
	});

	return (
		<>
			<SVRetractableBar
				isOpen={isOpen}
				render={() => (
					<SVGridSettingsBar ref={setBarRef} onClose={closeGridSettings}>
						<_SliderItemColumns
							onChange={handleColumnsValueChange}
							viewportName={viewportName}
							columnsRatio={columnsRatio}
						/>
						<_SliderItemSpacing
							onChange={handleSpacingValueChange}
							spacingRatio={spacingRatio}
						/>
					</SVGridSettingsBar>
				)}
			/>
			<SVKeyboardKey keys="=" onTrigger={handleColumnsLess} />
			<SVKeyboardKey keys="-" onTrigger={handleColumnsMore} />
		</>
	);
};

const SVGridSettingsContainer = React.memo(connector(_SVGridSettingsContainer));

export default SVGridSettingsContainer;

import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type RelatedItemsQueryQuery,
	type RelatedItemsQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';
import ItemFragment from './fragments/ItemFragment';

const RelatedItemsQuery: TypedDocumentNode<
	RelatedItemsQueryQuery,
	RelatedItemsQueryQueryVariables
> = gql`
	${ItemFragment}

	query RelatedItemsQuery($shortID: NonEmptyString!, $cursor: String) {
		relatedItems(shortID: $shortID, cursor: $cursor) {
			items {
				...ItemFragment
			}
			pageInfo {
				nextCursor
			}
		}
	}
`;

export default RelatedItemsQuery;

import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type NewCommentMutationMutation,
	type NewCommentMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';
import CommentFragment from '@apps/www/src/www/queries/fragments/CommentFragment';

const NewCommentMutation: TypedDocumentNode<
	NewCommentMutationMutation,
	NewCommentMutationMutationVariables
> = gql`
	${CommentFragment}

	mutation NewCommentMutation($input: NewCommentInput!) {
		newComment(input: $input) {
			asset {
				_id
				comments {
					...CommentFragment
				}
			}
		}
	}
`;

export default NewCommentMutation;

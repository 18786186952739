const CardBrand = {
	VISA: 'visa',
	MASTERCARD: 'mastercard',
	AMEX: 'amex',
	DISCOVER: 'discover',
	MAESTRO: 'maestro',
	OTHER: 'other',
} as const;

export default CardBrand;

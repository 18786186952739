import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';

const SIZES = {
	DEFAULT: 'default',
	LARGE: 'large',
	FULL: 'full',
} as const;

const SIZES_VALUES = {
	[SIZES.DEFAULT]: 'w-80',
	[SIZES.LARGE]: 'w-96',
	[SIZES.FULL]: 'w-full justify-center flex',
};

const _Block = ({ className, children }: React.PropsWithChildren<{ className?: string }>) => (
	<div className={twMerge('w-full flex-grow pb-3 last:pb-0', className)}>{children}</div>
);

const SVFormLayoutTitle = (props: OnlyChildrenProps) => (
	<_Block>
		<h2 className="type-subtitle font-semibold" {...props} />
	</_Block>
);

const SVFormLayoutP = (props: OnlyChildrenProps) => (
	<_Block>
		<p {...props} />
	</_Block>
);

const SVFormLayoutSub = (props: OnlyChildrenProps) => (
	<_Block>
		<p className="type-small text-secondary text-center" {...props} />
	</_Block>
);

const _InlineBlock = (props: OnlyChildrenProps) => (
	<div className="flex justify-between space-x-4" {...props} />
);

type Props = React.PropsWithChildren<{
	className?: string;
	size?: ValueOf<typeof SIZES>;
}>;

const SVFormLayout = ({ className, size = SIZES.DEFAULT, children }: Props) => (
	<div
		className={twMerge(
			clsx('mx-auto box-border max-w-full pb-4 last:pb-0 ', SIZES_VALUES[size]),
			className,
		)}
	>
		{children}
	</div>
);

SVFormLayout.InlineBlock = _InlineBlock;
SVFormLayout.Block = _Block;
SVFormLayout.Title = SVFormLayoutTitle;
SVFormLayout.P = SVFormLayoutP;
SVFormLayout.Sub = SVFormLayoutSub;

SVFormLayout.SIZES = SIZES;

export default SVFormLayout;

import qsStringify from 'qs-stringify';

const appendQueryParamsToURL = (url: string, params?: { [key: string]: string }): string => {
	if (!params) {
		return url;
	}

	// Remove null and undefined
	const cleanParams = Object.keys(params).reduce((sum, key) => {
		if (params[key] !== undefined && params[key] !== null) {
			sum[key] = params[key];
		}

		return sum;
	}, {} as { [key: string]: string });

	if (Object.keys(cleanParams).length) {
		const separator = url.indexOf('?') !== -1 ? '&' : '?';

		const queryString = qsStringify(cleanParams);

		const pieces = [url];

		if (queryString) {
			pieces.push(queryString);
		}

		return pieces.join(separator);
	}

	return url;
};

export default appendQueryParamsToURL;

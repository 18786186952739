/**
 * Parses Google Analytics cid from cookie value
 */

const parseCIDFromCookie = (cookieValue: string | null) => {
	const pieces = (cookieValue || '').split('.');

	if (pieces.length >= 4) {
		return pieces[2] + '.' + pieces[3];
	}

	return null;
};

export default parseCIDFromCookie;

import { gql } from '@apollo/client';
import TeamFragment from './TeamFragment';
import UserFragment from './UserFragment';

const AuthFragment = gql`
	${UserFragment}
	${TeamFragment}

	fragment AuthFragment on Auth {
		token
		user {
			...UserFragment
		}
		settings {
			_id
			email
			workEmail
			isBeta
			isModerator
			gridSpacingRatio
			gridColumnsRatio
			onboard
			dropboxLinked
			darkMode
			emailNotifications
			emailNewsletter
			itemsSortMethod
			autoPlayGIFs
			fitToScreen
			language
			muted
			pinterestUsername
			needsUpgradeReason
			unreadNotificationsCount
			featureFlags
			maxBoardCollaborators
			maxTeamUsers
			feedBannerExpanded
			megaphones {
				_id
				title
				body
				asset
				ctaLabel
			}
			subscriptions {
				type
				status
				canManage
				provider
				planID
				subscriptionEndDate
				needsRenewSince
				autoRenew
				cardLast4
				cardBrandID
				isCardExpired
				maxSeats
			}
			purchases {
				product
				amount
				currency
				createdAt
				chargeID
				provider
			}
		}
		team {
			...TeamFragment
		}
	}
`;

export default AuthFragment;

import IconCheckSVG from '@pkgs/shared-client/img/icon-check-inlined.svg';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

const USES = {
	CHECKBOX: 'checkbox',
	RADIO: 'radio',
} as const;

type Props = {
	isSelected: boolean;
	className?: string;
	use?: ValueOf<typeof USES>;
	controlBoxClassName?: string;
};

const SVCheck = ({
	isSelected,
	className,
	use = USES.CHECKBOX,
	controlBoxClassName,
	...props
}: Props) => (
	<div className={twMerge('cursor-pointer', className)}>
		<div
			className={twMerge(
				clsx(
					'flex-center duration-over h-5 w-5 transition-colors ease-out group-hover:bg-gray-400',
					use === USES.CHECKBOX ? 'rounded-md' : 'rounded-full',
					isSelected ? 'bg-primary text-background' : 'bg-gray-600',
				),
				controlBoxClassName,
			)}
			{...props}
		>
			{isSelected && <IconCheckSVG className="mt-px" />}
		</div>
	</div>
);

SVCheck.USES = USES;

export default SVCheck;

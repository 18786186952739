import { type Item, type ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import SVGridDetailOverlayContainer from '@apps/www/src/www/containers/SVGridDetailOverlayContainer';
import SVGridDetailStandaloneContainer from '@apps/www/src/www/containers/SVGridDetailStandaloneContainer';
import SVWithNoSSR from '@apps/www/src/www/containers/SVWithNoSSR';
import filterRealItems from '@apps/www/src/www/helpers/filterRealItems';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import type SVLink from '@pkgs/shared-client/components/SVLink';
import SVModal from '@pkgs/shared-client/components/SVModal';
import SVOverlay from '@pkgs/shared-client/components/SVOverlay';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import { createSelector } from 'reselect';

const findGridItemSelector = createSelector(
	(items: ItemFragmentFragment[]) => items,
	(items: ItemFragmentFragment[], itemShortID: Item['shortID']) => itemShortID,
	(items, shortID) => {
		return items && !!items.length ? items.find((item) => item.shortID === shortID) : undefined;
	},
);

const _Overlay = SVOverlay.create(
	({
		item,
		items,
		itemShortID,
		closeURL,
	}: {
		item: ItemFragmentFragment | undefined;
		items: ItemFragmentFragment[];
		itemShortID: Item['shortID'];
		closeURL: React.ComponentProps<typeof SVLink>['to'];
	}) => {
		// This is when we open from the grid
		if (item) {
			return <SVGridDetailOverlayContainer item={item} items={items} closeURL={closeURL} />;
		}

		// This is when we open from somewhere else, like SVUsersList
		return <SVGridDetailStandaloneContainer itemShortID={itemShortID} closeURL={closeURL} />;
	},
);

const _SVGridDetailModalControllerInner = ({
	itemShortIDAsModal,
}: {
	itemShortIDAsModal: string;
}) => {
	const router = useRouter();
	const [allItems] = useUIState(UIStateKeys.CURRENT_PAGE_GRID_ITEMS);
	const items = filterRealItems(allItems || []);
	const item = findGridItemSelector(items, itemShortIDAsModal);
	const isStandaloneRef = useRef(!item);

	const { itemShortID: _, ...query } = router.query;

	const closeURL = {
		pathname: router.pathname,
		query,
	};

	// From esc key or click on background overlay
	const handleRequestClose = useEventCallback(() => {
		router.push(closeURL, undefined, { scroll: false });
	});

	// since here we are waiting for the animation to finish
	const handleAfterOpen = useEventCallback(() => {
		if (item) {
			SVGridDetailOverlayContainer.scrollToItem(item);
		}
	});

	// When deleting and item is part of a grid, item comes as null, so we need to just close the overlay.
	if (!isStandaloneRef.current && itemShortIDAsModal && !item) {
		handleRequestClose();
		return null;
	}

	return (
		<SVModal.Visible
			Component={_Overlay}
			item={item}
			items={items}
			itemShortID={itemShortIDAsModal}
			closeURL={closeURL}
			onRequestClose={handleRequestClose}
			afterOpen={handleAfterOpen}
		/>
	);
};

const _SVGridDetailModalController = () => {
	const router = useRouter();

	// Only opens as a modal if we are navigating using the internal
	// query param and not on a standalone /i/[shortID] page
	const itemShortIDAsModal = !router.pathname.startsWith('/i/')
		? router.query.itemShortID
			? String(router.query.itemShortID)
			: undefined
		: undefined;

	if (!itemShortIDAsModal) {
		return null;
	}

	return <_SVGridDetailModalControllerInner itemShortIDAsModal={itemShortIDAsModal} />;
};

const SVGridDetailModalController = SVWithNoSSR(_SVGridDetailModalController);

export default SVGridDetailModalController;

import { LoginSocialMutationMutation } from '@apps/www/src/__generated__/graphql';
import { createContext, useState } from 'react';

type SocialLoginContextType = LoginSocialMutationMutation['loginSocial']['partialInput'];

const SVSocialLoginContext = createContext<{
	value: SocialLoginContextType;
	setValue: (value: SocialLoginContextType) => void;
}>({
	value: null,
	setValue: () => null,
});

export const SVSocialLoginContextProvider = ({ children }: OnlyChildrenProps) => {
	const [value, setValue] = useState<SocialLoginContextType>(null);

	return (
		<SVSocialLoginContext.Provider
			value={{
				value,
				setValue,
			}}
		>
			{children}
		</SVSocialLoginContext.Provider>
	);
};

export default SVSocialLoginContext;

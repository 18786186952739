import { type ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import { type LikeItem } from '@pkgs/shared-client/components/SVGrid';

const isRealItem = (item: LikeItem): item is ItemFragmentFragment => {
	// Only non uploading and non static items
	if (!('upload' in item) && '__typename' in item) {
		return true;
	}

	return false;
};

const filterRealItems = (items: LikeItem[]) => {
	return items.filter(isRealItem);
};

export default filterRealItems;

/**
 * Configuration constants for both frontend and backend.
 * Stays the same regardless of which environment is used.
 */

export const ALLOWED_IMAGE_EXTENSIONS = {
	jpg: 'image/jpeg',
	jpeg: 'image/jpeg',
	gif: 'image/gif',
	gifv: 'image/gif',
	png: 'image/png',
	tiff: 'image/tiff',
	bmp: 'image/bmp',
	webp: 'image/webp',
	avif: 'image/avif',
	svg: 'image/svg+xml',
	heic: 'image/heic',
} as const;

export const AVATAR_SIZE = { width: 256, height: 256 } as const;

export const COVER_SIZE = {
	width: 1920,
	height: 600,
} as const;

export const ASSET_IMAGE_MIN_SIZE = {
	width: 100,
	height: 100,
} as const;

export const ASSET_IMAGE_SIZES = {
	thumbnail: {
		extension: 'webp',
		allowAnimated: false,
		allowTransparency: false,
		maxWidth: 420,
	},
	original: {
		extension: 'webp',
		allowAnimated: true,
		allowTransparency: true,
		maxWidth: 2400,
	},
} as const;

export const ALLOWED_VIDEO_EXTENSIONS = {
	avi: 'video/avi',
	mov: 'video/quicktime',
	mp4: 'video/mp4',
	webm: 'video/webm',
} as const;

export const ALLOWED_VIDEO_MAX_DURATION = 90; // seconds

export const ASSET_VIDEO_MIN_SIZE = {
	width: 100,
	height: 100,
} as const;

export const ASSET_VIDEO_SIZE = {
	maxWidth: 1920,
	maxHeight: 1080,
} as const;

export const OG_IMAGE_SIZE = {
	width: Math.min(1200, ASSET_IMAGE_SIZES.thumbnail.maxWidth * 2),
	height:
		Math.round((Math.min(1200, ASSET_IMAGE_SIZES.thumbnail.maxWidth * 2) * (630 / 1200)) / 2) *
		2,
};

// Limit of items on feeds when user is not logged in
export const UNLOGGED_ITEM_LIMIT = 30;
export const FREE_ITEM_LIMIT = 50;

// for ios/android notifications
export const ASSET_NOTIFICATION_IMAGE = {
	extension: 'jpg',
	maxWidth: 256,
} as const;

type ViewportConfig = {
	width: number | null;
	height: number | null;
	columns: number;
	minColumns: number | null;
	maxColumns: number | null;
};

export const VIEWPORTS = {
	small: {
		width: 599,
		height: null,
		columns: 3,
		minColumns: null,
		maxColumns: null,
	} as ViewportConfig,
	'small-landscape': {
		width: 799,
		height: 479,
		columns: 3,
		minColumns: null,
		maxColumns: null,
	} as ViewportConfig,
	'medium-portrait': {
		width: 979,
		height: null,
		columns: 3,
		minColumns: 3,
		maxColumns: 8,
	} as ViewportConfig,
	medium: {
		width: 1024,
		height: null,
		columns: 4,
		minColumns: 3,
		maxColumns: 10,
	} as ViewportConfig,
	large: {
		width: 1599,
		height: null,
		columns: 5,
		minColumns: 3,
		maxColumns: 10,
	} as ViewportConfig,
	'x-large': {
		width: 2199,
		height: null,
		columns: 6,
		minColumns: 3,
		maxColumns: 10,
	} as ViewportConfig,
	'xx-large': {
		width: null,
		height: null,
		columns: 8,
		minColumns: 4,
		maxColumns: 10,
	} as ViewportConfig,
} as const;

export type ViewportNameType = keyof typeof VIEWPORTS;

export type GridColumnsConfigID = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type GridColumnsConfigButtonSize = 0 | 1 | 2 | 3;

export const GRID_COLUMNS: Array<{
	id: GridColumnsConfigID;
	spacing: number;
	buttonSize: GridColumnsConfigButtonSize;
}> = [
	{ id: 1, spacing: 2, buttonSize: 0 },
	{ id: 2, spacing: 3, buttonSize: 0 },
	{ id: 3, spacing: 2.5, buttonSize: 0 },
	{ id: 4, spacing: 2.1, buttonSize: 0 },
	{ id: 5, spacing: 1.8, buttonSize: 0 },
	{ id: 6, spacing: 1.6, buttonSize: 0 },
	{ id: 7, spacing: 1, buttonSize: 1 },
	{ id: 8, spacing: 1, buttonSize: 2 },
	{ id: 9, spacing: 1, buttonSize: 2 },
	{ id: 10, spacing: 1, buttonSize: 3 },
	{ id: 11, spacing: 1, buttonSize: 3 },
	{ id: 12, spacing: 1, buttonSize: 3 },
];

export type GridSpacingsConfigID = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export const GRID_SPACINGS: Array<{ id: GridSpacingsConfigID; scale: number }> = [
	{ id: 1, scale: 0 },
	{ id: 2, scale: 0.25 },
	{ id: 3, scale: 0.5 },
	{ id: 4, scale: 0.75 },
	{ id: 5, scale: 1 },
	{ id: 6, scale: 1.25 },
	{ id: 7, scale: 1.5 },
	{ id: 8, scale: 1.75 },
	{ id: 9, scale: 2 },
];

export const FEATURE_FLAGS = [
	'import_tumblr',
	'tags',
	'comments',
	'related_saves',
	'course_free',

	'course', // Open to 100%
] as const;

export const VALID_USERNAME_REGEX = /^[a-z0-9_]+$/;
export const INVALID_USERNAME_REGEX = /[^a-z0-9_]/g;

import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type TrackUpgradeClickMutationMutation,
	type TrackUpgradeClickMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const TrackUpgradeClickMutation: TypedDocumentNode<
	TrackUpgradeClickMutationMutation,
	TrackUpgradeClickMutationMutationVariables
> = gql`
	mutation TrackUpgradeClickMutation($input: TrackUpgradeClickInput!) {
		trackUpgradeClick(input: $input)
	}
`;

export default TrackUpgradeClickMutation;

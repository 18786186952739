import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type UpdateBillingPlanMutationMutation,
	type UpdateBillingPlanMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';
import AuthBillingFragment from './fragments/AuthBillingFragment';

const UpdateBillingPlanMutation: TypedDocumentNode<
	UpdateBillingPlanMutationMutation,
	UpdateBillingPlanMutationMutationVariables
> = gql`
	${AuthBillingFragment}

	mutation UpdateBillingPlanMutation($input: UpdateBillingPlanInput!) {
		updateBillingPlan(input: $input) {
			auth {
				...AuthBillingFragment
			}
			redirectURL
		}
	}
`;

export default UpdateBillingPlanMutation;

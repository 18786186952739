import { type DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';
// import { supportsPassive } from './dom';

class LongPressListener {
	element: HTMLElement;
	callback: () => void;
	isFired = false;
	handleLongPressDebounced: DebouncedFunc<() => void>;

	constructor(element: HTMLElement, callback: () => void) {
		this.handleLongPressDebounced = debounce(this.handleLongPress, 600);

		this.element = element;
		this.callback = callback;

		this.element.addEventListener('mousedown', this.onMouseDown, true);
		// this.element.addEventListener('touchstart', this.onMouseDown, supportsPassive() ? {
		// 	passive: true,
		// 	capture: true
		// } : true);
		this.element.addEventListener('click', this.onClick, true);
	}

	destroy = () => {
		this.handleLongPressDebounced.cancel();

		this.element.removeEventListener('mousedown', this.onMouseDown);
		// this.element.removeEventListener('touchstart', this.onMouseDown);
		this.element.removeEventListener('click', this.onClick);
		document.removeEventListener('mouseup', this.onMouseUp);
		// document.removeEventListener('touchend', this.onMouseUp);
	};

	onMouseDown = () => {
		document.addEventListener('mouseup', this.onMouseUp, true);
		// document.addEventListener('touchend', this.onMouseUp, supportsPassive() ? {
		// 	passive: true,
		// 	capture: true
		// } : true);

		this.isFired = false;

		this.handleLongPressDebounced();
	};

	onClick = (event: MouseEvent) => {
		if (this.isFired) {
			setTimeout(() => {
				this.isFired = false;
			}, 50);

			event.preventDefault();
			event.stopPropagation();
			return false;
		}
	};

	onMouseUp = (event: MouseEvent) => {
		document.removeEventListener('mouseup', this.onMouseUp);
		// document.removeEventListener('touchend', this.onMouseUp);

		this.handleLongPressDebounced.cancel();

		if (this.isFired) {
			setTimeout(() => {
				this.isFired = false;
			}, 50);

			event.preventDefault();
			event.stopPropagation();
			return false;
		}
	};

	handleLongPress = () => {
		// TODO: Check if mouse is still over element?
		// if (this.element === event.target || this.element.contains(event.target)) {

		this.isFired = true;

		this.callback();
	};
}

const LISTENERS = new Map<HTMLElement, LongPressListener>();

export function addLongPressListener(element: HTMLElement, callback: () => void) {
	const listener = new LongPressListener(element, callback);
	LISTENERS.set(element, listener);
}

export function removeLongPressListener(element: HTMLElement) {
	const listener = LISTENERS.get(element);

	if (listener) {
		listener.destroy();
		LISTENERS.delete(element);
	}
}

/**
 * List the active subscription tiers of a user, taking into account isModerator and isBeta flags.
 * Only use this inside a page that is wrapped with SVWithRequiredLogin otherwise it'll return an empty array while loading.
 */
import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import useAuthSubscriptions from '@apps/www/src/www/hooks/useAuthSubscriptions';
import config from '@pkgs/shared-client/config';
import SubscriptionStatus from '@pkgs/shared/enums/SubscriptionStatus';
import getUserActiveSubscriptionTiersFromInfo from '@pkgs/shared/helpers/getUserActiveSubscriptionTiersFromInfo';
import { getPlanFromPlanID } from '@pkgs/shared/plans';

export default function useAuthActiveSubscriptionTiers() {
	const authSettings = useAuthSettings(['isModerator', 'isBeta']);
	const subscriptions = useAuthSubscriptions();

	const activeSubscriptions = subscriptions.filter(
		(subscription) => subscription.status === SubscriptionStatus.ACTIVE,
	);

	const activeSubscriptionTiers = activeSubscriptions.map(
		(subscription) => getPlanFromPlanID(config, subscription.planID).tier,
	);

	return getUserActiveSubscriptionTiersFromInfo({
		moderator: authSettings?.isModerator || false,
		beta: authSettings?.isBeta || false,
		activeSubscriptionTiers,
	});
}

const TeamUserRole = {
	// Can deal with billing, invite admins (plus admin)
	OWNER: 'owner',
	// Can invite/remove users, edit team details (plus editor)
	ADMIN: 'admin',
	// Can add/remove items from protected team boards
	CURATOR: 'curator',
	// Can see team boards, add/remove own items from unprotected team boards, see team feed
	EDITOR: 'editor',
} as const;

export default TeamUserRole;

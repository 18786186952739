import { gql } from '@apollo/client';

const CommentFragment = gql`
	fragment CommentFragment on Comment {
		_id
		createdAt
		user {
			_id
			name
			username
			url
			avatarURL
		}
		parent {
			_id
		}
		content
		position {
			x
			y
		}
		isTeamOnly
		isLiked
		userMentions {
			user {
				_id
				name
				username
				url
				avatarURL
			}
			indexStart
			indexEnd
		}
		likesCount
	}
`;

export default CommentFragment;

import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type InternalUpdateAssetTagsMutationMutation,
	type InternalUpdateAssetTagsMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const internalUpdateAssetTagsMutation: TypedDocumentNode<
	InternalUpdateAssetTagsMutationMutation,
	InternalUpdateAssetTagsMutationMutationVariables
> = gql`
	mutation internalUpdateAssetTagsMutation($input: UpdateAssetTagsInput!) {
		internalUpdateAssetTags(input: $input) {
			_id
			tags {
				_id
				name
			}
		}
	}
`;

export default internalUpdateAssetTagsMutation;

import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type UpdateItemMutationMutation,
	type UpdateItemMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const UpdateItemMutation: TypedDocumentNode<
	UpdateItemMutationMutation,
	UpdateItemMutationMutationVariables
> = gql`
	mutation UpdateItemMutation($input: UpdateItemInput!) {
		updateItem(input: $input) {
			_id
			name
			pageURL
			sourceDisplayURL
			sourceURL
		}
	}
`;

export default UpdateItemMutation;

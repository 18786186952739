const NotificationType = {
	NEW_FOLLOWER: 'new-follower',
	NEW_SAVE: 'new-save',
	INVITE_COLLABORATOR: 'invite-collaborator',
	INVITE_TEAM_USER: 'invite-team-user',
	INVITE_COLLABORATOR_ACCEPTED: 'invite-collaborator-accepted',
	INVITE_COLLABORATOR_REJECTED: 'invite-collaborator-rejected',
	INVITE_TEAM_USER_ACCEPTED: 'invite-team-user-accepted',
	INVITE_TEAM_USER_REJECTED: 'invite-team-user-rejected',
	MENTION_IN_COMMENT: 'mentioned-in-comment',
	NEW_COMMENT: 'new-comment',
	REPLY_TO_COMMENT: 'reply-to-comment',
} as const;

export default NotificationType;

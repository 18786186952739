import { twMerge } from 'tailwind-merge';
import SVPageMargins from './SVPageMargins';

type Props = React.PropsWithChildren<{
	className?: string;
	style?: React.HTMLProps<HTMLDivElement>['style'];
}>;

const SVPageHeader = ({ className, style, children }: Props) => (
	<SVPageMargins
		className={twMerge(
			'-sm:mb-10 mb-14 mt-8 text-pretty break-words text-center',

			className,
		)}
		style={style}
	>
		{children}
	</SVPageMargins>
);

export default SVPageHeader;

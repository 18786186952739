import { preventDefault } from '@pkgs/shared-client/helpers/dom';
import formatCount from '@pkgs/shared-client/helpers/formatCount';
import { unit } from '@pkgs/shared-client/styles/mixins';
import React from 'react';
import SVA from './SVA';
import SVPageMargins from './SVPageMargins';

const _ProgressBar = React.memo(
	({ count, completedCount }: Pick<Props, 'count' | 'completedCount'>) => (
		<div className="absolute top-0 left-0 h-1 w-full bg-gray-800">
			<div
				className="bg-brand h-1"
				style={{
					width: unit(Math.round((Math.min(count, completedCount) / count) * 100), '%'),
				}}
			/>
		</div>
	),
);

const _ProgressText = React.memo(
	({ count, completedCount }: Pick<Props, 'count' | 'completedCount'>) => (
		<span className="type-small-title text-primary mb-2 inline-block font-semibold">
			Uploading {formatCount(Math.min(count, completedCount))} of {formatCount(count)}
		</span>
	),
);

const _Cancel = React.memo(({ onCancel }: Pick<Props, 'onCancel'>) => (
	<div>
		Please don’t leave the page until it’s done. Or{' '}
		<SVA className="font-semibold" href="#" onClick={preventDefault(onCancel)}>
			cancel
		</SVA>{' '}
		anytime.
	</div>
));

type Props = {
	count: number;
	completedCount: number;
	onCancel: React.MouseEventHandler;
};

const SVGridUploadProgressBar = ({ count, completedCount, onCancel }: Props) => (
	<div className="z-index-grid-settings flex-center border-b-separator bg-background relative border-b py-4 text-center">
		<_ProgressBar count={count} completedCount={completedCount} />
		<SVPageMargins>
			<_ProgressText count={count} completedCount={completedCount} />
			<_Cancel onCancel={onCancel} />
		</SVPageMargins>
	</div>
);

export default SVGridUploadProgressBar;

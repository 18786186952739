import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import { type DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';
import { useRef } from 'react';
import { useMountedState } from 'react-use';

const useDebouncedCallback = <T extends (...args: Parameters<T>) => ReturnType<T>>(
	callback: T,
	delay: number | null = null,
) => {
	const isMounted = useMountedState();
	const debouncedFn = useEventCallback(
		(...args: Parameters<T>) => isMounted() && callback(...args),
	);
	const debouncedRef = useRef<DebouncedFunc<T> | null>(null);

	if (!debouncedRef.current && delay) {
		debouncedRef.current = debounce(debouncedFn, delay);
	}

	const fn = useEventCallback((...args: Parameters<T>) =>
		delay && debouncedRef.current ? debouncedRef.current(...args) : callback(...args),
	);

	return fn;
};

export default useDebouncedCallback;

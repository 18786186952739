import SVUserHeader from '@apps/www/src/www/components/SVUserHeader';
import useUserAndBoardPageQuery from '@apps/www/src/www/hooks/useUserAndBoardPageQuery';
import SVPageMeta from './SVPageMeta';

const SVUserHeaderContainer = () => {
	const { isOwner, user } = useUserAndBoardPageQuery();

	if (!user) {
		return null;
	}

	const meta = {
		title: user.name,
		image: user.ogImage,
	};

	return (
		<>
			<SVPageMeta {...meta} />
			<SVUserHeader asOwner={isOwner} user={user} />
		</>
	);
};

export default SVUserHeaderContainer;

import React from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
	className?: string;
};

const SVFlexSpacer = React.memo(({ className }: Props) => (
	<span className={twMerge('flex-auto', className)} />
));

export default SVFlexSpacer;

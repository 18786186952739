import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import Link from 'next/link';
import { useRouter, type NextRouter } from 'next/router';
import React from 'react';
import SVKeyboardKey, { formatTitleWithKeys, type KeyboardKeys } from './SVKeyboardKey';

export type Props = Omit<React.ComponentProps<typeof Link>, 'href' | 'onClick'> & {
	to: Parameters<NextRouter['push']>[0];
	onClick?: (event: React.UIEvent) => void;
	keys?: KeyboardKeys;
};

const SVLink = React.forwardRef<HTMLAnchorElement, Props>(
	({ children, title, keys, to, ...props }, forwardedRef) => {
		const router = useRouter();
		const onKeyDownCallback = useEventCallback(() => {
			router[props.replace ? 'replace' : 'push'](to, props.as, {
				shallow: props.shallow,
				locale: props.locale,
				scroll: props.scroll,
			});
		});

		const hasKeys = keys && keys.length;
		const titleWithKey = hasKeys ? formatTitleWithKeys(title, keys) : title;
		const keyContent = hasKeys ? (
			<SVKeyboardKey keys={keys} onTrigger={onKeyDownCallback} />
		) : null;

		return (
			<Link ref={forwardedRef} {...props} title={titleWithKey} href={to}>
				{children}
				{keyContent}
			</Link>
		);
	},
);

export default SVLink;

import { useApolloClient } from '@apollo/client';
import {
	type BoardFragmentFragment,
	type ItemFragmentFragment,
} from '@apps/www/src/__generated__/graphql';
import useAllowedBoardsToSave from '@apps/www/src/www/hooks/useAllowedBoardsToSave';
import useAllowedFeatureCheckEvent from '@apps/www/src/www/hooks/useAllowedFeatureCheckEvent';
import useAuthUser from '@apps/www/src/www/hooks/useAuthUser';
import SVNewBoardModal from '@apps/www/src/www/modals/SVNewBoardModal';
import AddItemsToBoardMutation from '@apps/www/src/www/queries/AddItemsToBoardMutation';
import BoardItemsQuery from '@apps/www/src/www/queries/BoardItemsQuery';
import RemoveItemsFromBoardMutation from '@apps/www/src/www/queries/RemoveItemsFromBoardMutation';
import TeamFeedItemsQuery from '@apps/www/src/www/queries/TeamFeedItemsQuery';
import UserItemsQuery from '@apps/www/src/www/queries/UserItemsQuery';
import type SVItemBoardsList from '@pkgs/shared-client/components/SVItemBoardsList';
import SVModal from '@pkgs/shared-client/components/SVModal';
import SVTagList from '@pkgs/shared-client/components/SVTagList';
import fieldNameFromQuery from '@pkgs/shared-client/helpers/fieldNameFromQuery';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import AllowedFeature from '@pkgs/shared/enums/AllowedFeature';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';
import BoardUserRole from '@pkgs/shared/enums/BoardUserRole';
import ItemsSortMethod from '@pkgs/shared/enums/ItemsSortMethod';
import boardUserRoleHasBoardUserRolePrivileges from '@pkgs/shared/helpers/boardUserRoleHasBoardUserRolePrivileges';

const getSelectedBoardIDs = (items: ItemFragmentFragment[]) => {
	const countByBoardID = items.reduce((acc: Record<string, number>, item) => {
		(item.asset.ownBoards || []).forEach(
			(board) => (acc[board._id] = (acc[board._id] || 0) + 1),
		);

		return acc;
	}, {});

	const itemsCount = items.length;

	return Object.keys(countByBoardID).filter((boardID) => countByBoardID[boardID] === itemsCount);
};

type Props = {
	items: ItemFragmentFragment[];
} & (
	| {
			Component: typeof SVItemBoardsList;
	  }
	| {
			Component?: typeof SVTagList;
	  }
);

const SVItemBoardsContainer = ({ items, Component = SVTagList, ...otherProps }: Props) => {
	const client = useApolloClient();
	const authUser = useAuthUser(['username']);
	const boards = useAllowedBoardsToSave(items);
	const selectedBoardIDs = getSelectedBoardIDs(items);

	const handleAdd = useEventCallback(async (board: BoardFragmentFragment) => {
		await client.mutate({
			mutation: AddItemsToBoardMutation,
			variables: {
				input: {
					boardID: board._id,
					itemIDs: items.map((item) => item._id),
				},
			},
		});

		// If any added item is not owner, clear authUser items query
		const hasNotOwnedItem = items.some((item) => !item.isOwner);
		let fieldName = fieldNameFromQuery(UserItemsQuery, {
			username: authUser?.username,
		});
		if (hasNotOwnedItem && fieldName) {
			client.cache.evict({
				id: 'ROOT_QUERY',
				fieldName,
				broadcast: false,
			});
		}

		// Remove board items query from cache to refetch when necessary
		fieldName = fieldNameFromQuery(BoardItemsQuery, { boardID: board._id });
		if (fieldName) {
			client.cache.evict({
				id: 'ROOT_QUERY',
				fieldName,
				broadcast: false,
			});
		}

		// Remove team items feed query from cache to refetch when necessary
		fieldName = fieldNameFromQuery(TeamFeedItemsQuery);
		if (fieldName) {
			client.cache.evict({
				id: 'ROOT_QUERY',
				fieldName,
				broadcast: false,
			});
		}

		client.cache.gc();
	});

	const handleRemove = useEventCallback(async (board: BoardFragmentFragment) => {
		await client.mutate({
			mutation: RemoveItemsFromBoardMutation,
			variables: {
				input: {
					boardID: board._id,
					itemIDs: items.map((item) => item._id),
				},
			},
		});

		// Remove items from cached query
		Object.values(ItemsSortMethod).forEach((sortMethod) => {
			client.cache.updateQuery(
				{
					query: BoardItemsQuery,
					variables: {
						boardID: board._id,
						sortMethod,
					},
				},
				(data) =>
					data && {
						...data,
						boardByID: {
							...data.boardByID,
							items: {
								...data.boardByID.items,
								items: data.boardByID.items.items.filter(
									(item) => !items.some((i) => i._id === item._id),
								),
							},
						},
					},
			);
		});

		// Remove team items feed query from cache to refetch when necessary
		const fieldName = fieldNameFromQuery(TeamFeedItemsQuery);
		if (fieldName) {
			client.cache.evict({
				id: 'ROOT_QUERY',
				fieldName,
				broadcast: false,
			});
		}

		client.cache.gc();
	});

	const handleNew = useAllowedFeatureCheckEvent(AllowedFeature.ADD_BOARD, () => {
		SVModal.open(SVNewBoardModal, {
			items,
			ownershipType: BoardOwnershipType.USER,
		});
	});

	const handleCanRemove = useEventCallback((board: BoardFragmentFragment) => {
		const ownsAllItems = items.every((item) => item.isOwner);

		if (ownsAllItems) {
			return true;
		}

		return boardUserRoleHasBoardUserRolePrivileges(board.role, BoardUserRole.ADMIN);
	});

	return (
		<Component
			items={items}
			boards={boards}
			selectedBoardIDs={selectedBoardIDs}
			onAdd={handleAdd}
			onRemove={handleRemove}
			onNew={handleNew}
			canRemove={handleCanRemove}
			{...otherProps}
		/>
	);
};

export default SVItemBoardsContainer;

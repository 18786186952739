/**
 * Check if logged in user has access to the course
 *
 * Needs `course` feature flag and at least one of the following:
 * - If user has feature flag `course_free`
 * - If by being a member of an enterprise team
 * - If by purchase
 *
 * For backend: Use getUserHasCourse
 */

import useAuthHasEnterprisePrivileges from '@apps/www/src/www/hooks/useAuthHasEnterprisePrivileges';
import useAuthPurchases from '@apps/www/src/www/hooks/useAuthPurchases';
import useFeatureFlag from '@apps/www/src/www/hooks/useFeatureFlag';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import PurchaseProduct from '@pkgs/shared/enums/PurchaseProduct';

const useHasCourse = (): boolean => {
	const isLoggedIn = useIsLoggedIn();
	const userHasEnterprisePrivileges = useAuthHasEnterprisePrivileges();
	const freeCourseFlag = useFeatureFlag('course_free');
	const purchases = useAuthPurchases();

	// WARNING: If the logic changes here, also update `getUserHasCourse` for the backend

	return (
		isLoggedIn &&
		(freeCourseFlag ||
			userHasEnterprisePrivileges ||
			(purchases.length > 0 &&
				purchases.some((purchase) => purchase.product === PurchaseProduct.COURSE)))
	);
};

export default useHasCourse;

type Props = {
	x: number;
	y: number;
	width: number;
	height: number;
};

const SVGridAreaSelect = ({ x, y, width, height }: Props) => (
	<div
		className="z-index-grid-area-select pointer-events-none absolute top-0 left-0 box-border border border-solid border-primary border-opacity-50 bg-primary bg-opacity-20"
		style={{
			left: x,
			top: y,
			width,
			height,
		}}
	/>
);

export default SVGridAreaSelect;

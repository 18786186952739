let supportsPromise: Promise<boolean> | null = null;

const checkSupportsWebpFromClient = async (): Promise<boolean> => {
	if (supportsPromise === null) {
		// Recommended way from Google and modernizr
		// https://stackoverflow.com/questions/5573096/detecting-webp-support
		/* supportsPromise = new Promise((resolve) => {
			const testImages = [
				// { name: 'lossy', url: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA' },
				// { name: 'lossless', url: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==' },
				// {
				// 	name: 'alpha',
				// 	url:
				// 		'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
				// },
				{ name: 'animation', url: 'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD' },
			];

			const testImage = (url, callback) => {
				const img = new Image();
				img.onload = () => {
					const result = img.width > 0 && img.height > 0;
					callback(result);
				};
				img.onerror = () => {
					callback(false);
				};
				img.src = 'data:image/webp;base64,' + url;
			};

			const first = testImages.shift();

			testImage(first.url, async (result) => {
				if (!result) {
					return resolve(false);
				}

				// for (const index in testImages) {
				// 	const result = await new Promise((resolve) =>
				// 		testImage(testImages[index].url, resolve),
				// 	);

				// 	if (!result) {
				// 		return resolve(false);
				// 	}
				// }

				resolve(true);
			});
		}); */

		// Faster but gives a false negative for Firefox 65. But no one uses that anymore, so we go with it
		// https://stackoverflow.com/questions/5573096/detecting-webp-support
		supportsPromise = Promise.resolve(
			document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') ==
				0,
		);
	}

	return supportsPromise;
};

export default checkSupportsWebpFromClient;

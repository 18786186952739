import SVModal from '@pkgs/shared-client/components/SVModal';
import NeedsUpgradeReason from '@pkgs/shared/enums/NeedsUpgradeReason';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import SVA from './SVA';
import SVButton, { SVButtonUSES } from './SVButton';
import SVLink from './SVLink';
// import addDays from 'date-fns/add_days';
// import { formatDate } from '../helpers/format';
import SVTrackedUpgradeButtonContainer from '@apps/www/src/www/containers/SVTrackedUpgradeButtonContainer';
import UpgradeButtonSource from '@pkgs/shared/enums/UpgradeButtonSource';

// const addDaysAndFormat = (since, days) => (since ? formatDate(addDays(since, days)) : null);

type Props = {
	reason: ValueOf<typeof NeedsUpgradeReason>;
};

const SVUpgradeWarning = ({ reason }: Props) => {
	const [open, setOpen] = useState(false);
	const router = useRouter();
	let title: React.ReactNode = null;
	let body: React.ReactNode = null;
	let button: React.ReactNode = null;

	const close = () => {
		setOpen(false);
	};

	useEffect(() => {
		setOpen(true);
	}, [router.route]);

	switch (reason) {
		case NeedsUpgradeReason.RENEW:
			title = (
				<>
					Your subscription
					<br />
					has&nbsp;ended
				</>
			);
			body = (
				<>
					We need your attention, please{' '}
					<SVA Component={SVLink} className="text-brand" to="/billing/">
						update your billing info
					</SVA>{' '}
					to avoid losing access to your saves.
				</>
			);
			button = (
				<SVButton Component={SVLink} use={SVButtonUSES.PRIMARY} to="/upgrade/">
					Restart subscription
				</SVButton>
			);

			break;
		case NeedsUpgradeReason.MAX_FREE_ITEMS_REACHED:
		case NeedsUpgradeReason.MAX_FREE_BOARDS_REACHED:
		default:
			title = <>Savee is made 100% with curated inspiration</>;
			body = (
				<>
					<p>
						That's why we invest so much time on picking the best content{' '}
						<span className="text-brand">to inspire our users.</span>
					</p>
					<p className="mt-2">
						Subscribe and get access to all the great content in high quality for less
						than $2 a week.
					</p>
				</>
			);
			button = (
				<SVTrackedUpgradeButtonContainer
					source={UpgradeButtonSource.REACHED_LIMIT_MODAL}
					className="w-fit"
					to={`/upgrade/?reason=${
						reason === NeedsUpgradeReason.MAX_FREE_BOARDS_REACHED
							? 'board-limit'
							: 'image-limit'
					}`}
				>
					Upgrade
				</SVTrackedUpgradeButtonContainer>
			);
			break;
	}

	return (
		<SVModal
			isOpen={open}
			onClose={close}
			className={'space-y-10'}
			modalClassName={'max-w-[420px] dark:bg-gray-900 bg-gray-100'}
		>
			<div className="flex flex-col space-y-4">
				<div>
					<h3 className="type-small-title block max-w-[300px] text-left font-medium">
						{title}
					</h3>
				</div>
				<div>
					<p className="type-small">{body}</p>
				</div>
			</div>

			{button}
		</SVModal>
	);
};

export default SVUpgradeWarning;

import { isSSR } from '@pkgs/shared-client/helpers/dom';

const SVNoSSR = ({ children }: OnlyChildrenProps) => {
	if (isSSR) {
		return null;
	}

	return <>{children}</>;
};

export default SVNoSSR;

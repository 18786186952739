import { useApolloClient } from '@apollo/client';
import { type User } from '@apps/www/src/__generated__/graphql';
import AuthQuery from '@apps/www/src/www/queries/AuthQuery';
import ToggleFollowUserMutation from '@apps/www/src/www/queries/ToggleFollowUserMutation';
import type { Props as SVButtonProps } from '@pkgs/shared-client/components/SVButton';
import SVFollowButton from '@pkgs/shared-client/components/SVFollowButton';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import SVWithTooltipErrorBoundary, {
	type Props as SVWithTooltipErrorBoundaryProps,
} from './SVWithTooltipErrorBoundary';

type Props = {
	userID: User['_id'];
	canFollow: boolean;
	isFollowing: boolean;
	isFollowingBack: boolean;
} & SVWithTooltipErrorBoundaryProps &
	Pick<SVButtonProps, 'size' | 'className'>;

const _SVFollowButtonContainer = ({
	userID,
	canFollow,
	isFollowing,
	isFollowingBack,
	size,
	className,
	showTooltipError,
}: Props) => {
	const client = useApolloClient();

	const handleClick = useEventCallback(async (event: React.UIEvent) => {
		try {
			const data = client.readQuery({
				query: AuthQuery,
			});

			const authUserID = data?.auth?.user?._id;
			const followingCount = data?.auth?.user?.followingCount || 0;

			event.stopPropagation();
			event.preventDefault();

			await client.mutate({
				mutation: ToggleFollowUserMutation,
				variables: {
					input: { userID },
				},
				optimisticResponse: authUserID
					? {
							toggleFollowUser: {
								user: {
									__typename: 'User',
									_id: userID,
									isFollowing: !isFollowing,
								},
								auth: {
									__typename: 'Auth',
									user: {
										__typename: 'User',
										_id: authUserID,
										followingCount: followingCount + (isFollowing ? -1 : 1),
									},
								},
							},
					  }
					: undefined,
			});
		} catch (e) {
			const error = e instanceof Error ? e : new Error('Unknown error');

			showTooltipError(error);
		}

		return false;
	});

	if (!canFollow) {
		return null;
	}

	return (
		<SVFollowButton
			isFollowing={isFollowing}
			isFollowingBack={isFollowingBack}
			onClick={handleClick}
			size={size}
			className={className}
		/>
	);
};

const SVFollowButtonContainer = SVWithTooltipErrorBoundary(_SVFollowButtonContainer);

export default SVFollowButtonContainer;

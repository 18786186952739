import useAuthSubscriptions from '@apps/www/src/www/hooks/useAuthSubscriptions';
import config from '@pkgs/shared-client/config';
import PaymentButtonType from '@pkgs/shared/enums/PaymentButtonType';
import SubscriptionStatus from '@pkgs/shared/enums/SubscriptionStatus';
import SubscriptionType from '@pkgs/shared/enums/SubscriptionType';
import { Plan, getPlanFromPlanID, getSubscriptionTypeFromTier } from '@pkgs/shared/plans';

export default function usePaymentButtonInfo(
	info:
		| {
				readonly buttonType: typeof PaymentButtonType.SUBSCRIBE;
				readonly plan: Plan;
		  }
		| {
				readonly buttonType: typeof PaymentButtonType.EDIT_PAYMENT_METHOD;
				readonly type: ValueOf<typeof SubscriptionType>;
		  },
) {
	const subscriptions = useAuthSubscriptions();
	const type =
		info.buttonType === PaymentButtonType.EDIT_PAYMENT_METHOD
			? info.type
			: getSubscriptionTypeFromTier(info.plan.tier);

	const activePlan = (() => {
		const activeSubscription = subscriptions.find(
			(sub) => sub.type === type && sub.status === SubscriptionStatus.ACTIVE,
		);

		if (!activeSubscription) {
			return null;
		}

		return getPlanFromPlanID(config, activeSubscription.planID);
	})();

	return {
		buttonType: info.buttonType,
		type,
		plan: info.buttonType === PaymentButtonType.SUBSCRIBE ? info.plan : activePlan,
		hasActivePlan: !!activePlan,
	};
}

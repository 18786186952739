// Links can be in the following formats:
// https://www.instagram.com/username/
// https://instagram.com/username/
// https://instagram.com/username
// instagr.am
// username
// username/
// username?utm_source=ig_profile_share&igshid=1q2w3e4r5t6y7u8i9o0p

const HOSTS = {
	instagram: ['instagram.com', 'instagr.am'],
	twitter: ['twitter.com', 'x.com'],
};

export default function normalizeSocialProfileLink(
	link: string,
	provider: keyof typeof HOSTS,
): string {
	if (!link) {
		return `https://${HOSTS[provider][0]}/`;
	}

	let normalizedUser = link
		.trim()
		.toLowerCase()
		.replace(/^(https?:\/\/)?(www\.)?/i, '')
		.replace(/\/$/, '');

	HOSTS[provider].forEach((host) => {
		normalizedUser = normalizedUser.replace(`${host}/`, '');
	});

	normalizedUser = normalizedUser.split('/')[0].split('?')[0];

	return `https://${HOSTS[provider][0]}/${normalizedUser}/`;
}

export function changeItems<T, CT>(
	items: T[],
	compare: (_item: T) => CT,
	change: Partial<T> | ((_item: T, _compareResult: CT) => Partial<T>),
): T[] {
	return (
		items &&
		items.map((item) => {
			const compareResult = compare(item);
			if (!compareResult) {
				return item;
			}

			let changeData = change;

			if (typeof change == 'function') {
				changeData = change(item, compareResult);
			}

			return {
				...item,
				...changeData,
			};
		})
	);
}

export function ensureArray<T>(
	items: NonNullable<T>[] | NonNullable<T> | null | undefined,
): NonNullable<T>[] {
	if (!items) {
		return [];
	}

	if (!Array.isArray(items)) {
		return [items];
	}

	return items;
}

/**
 * Prevents user from closing the browser if an important action is in progress.
 * Uses redux dispatch directly and not useUIState hook to avoid re-renders when value changes.
 */
import { type RootState } from '@apps/www/src/www/reducers';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import { useEffect } from 'react';
import { useStore } from 'react-redux';
import { readUIStateFromStoreState, UIStateKeys, useSetUIState } from './useUIState';

const useSetUILocked = () => {
	const store = useStore<RootState>();
	const setState = useSetUIState(UIStateKeys.LOCK_COUNT);

	const setLocked = useEventCallback((lock: boolean) => {
		const lockCount =
			readUIStateFromStoreState(store.getState(), UIStateKeys.LOCK_COUNT) + (lock ? 1 : -1);
		setState(lockCount);
	});

	return setLocked;
};

export const useUILockedWhileMounted = () => {
	const setLocked = useSetUILocked();

	useEffect(() => {
		setLocked(true);
		return () => setLocked(false);
	}, [setLocked]);
};

export default useSetUILocked;

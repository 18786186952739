import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type SuggestedMentionedUsersQueryQuery,
	type SuggestedMentionedUsersQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';

const SuggestedMentionedUsersQuery: TypedDocumentNode<
	SuggestedMentionedUsersQueryQuery,
	SuggestedMentionedUsersQueryQueryVariables
> = gql`
	query suggestedMentionedUsersQuery($type: MentionsType, $query: String) {
		suggestedMentionedUsers(type: $type, query: $query) {
			_id
			username
			name
			avatarURL
		}
	}
`;

export default SuggestedMentionedUsersQuery;
import normalizeURL from '@pkgs/esm-libs/normalize-url';
import CardBrand from '@pkgs/shared/enums/CardBrand';
import { addDays, isBefore } from 'date-fns';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import format from 'date-fns/format';
import urlParser from 'url';

export function formatDate(date: Date | null) {
	if (!date) {
		return '';
	}

	return format(date, 'MMM Do, YYYY');
}

export function isWithinNextDays(dateToCheck: Date, referenceDate: Date, days: number): boolean {
	const endDate = addDays(referenceDate, days);
	return isBefore(dateToCheck, endDate);
}

export function formatRelativeDate(date: Date) {
	return distanceInWordsToNow(date);
}

export function formatRelativeDateShort(date: Date, includeSuffix = false) {
	const formattedDate = distanceInWordsToNow(date, { addSuffix: includeSuffix });

	if (formattedDate === 'less than a minute') {
		return 'Now';
	}

	return formattedDate
		.replace(/ seconds?/, 's')
		.replace(/ minutes?/, 'm')
		.replace(/ hours?/, 'h')
		.replace(/ days?/, 'd')
		.replace(/ weeks?/, 'w')
		.replace(/ months?/, 'mo')
		.replace(/ years?/, 'y');
}

export function formatMaskedCard(last4: string, brandID: string | undefined | null) {
	if (brandID == CardBrand.AMEX) {
		return `•••• •••••• •${last4}`;
	}

	return `•••• •••• •••• ${last4}`;
}

// TODO: Delete this, move formatURL from `@pkg/shared-server/` to `@pkgs/shared/` and update call sites
export function formatURL(baseURLOrPath: string, path: string | null = null) {
	let baseURL: string | null = baseURLOrPath;

	if (arguments.length === 1 || path === null) {
		path = baseURLOrPath;
		baseURL = null;
	}

	try {
		if (!baseURL && (path.indexOf('://') === -1 || path.indexOf('://') > 5)) {
			return path || '';
		}

		if (baseURL) {
			const parsedBaseURL = urlParser.parse(baseURL);
			path = path ? urlParser.parse(path).path || '' : '';

			if (parsedBaseURL.host) {
				parsedBaseURL.protocol = parsedBaseURL.protocol || 'http:';

				if (parsedBaseURL.path !== '/') {
					path = (parsedBaseURL.path || '') + path;
				}

				path = urlParser.resolve(parsedBaseURL.protocol + '//' + parsedBaseURL.host, path);
			}
		}

		return normalizeURL(path, {
			stripTextFragment: false,
			removeTrailingSlash: false,
		});
	} catch (e) {
		return path || '';
	}
}

export function prettyURL(url: string) {
	let formatted = formatURL(url);

	if (formatted.indexOf('://') > -1 && formatted.indexOf('://') <= 5) {
		const parsed = urlParser.parse(formatted);

		formatted = formatted.substr((parsed.protocol || 'http').length + 2);
	}

	if (formatted.indexOf('www.') === 0) {
		formatted = formatted.substr(4);
	}

	return formatted;
}

export function fixURL(url: string) {
	if (url.indexOf('http') !== 0) {
		url = 'https://' + url.trim();
	}

	return url;
}

import { type Item } from '@apps/www/src/__generated__/graphql';
import { type closeOverlays } from '@apps/www/src/www/reducers/ui';
import { type Dispatch } from 'redux';
import { type RootState } from '.';
import { grid, ui } from './actionTypes';

type State = {
	isSorting: boolean;
	isSortLoading: boolean;
	isEditing: boolean;
	isAreaSelecting: boolean;
	selectedItemIDs: Item['_id'][];
	sortingItemID: Item['_id'] | null;
};

const getInitialState = (): State => {
	const state: State = {
		isSorting: false,
		isSortLoading: false,
		isEditing: false,
		isAreaSelecting: false,
		selectedItemIDs: [],
		sortingItemID: null,
	};

	return state;
};

type Action =
	| ReturnType<typeof toggleItemSort>
	| ReturnType<typeof sortLoading>
	| ReturnType<typeof sortSuccess>
	| ReturnType<typeof toggleEditing>
	| ReturnType<typeof toggleAreaSelect>
	| ReturnType<typeof updateItemSelection>
	| ReturnType<typeof clearItemsSelection>
	| ReturnType<typeof closeOverlays>;

export default function gridReducer(state = getInitialState(), action: Action): State {
	switch (action.type) {
		case grid.SORT_LOADING:
		case grid.SORT_SUCCESS:
			return {
				...state,
				isSortLoading: action.type == grid.SORT_LOADING,
			};
		case grid.TOGGLE_SORTING:
			return {
				...state,
				isSorting: action.payload.isSorting,
				sortingItemID: action.payload.isSorting ? action.payload._id : null,
			};
		case grid.TOGGLE_EDITING:
			return {
				...state,
				isEditing: !state.isEditing,
				// Clear selection when toggling off
				selectedItemIDs: state.isEditing ? [] : state.selectedItemIDs,
			};
		case grid.TOGGLE_AREA_SELECT:
			return {
				...state,
				isAreaSelecting: action.payload.isAreaSelecting,
			};
		case grid.UPDATE_ITEM_SELECTION: {
			const selectedItemIDs = [...state.selectedItemIDs];
			const index = state.selectedItemIDs.indexOf(action.payload._id);

			if (action.payload.select) {
				if (index === -1) {
					selectedItemIDs.push(action.payload._id);
				}
			} else {
				if (index !== -1) {
					selectedItemIDs.splice(index, 1);
				}
			}

			return {
				...state,
				selectedItemIDs,
			};
		}
		case grid.CLEAR_ITEMS_SELECTION:
			return {
				...state,
				selectedItemIDs: [],
			};
		case ui.CLOSE_OVERLAYS:
			return {
				...state,
				isEditing: false,
			};
		default:
			return state;
	}
}

// Action creators
export function toggleItemSort(itemID: Item['_id'], isSorting: boolean) {
	return {
		type: grid.TOGGLE_SORTING,
		payload: {
			_id: itemID,
			isSorting,
		},
	};
}

export function sortLoading() {
	return {
		type: grid.SORT_LOADING,
	};
}

export function sortSuccess() {
	return {
		type: grid.SORT_SUCCESS,
	};
}

export function toggleEditing() {
	return {
		type: grid.TOGGLE_EDITING,
	};
}

export function toggleAreaSelect(isAreaSelecting: boolean) {
	return {
		type: grid.TOGGLE_AREA_SELECT,
		payload: {
			isAreaSelecting,
		},
	};
}

export function toggleItemSelection(itemID: Item['_id']) {
	return (dispatch: Dispatch<Action>, getState: () => RootState) => {
		const state = getState();
		const isSelected = state.grid.selectedItemIDs.includes(itemID);

		dispatch(updateItemSelection(itemID, !isSelected));
	};
}

export function updateItemSelection(itemID: Item['_id'], select: boolean) {
	return {
		type: grid.UPDATE_ITEM_SELECTION,
		payload: {
			_id: itemID,
			select,
		},
	};
}

export function clearItemsSelection() {
	return {
		type: grid.CLEAR_ITEMS_SELECTION,
	};
}

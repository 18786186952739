import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type NewListMutationMutation,
	type NewListMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const NewListMutation: TypedDocumentNode<
	NewListMutationMutation,
	NewListMutationMutationVariables
> = gql`
	mutation NewListMutation($input: NewListInput!) {
		newList(input: $input) {
			url
		}
	}
`;

export default NewListMutation;

import { VIEWPORTS, type ViewportNameType } from '@pkgs/shared/constants';

type ViewportWithName = ValueOf<typeof VIEWPORTS> & { name: ViewportNameType };

const SORTED_VIEWPORTS: Array<ViewportWithName> = Object.keys(VIEWPORTS)
	.filter((key) => !!VIEWPORTS[key].width)
	.map((key) => ({ ...VIEWPORTS[key], name: key }))
	.sort((a, b) => {
		return b.width - a.width;
	});

function getViewportName(): ViewportNameType | null {
	// If on server, default to null
	if (typeof window === 'undefined') {
		return null;
	}

	const size = {
		width: window.innerWidth,
		height: window.innerHeight,
	};

	return SORTED_VIEWPORTS.reduce((foundName, viewport: ViewportWithName) => {
		if (
			viewport.width &&
			size.width <= viewport.width &&
			(!viewport.height || size.height <= viewport.height)
		) {
			return viewport.name;
		}

		return foundName;
	}, 'xx-large' as ViewportNameType);
}

export default getViewportName;

import SVPaypalPaymentButtonContainer from '@apps/www/src/www/containers/SVPaypalPaymentButtonContainer';
import SVStripePaymentButtonContainer from '@apps/www/src/www/containers/SVStripePaymentButtonContainer';
import { SVButtonSIZES } from '@pkgs/shared-client/components/SVButton';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import SVModal, { useModalClose } from '@pkgs/shared-client/components/SVModal';
import IconCloseSVG from '@pkgs/shared-client/img/icon-close-inlined.svg';
import PaymentButtonType from '@pkgs/shared/enums/PaymentButtonType';
import { Plan } from '@pkgs/shared/plans';
import { useEffect, useRef } from 'react';

const _SVCheckoutSubscriptionOverlay = ({ plan }: { plan: Plan }) => {
	const close = useModalClose();
	const stripeButtonRef = useRef<{ handleCheckoutClick: () => void }>(null);

	useEffect(() => {
		if (stripeButtonRef.current && !plan?.paypalPlanID) {
			stripeButtonRef.current.handleCheckoutClick();
			close();
		}
	}, [plan, stripeButtonRef, close]);

	// Close overlay if no plan (like ENTERPRISE)
	if (!plan) {
		close();
		return null;
	}

	return (
		<div className="theme-light bg-background h-screen w-screen">
			<div className="absolute top-10 right-10">
				<SVIconButton src={IconCloseSVG} onClick={close} />
			</div>
			<div className="flex-center -mt-5 h-full w-full flex-col space-y-20">
				<div className="type-subnav text-center font-semibold text-black">
					Choose your payment method
				</div>
				<div className="flex-center w-full max-w-[240px] flex-col space-y-9">
					<SVStripePaymentButtonContainer
						ref={stripeButtonRef}
						id="pay-with-card"
						size={SVButtonSIZES.PAYMENT}
						className="w-full rounded-lg"
						buttonType={PaymentButtonType.SUBSCRIBE}
						plan={plan}
					/>
					<div className="flex-center flex w-full text-gray-400">
						<span>or</span>
					</div>
					<SVPaypalPaymentButtonContainer
						className="w-full"
						buttonType={PaymentButtonType.SUBSCRIBE}
						plan={plan}
						isOnOverlay
					/>
				</div>
			</div>
		</div>
	);
};

const SVCheckoutSubscriptionOverlay = SVModal.create(_SVCheckoutSubscriptionOverlay, {
	layout: SVModal.LAYOUTS.OVERLAY,
	className: 'h-screen w-screen',
});

export default SVCheckoutSubscriptionOverlay;

import GraphQLErrorCode from '@pkgs/shared/enums/GraphQLErrorCode';
import { type ApolloError } from 'apollo-server-core';

const isNotFoundGraphQLError = (error: Error | ApolloError) =>
	Boolean(
		error &&
			'graphQLErrors' in error &&
			error.graphQLErrors.some(
				(graphQLError) =>
					graphQLError.extensions.code === GraphQLErrorCode.NOT_FOUND ||
					graphQLError.extensions.code === GraphQLErrorCode.FORBIDDEN,
			),
	);

export default isNotFoundGraphQLError;

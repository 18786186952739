import IconLoadingSVG from '@pkgs/shared-client/img/icon-loading-inlined.svg';
import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import SVHidden from './SVHidden';
import SVKeyboardKey, { formatTitleWithKeys, type KeyboardKeys } from './SVKeyboardKey';
import SVLink from './SVLink';

type Props = React.PropsWithChildren<
	(
		| ({
				Component: 'a';
				onClick?: (event: React.UIEvent) => void;
		  } & Pick<React.HTMLProps<HTMLAnchorElement>, 'href' | 'target' | 'rel'>)
		| ({
				Component: typeof SVLink;
		  } & Pick<React.ComponentPropsWithoutRef<typeof SVLink>, 'to' | 'onClick' | 'scroll'>)
		| ({
				Component?: 'button';
				onClick?: (event: React.UIEvent) => void;
		  } & Pick<React.HTMLProps<HTMLButtonElement>, 'type' | 'onMouseDown' | 'onMouseOver'>)
	) & {
		src: SVGComponent | null;
		title?: string;
		label?: string;
		keys?: KeyboardKeys;
		isLoading?: boolean;
		isCurrent?: boolean;
		isDisabled?: boolean;
		isPressed?: boolean;
		showCircle?: boolean;
		className?: string;
		iconClassName?: string;
		reversedCurrentStyle?: boolean;
	}
>;

const SVIconButton = React.memo(
	React.forwardRef<HTMLButtonElement & HTMLAnchorElement, Props>(
		(
			{
				src,
				Component = 'button',
				label,
				keys,
				title,
				children,
				isLoading,
				isCurrent,
				isDisabled,
				isPressed,
				showCircle,
				className,
				iconClassName,
				reversedCurrentStyle,
				...props
			},
			forwardRef,
		) => {
			let IconComponent = src;

			if (isLoading) {
				IconComponent = IconLoadingSVG;
			}

			let buttonTitle = title || label;

			if (('onClick' in props && props.onClick) || keys !== 'escape') {
				buttonTitle = formatTitleWithKeys(buttonTitle, keys);
			}

			const isCurrentStyle = reversedCurrentStyle ? !isCurrent : isCurrent;

			return (
				// @ts-expect-error fix this later
				<Component
					ref={forwardRef}
					title={buttonTitle}
					keys={Component === SVLink && !(props as any).onClick ? keys : undefined}
					className={twMerge(
						clsx(
							'text-primary duration-over group relative flex flex-shrink-0 transition-colors ease-out hover:text-gray-500',
							(isLoading || isCurrent || isDisabled) && 'disabled',
							(isCurrentStyle || isPressed) && 'text-gray-700',
							isDisabled && 'text-gray-800',
							!isLoading &&
								!isCurrentStyle &&
								!isDisabled &&
								!isPressed &&
								'text-gray-200',
						),
						className,
					)}
					{...props}
				>
					<span className="absolute ml-[50%] mt-[50%] block h-full min-h-[40px] w-full min-w-[40px] translate-x-[-50%] translate-y-[-50%]" />
					{keys && 'onClick' in props && props.onClick && (
						<SVKeyboardKey
							keys={keys}
							isDisabled={isDisabled || isLoading || isCurrent}
							onTrigger={props.onClick}
						/>
					)}
					<span
						className={twMerge(
							'flex-center relative flex min-h-full min-w-full',
							showCircle && 'min-h-[32px] min-w-[32px]',
						)}
					>
						{showCircle && (
							<span className="absolute block h-8 w-8 rounded-full bg-gray-800"></span>
						)}
						{IconComponent && (
							<IconComponent
								role={isLoading ? 'progressbar' : undefined}
								className={twMerge(
									clsx(
										'disabled relative min-h-[18px] min-w-[18px]',
										iconClassName,
									),
								)}
							/>
						)}
						{label && <SVHidden>{label}</SVHidden>}
						{children}
					</span>
				</Component>
			);
		},
	),
);

export default SVIconButton;

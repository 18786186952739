const GraphQLErrorCode = {
	REDIRECT: 'REDIRECT',
	NOT_FOUND: 'NOT_FOUND',
	NEEDS_PRO: 'NEEDS_PRO',
	FORBIDDEN: 'FORBIDDEN',
	INVALID_INPUT: 'INVALID_INPUT',
	TOO_MANY_REQUESTS: 'TOO_MANY_REQUESTS',
} as const;

export default GraphQLErrorCode;

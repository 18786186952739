import { useApolloClient, useMutation } from '@apollo/client';
import { type ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import useUserAndBoardPageQuery from '@apps/www/src/www/hooks/useUserAndBoardPageQuery';
import DeleteItemsMutation from '@apps/www/src/www/queries/DeleteItemsMutation';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import { useEffect } from 'react';
import SVWithTooltipErrorBoundary, {
	type Props as SVWithTooltipErrorBoundaryProps,
} from './SVWithTooltipErrorBoundary';

type Props = {
	items: ItemFragmentFragment[];
	render: (props: { onClick: (event: React.UIEvent) => void; isLoading: boolean }) => JSX.Element;
	onSuccess?: () => void;
} & SVWithTooltipErrorBoundaryProps;

const _SVDeleteItemsActionContainer = ({ items, onSuccess, render, showTooltipError }: Props) => {
	const client = useApolloClient();
	const { board } = useUserAndBoardPageQuery();

	const [deleteItems, { loading, error }] = useMutation(DeleteItemsMutation, {
		variables: { input: { itemIDs: items.map((item) => item._id), boardID: board?._id } },
	});

	const handleClick = useEventCallback(async () => {
		const { data } = await deleteItems();

		onSuccess && onSuccess();

		setTimeout(() => {
			const deletedItemIDs = data?.deleteItems.deletedItemIDs;

			if (deletedItemIDs) {
				items
					.filter((item) => deletedItemIDs.includes(item._id))
					.forEach((item) => client.cache.evict({ id: client.cache.identify(item) }));
			}

			client.cache.gc();
		});
	});

	useEffect(() => {
		showTooltipError(error);
	}, [showTooltipError, error]);

	return <>{render({ onClick: handleClick, isLoading: loading })}</>;
};

const SVDeleteItemsActionContainer = SVWithTooltipErrorBoundary(_SVDeleteItemsActionContainer);

export default SVDeleteItemsActionContainer;

import defaultAvatar from '@apps/www/public/img/default-avatar-1.jpg';
import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import SVLazyImage from './SVLazyImage';

/*
Used on:
- Nav
- Grid detail nav
- Feed user header
- Suggested users
- Avatars List
- Users dropdown
*/

// TODO: Use SVImage component to get loading states and background?

type Props = {
	src: HTMLImageElement['src'] | null;
	lazy?: boolean;
	className?: string;
	isFaded?: boolean;
	title?: string;
};

const SVAvatar = React.memo(({ src, lazy, className, isFaded, ...otherProps }: Props) => {
	const Component = lazy ? SVLazyImage : 'img';

	return (
		<span
			className={twMerge(
				clsx(
					'transition-filter duration-over relative flex max-h-[128px] max-w-[128px] flex-shrink-0 opacity-100 ease-out ',
					isFaded
						? 'group-hover:brightness-25 brightness-50'
						: 'group-hover:brightness-75',
				),
				className,
			)}
			{...otherProps}
		>
			<Component
				src={src || defaultAvatar}
				className="bg-image-loading h-full w-full overflow-hidden rounded-full"
			/>
			<span className="shadow-inner-1 absolute block h-full w-full rounded-full"></span>
		</span>
	);
});

export default SVAvatar;

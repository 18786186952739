import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type UpdateItemsPrivacyMutationMutation,
	type UpdateItemsPrivacyMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const UpdateItemsPrivacyMutation: TypedDocumentNode<
	UpdateItemsPrivacyMutationMutation,
	UpdateItemsPrivacyMutationMutationVariables
> = gql`
	mutation UpdateItemsPrivacyMutation($input: UpdateItemsPrivacyInput!) {
		updateItemsPrivacy(input: $input) {
			items {
				_id
				isPrivate
			}
			auth {
				user {
					_id
					itemsCount
				}
				settings {
					_id
					needsUpgradeReason
				}
			}
		}
	}
`;

export default UpdateItemsPrivacyMutation;

import React from 'react';
import { twMerge } from 'tailwind-merge';
import type SVLink from './SVLink';

type Props = React.PropsWithChildren<{ className?: string }> &
	(
		| ({ Component?: 'a' } & Pick<
				React.HTMLProps<HTMLAnchorElement>,
				'href' | 'onClick' | 'title' | 'rel' | 'target'
		  >)
		| ({ Component: 'button' } & Pick<React.HTMLProps<HTMLButtonElement>, 'onClick' | 'title'>)
		| ({
				Component: typeof SVLink;
		  } & React.ComponentPropsWithoutRef<typeof SVLink>)
	);

const SVA = React.forwardRef<HTMLAnchorElement & HTMLButtonElement, Props>(
	({ Component = 'a', className, ...props }, forwardedRef) => {
		return (
			// @ts-expect-error
			<Component
				ref={forwardedRef}
				className={twMerge(
					'group cursor-pointer text-primary transition-colors hover:text-muted',
					className,
				)}
				{...props}
			/>
		);
	},
);

export default SVA;

import { type ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import useAllowedFeatureCheckEvent from '@apps/www/src/www/hooks/useAllowedFeatureCheckEvent';
import type SVActions from '@pkgs/shared-client/components/SVActions';
import type SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import AllowedFeature from '@pkgs/shared/enums/AllowedFeature';
import React from 'react';
import SVCreateListActionContainer from './SVCreateListActionContainer';

type Props = React.PropsWithChildren<
	(
		| {
				items: ItemFragmentFragment[];
		  }
		| {
				url: string;
		  }
	) &
		(
			| {
					Component: typeof SVDropdownContent.Links.Item;
					title?: string;
			  }
			| {
					Component: typeof SVActions.Item;
					title?: string;
			  }
		)
>;

const printURL = (url: string) => window.open(url + 'print/', '_blank');

const SVPrintActionContainer = ({ Component, ...otherProps }: Props) => {
	const handleClick = useAllowedFeatureCheckEvent(AllowedFeature.PRINT, () => {
		if ('url' in otherProps) {
			printURL(otherProps.url);
		}
	});

	const handleListCreate = useEventCallback((listURL: string) => {
		printURL(listURL);
	});

	if ('items' in otherProps) {
		return (
			// @ts-expect-error fix this later
			<SVCreateListActionContainer
				Component={Component}
				onCreate={handleListCreate}
				action={AllowedFeature.PRINT}
				{...otherProps}
			/>
		);
	}

	const { url: _, ...otherPropsWithoutURL } = otherProps;

	return <Component onClick={handleClick} {...otherPropsWithoutURL} />;
};

export default SVPrintActionContainer;

import { twMerge } from 'tailwind-merge';

type Props = React.PropsWithChildren<{
	className?: string;
}>;

const SVCenter = ({ className, children }: Props) => (
	<div className={twMerge('flex-center flex-col text-center', className)}>{children}</div>
);

export default SVCenter;

import config from '@pkgs/shared-client/config';
import { ensureArray } from '@pkgs/shared-client/helpers/array';
import minimatch from 'minimatch';

const removeTrailingSlash = (url: string) => {
	if (url.endsWith('/')) {
		return url.slice(0, -1);
	}
	return url;
};

export default function matchLocation(routerAsPath: string | null, paths: string | string[]) {
	const baseURLWithoutTrailingSlash = removeTrailingSlash(config.baseURL.toString());
	// Remove baseURL from paths
	if (typeof paths === 'string') {
		paths = paths.replace(baseURLWithoutTrailingSlash, '');
	} else if (Array.isArray(paths)) {
		paths = paths.map((path) => path.replace(baseURLWithoutTrailingSlash, ''));
	}

	const pathsArray = ensureArray(paths).filter(Boolean);

	if (routerAsPath && routerAsPath.length && pathsArray.length) {
		return pathsArray.some((path) => minimatch(routerAsPath, path));
	}

	return false;
}

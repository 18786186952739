import { gql, type TypedDocumentNode } from '@apollo/client';
import { type SiteQueryQuery } from '@apps/www/src/__generated__/graphql';
import SiteFragment from './fragments/SiteFragment';

const SiteQuery: TypedDocumentNode<SiteQueryQuery> = gql`
	${SiteFragment}

	query SiteQuery {
		site {
			...SiteFragment
		}
	}
`;

export default SiteQuery;

import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type UserQueryQuery,
	type UserQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';
import UserFragment from './fragments/UserFragment';

const UserQuery: TypedDocumentNode<UserQueryQuery, UserQueryQueryVariables> = gql`
	${UserFragment}

	query UserQuery($username: NonEmptyString!) {
		userByUsername(username: $username) {
			...UserFragment
		}
	}
`;

export default UserQuery;

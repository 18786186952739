import { gql, type TypedDocumentNode } from '@apollo/client';
import { type AuthUserQueryQuery, type User } from '@apps/www/src/__generated__/graphql';
import { wrap } from 'optimism';

const getFieldsArray = (fields: (keyof User)[]) => {
	const fieldsArray = fields.sort();

	if (!fieldsArray.includes('_id')) {
		fieldsArray.push('_id');
	}

	return fieldsArray;
};

type QueryType<K extends keyof User> = Omit<AuthUserQueryQuery, 'auth'> & {
	auth: Omit<AuthUserQueryQuery['auth'], 'user'> & {
		user: Pick<User, K>;
	};
};

const createAuthUserQuery = wrap(
	<T extends keyof User>(fields: T[]): TypedDocumentNode<QueryType<T | '_id'>> => {
		const fieldsArray = getFieldsArray(fields);

		return gql`
			query AuthUserQuery_${fieldsArray.join('_')} {
				auth {
					user {
						${fieldsArray.join('\n')}
					}
				}
			}
		`;
	},
	{
		makeCacheKey: (fields) => getFieldsArray(fields).join('-'),
	},
);

export default createAuthUserQuery;

import SVTooltip from '@pkgs/shared-client/components/SVTooltip';
import React from 'react';
import ReactDOM from 'react-dom';
import wrapDisplayName from 'recompose/wrapDisplayName';

export type Props = {
	showTooltipError: (error: Error | undefined | null) => void;
};

const SVWithTooltipErrorBoundary = <P,>(WrappedComponent: React.ComponentType<P & Props>) => {
	class _SVWithTooltipErrorBoundary extends React.Component<Omit<P, 'showTooltipError'>> {
		static displayName = wrapDisplayName(WrappedComponent, 'SVWithTooltipErrorBoundary');

		_showTooltipError: Props['showTooltipError'] = (error) => {
			if (!error) {
				return;
			}

			const element = ReactDOM.findDOMNode(this) as HTMLElement; // eslint-disable-line react/no-find-dom-node

			SVTooltip.show(element, `An error ocurred.<br>${error.message}`);
		};

		render() {
			return (
				<WrappedComponent
					showTooltipError={this._showTooltipError}
					{...(this.props as P)}
				/>
			);
		}
	}

	return _SVWithTooltipErrorBoundary;
};

export default SVWithTooltipErrorBoundary;

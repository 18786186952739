import IconCloseSVG from '@pkgs/shared-client/img/icon-close-inlined.svg';
import React from 'react';
import SVIconButton from './SVIconButton';
import SVPageMargins from './SVPageMargins';

export const SVGridSettingsBarItem = ({
	children,
	label,
}: React.PropsWithChildren<{ label: string }>) => (
	<div className="flex flex-1 flex-col items-stretch text-center">
		<div>{label}</div>
		<div>
			<div className="m-auto max-w-[280px]">{children}</div>
		</div>
	</div>
);

type Props = React.PropsWithChildren<{
	onClose: (event: React.UIEvent) => void;
}>;

const SVGridSettingsBar = React.forwardRef<HTMLDivElement, Props>(
	({ onClose, children }, forwardedRef) => (
		<div
			ref={forwardedRef}
			className="z-index-grid-settings type-subtitle border-b-separator bg-background relative flex h-36 items-center border-b"
		>
			<SVPageMargins className="flex flex-1 items-center justify-between space-x-6">
				{children}
				<SVIconButton onClick={onClose} src={IconCloseSVG} label="Close" keys="escape" />
			</SVPageMargins>
			<div className="absolute left-0 -bottom-48 h-48 w-full"></div>
		</div>
	),
);

export default SVGridSettingsBar;

import { gql } from '@apollo/client';

const TeamFragment = gql`
	fragment TeamFragment on Team {
		_id
		role
		name
		email
		usersCount
		feedBoardID
		notifyUsersOnComment
		autoFollowTeamOwners
		teamFeedAsHomepage
	}
`;

export default TeamFragment;

import useAuthBoards from '@apps/www/src/www/hooks/useAuthBoards';
import useAuthUserID from '@apps/www/src/www/hooks/useAuthUserID';
import { type LikeItem } from '@pkgs/shared-client/components/SVGrid';
import allowedBoardsToSaveSelector from '@pkgs/shared-client/selectors/allowedBoardsToSaveSelector';

const useAllowedBoardsToSave = (items: LikeItem[] = []) => {
	const authUserID = useAuthUserID();
	const boards = useAuthBoards();

	if (!authUserID || !boards) {
		return [];
	}

	return allowedBoardsToSaveSelector(boards, items, authUserID);
};

export default useAllowedBoardsToSave;

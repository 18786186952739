/**
 * Returns the number of notifications to load, based on unreadNotificationsCount from authSettings.
 */

import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';

export default function useNotificationsLimit(): number {
	const authSettings = useAuthSettings(['unreadNotificationsCount']);

	return Math.max(authSettings?.unreadNotificationsCount || 0, 50);
}

import { type Item } from '@apps/www/src/__generated__/graphql';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import { type RootState } from '@apps/www/src/www/reducers';
import { toggleAreaSelect, updateItemSelection } from '@apps/www/src/www/reducers/grid';
import { type LikeItem } from '@pkgs/shared-client/components/SVGrid';
import SVGridAreaSelect from '@pkgs/shared-client/components/SVGridAreaSelect';
import {
	GRID_ITEM_CLASS_NAME,
	getShortIDFromElementID,
} from '@pkgs/shared-client/components/SVGridItem';
import {
	getWindowScrollLeft,
	getWindowScrollTop,
	offsetLeft,
	offsetTop,
} from '@pkgs/shared-client/helpers/dom';
import closest from 'dom-closest';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

type Rect = {
	x: number;
	y: number;
	width: number;
	height: number;
};

function isTouchingRect(areaReact: Rect, itemRect: Rect): boolean {
	return (
		areaReact.x < itemRect.x + itemRect.width &&
		areaReact.x + areaReact.width > itemRect.x &&
		areaReact.y < itemRect.y + itemRect.height &&
		areaReact.y + areaReact.height > itemRect.y
	);
}

const mapStateToProps = (state: RootState) => ({
	isEditing: state.grid.isEditing,
	isAreaSelecting: state.grid.isAreaSelecting,
	selectedItemIDs: state.grid.selectedItemIDs,
});

const connector = connect(mapStateToProps, {
	toggleAreaSelect,
	updateItemSelection,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
	items: Array<LikeItem>;
};

type State = {
	selectAreaRect: {
		x: number;
		y: number;
		width: number;
		height: number;
	};
};

class _SVGridEditAreaSelectContainerInner extends React.Component<Props, State> {
	selectAreaStartOffset: {
		x: number;
		y: number;
	} | null = null;
	selectAreaLastClientOffset: {
		x: number;
		y: number;
	} | null = null;
	selectAreaSelectedItemIDs: Item['_id'][] | null = null;
	elementsData: Array<{
		item: LikeItem;
		element: HTMLElement;
		rect: Rect;
	}> = [];

	constructor(props: Props) {
		super(props);

		this.state = {
			selectAreaRect: {
				x: 0,
				y: 0,
				width: 0,
				height: 0,
			},
		};
	}

	componentDidMount = () => {
		document.addEventListener('mousedown', this.handleMouseDown);
		document.addEventListener('mouseup', this.handleMouseUp);
	};

	componentWillUnmount = () => {
		document.removeEventListener('mousedown', this.handleMouseDown);
		document.removeEventListener('mouseup', this.handleMouseUp);
	};

	componentDidUpdate() {
		this.updateElementsCache();
	}

	handleMouseDown = (event: MouseEvent) => {
		if (!this.props.isEditing) {
			return;
		}

		if (closest(event.target as HTMLElement, '.prevent-grid-select')) {
			return;
		}

		this.selectAreaStartOffset = { x: event.pageX, y: event.pageY };
		this.selectAreaSelectedItemIDs = [];

		document.addEventListener('mousemove', this.handleMouseMove);
		window.addEventListener('scroll', this.handleMouseMove);
	};

	handleMouseUp = (event: MouseEvent) => {
		document.removeEventListener('mousemove', this.handleMouseMove);
		window.removeEventListener('scroll', this.handleMouseMove);

		if (this.props.isAreaSelecting) {
			event.preventDefault();
			event.stopPropagation();

			this.selectAreaStartOffset = null;
			this.selectAreaSelectedItemIDs = null;

			this.props.toggleAreaSelect(false);

			return false;
		}
	};

	handleMouseMove = (event: Event | MouseEvent) => {
		if (!this.props.isEditing) {
			if (this.props.isAreaSelecting) {
				this.props.toggleAreaSelect(false);
			}

			return;
		}

		if ('clientX' in event && (event.clientX || event.clientY)) {
			this.selectAreaLastClientOffset = {
				x: event.clientX,
				y: event.clientY,
			};
		}

		if (!this.selectAreaStartOffset) {
			return;
		}

		let pageX = 'pageX' in event ? event.pageX : 0;
		let pageY = 'pageY' in event ? event.pageY : 0;

		if (this.selectAreaLastClientOffset && (!pageX || !pageY)) {
			pageX = getWindowScrollLeft() + this.selectAreaLastClientOffset.x;
			pageY = getWindowScrollTop() + this.selectAreaLastClientOffset.y;
		}

		const rect = {
			x: this.selectAreaStartOffset.x,
			y: this.selectAreaStartOffset.y,
			width: pageX - this.selectAreaStartOffset.x,
			height: pageY - this.selectAreaStartOffset.y,
		};

		if (rect.width < 0) {
			rect.x = rect.x + rect.width;
			rect.width = this.selectAreaStartOffset.x - rect.x;
		}

		if (rect.height < 0) {
			rect.y = rect.y + rect.height;
			rect.height = this.selectAreaStartOffset.y - rect.y;
		}

		if (
			this.selectAreaStartOffset &&
			!this.props.isAreaSelecting &&
			(rect.width > 5 || rect.height > 5)
		) {
			this.setState({
				selectAreaRect: rect,
			});

			this.props.toggleAreaSelect(true);
		}

		if (this.props.isAreaSelecting) {
			rect.width = Math.min(rect.width, getWindowScrollLeft() + window.innerWidth - rect.x);
			rect.height = Math.min(rect.height, getWindowScrollTop() + window.innerHeight - rect.y);

			this.setState({
				selectAreaRect: rect,
			});

			const selectedIDs: Item['_id'][] = [];
			const currentSelectedItemIDs = this.props.selectedItemIDs;

			this.elementsData.forEach((data) => {
				if (data.item && isTouchingRect(rect, data.rect)) {
					const itemID = data.item._id;

					if (!currentSelectedItemIDs.includes(itemID)) {
						this.props.updateItemSelection(itemID, true);
					}

					selectedIDs.push(itemID);
				}
			});

			// Loop through remaining previously selected ids that are not inside/touching
			// the select area anymore
			if (this.selectAreaSelectedItemIDs) {
				this.selectAreaSelectedItemIDs.forEach((itemID) => {
					// currentSelectedItemIDs.forEach((itemID) => {
					if (!selectedIDs.includes(itemID)) {
						this.props.updateItemSelection(itemID, false);
					}
				});
			}

			this.selectAreaSelectedItemIDs = selectedIDs;
		}
	};

	updateElementsCache = () => {
		this.elementsData = Array.from(document.querySelectorAll(`.${GRID_ITEM_CLASS_NAME}`))
			.map((element) => {
				const itemID = getShortIDFromElementID(element.id);
				if (!itemID) {
					return null;
				}

				const item = this.props.items.find((item) => item.shortID == itemID);

				if (!item) {
					return null;
				}
				const computedStyle = getComputedStyle(element);

				const rect = {
					x: offsetLeft(element as HTMLElement),
					y: offsetTop(element as HTMLElement),
					width: parseInt(computedStyle.width),
					height: parseInt(computedStyle.height),
				};

				return {
					element: element as HTMLElement,
					item,
					rect,
				};
			})
			.filter(Boolean)
			// filter out uploading/queued items
			.filter((data) => !('upload' in data.item));
	};

	render() {
		const { isAreaSelecting } = this.props;
		const { selectAreaRect } = this.state;

		if (!isAreaSelecting) {
			return null;
		}

		return <SVGridAreaSelect {...selectAreaRect} />;
	}
}

const SVGridEditAreaSelectContainerInner = React.memo(
	connector(_SVGridEditAreaSelectContainerInner),
);

const SVGridEditAreaSelectContainer = () => {
	const [items] = useUIState(UIStateKeys.CURRENT_PAGE_GRID_ITEMS);

	return <SVGridEditAreaSelectContainerInner items={items} />;
};

export default SVGridEditAreaSelectContainer;

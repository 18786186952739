import { gql, type TypedDocumentNode } from '@apollo/client';
import { type ClearUnreadNotificationsCountMutationMutation } from '@apps/www/src/__generated__/graphql';

const ClearUnreadNotificationsCountMutation: TypedDocumentNode<ClearUnreadNotificationsCountMutationMutation> = gql`
	mutation ClearUnreadNotificationsCountMutation {
		clearUnreadNotificationsCount {
			_id
			settings {
				_id
				unreadNotificationsCount
			}
		}
	}
`;

export default ClearUnreadNotificationsCountMutation;

import { useCallback, useRef } from 'react';
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

/**
 * Same as React.useCallback but the callback gets memoized to not trigger a re-render
 *
 * @see https://github.com/formium/formik/blob/2d613c11a67b1c1f5189e21b8d61a9dd8a2d0a2e/packages/formik/src/Formik.tsx#L1193-L1205
 */

function useEventCallback<T>(fn: T): T {
	const ref = useRef(fn);

	// we copy a ref to the callback scoped to the current state/props on each render
	useIsomorphicLayoutEffect(() => {
		ref.current = fn;
	});

	// @ts-expect-error
	return useCallback((...args) => ref.current.apply(null, args), []);
}

export default useEventCallback;

import { useGridSpacingsFromUserSettings } from '@pkgs/shared-client/hooks/useGridSpacings';
import React, { useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const WIDTHS = {
	DEFAULT: 'default',
	COMPACT: 'compact',
	FULL: 'full',
} as const;

const WIDTHS_VALUES = {
	[WIDTHS.DEFAULT]: 'mx-[var(--page-margin)]',
	[WIDTHS.COMPACT]: 'mx-[calc(var(--page-margin)*4)]',
	[WIDTHS.FULL]: 'mx-[0]',
};

type Props = React.PropsWithChildren<{
	className?: string;
	width?: ValueOf<typeof WIDTHS>;
	style?: React.HTMLProps<HTMLDivElement>['style'];
	id?: React.HTMLProps<HTMLDivElement>['id'];
}>;

// This is added at the root to update the body element css variable
export const SVPageMarginsSpacingUpdater = () => {
	const { spacingPerc, columnConfig } = useGridSpacingsFromUserSettings();

	const totalSpacingPerc = spacingPerc / (columnConfig?.id || 1);

	useEffect(() => {
		document.body.style.setProperty('--page-margin-max', `${totalSpacingPerc}%`);
	}, [totalSpacingPerc]);

	return null;
};

const SVPageMargins = ({ className, width = WIDTHS.DEFAULT, style, children, id }: Props) => {
	const containerRef = useRef<HTMLDivElement>(null);

	return (
		<div
			className={twMerge('flex-grow', WIDTHS_VALUES[width], className)}
			style={style}
			id={id}
			ref={containerRef}
		>
			{children}
		</div>
	);
};

export default SVPageMargins;

import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type ItemDetailsQuery as ItemDetailsQueryQuery,
	type ItemDetailsQueryVariables as ItemDetailsQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';
import CommentFragment from '@apps/www/src/www/queries/fragments/CommentFragment';

const ItemDetailsQuery: TypedDocumentNode<
	ItemDetailsQueryQuery,
	ItemDetailsQueryQueryVariables
> = gql`
	${CommentFragment}

	query ItemDetails($shortID: NonEmptyString!) {
		itemByShortID(shortID: $shortID) {
			_id
			asset {
				_id
				hasRelated
				ogImage {
					width
					height
					url
				}
				users {
					_id
					name
					username
					url
					avatarURL
					isPro
					canFollow
					isFollowing
					isFollowingBack
				}
				tags {
					name
				}
				comments {
					...CommentFragment
				}
			}
		}
	}
`;

export default ItemDetailsQuery;

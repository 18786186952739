import { type User, type UserFragmentFragment } from '@apps/www/src/__generated__/graphql';
import BoardUserRole from '@pkgs/shared/enums/BoardUserRole';
import boardUserRoleHasBoardUserRolePrivileges from '@pkgs/shared/helpers/boardUserRoleHasBoardUserRolePrivileges';

// Filter out boards that the user didn't accept as collaborator yet
const allowedBoardsToSaveSelector = (
	boards: UserFragmentFragment['boards'],
	items,
	userID: User['_id'] | null | undefined,
): UserFragmentFragment['boards'] => {
	return boards.filter((board) => {
		if (!userID) {
			return true;
		}

		// Is at least an editor
		if (boardUserRoleHasBoardUserRolePrivileges(board.role, BoardUserRole.EDITOR)) {
			return true;
		}

		const hasItem = items.some((item) =>
			(item.asset?.ownBoards || []).some((assetBoard) => assetBoard._id === board._id),
		);

		if (hasItem) {
			return true;
		}

		return false;
	});
};

export default allowedBoardsToSaveSelector;

/**
 * Returns the date the subscription became expired, only if the user lost PRO privileges.
 */
import useAuthActiveSubscriptionTiers from '@apps/www/src/www/hooks/useAuthActiveSubscriptionTiers';
import useAuthSubscriptions from '@apps/www/src/www/hooks/useAuthSubscriptions';
import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import SubscriptionStatus from '@pkgs/shared/enums/SubscriptionStatus';
import SubscriptionType from '@pkgs/shared/enums/SubscriptionType';
import TeamUserRole from '@pkgs/shared/enums/TeamUserRole';

export default function useAuthSubscriptionIsExpired(): {
	needsRenewSince: Date;
} | null {
	const activeTiers = useAuthActiveSubscriptionTiers();
	const subscriptions = useAuthSubscriptions();
	const team = useAuthTeam();

	if (activeTiers.length > 0) {
		return null;
	}

	// First check for an expired TEAM subscription
	const expiredTeamSubscription = subscriptions.find(
		(subscription) =>
			subscription.type === SubscriptionType.TEAM &&
			subscription.status === SubscriptionStatus.EXPIRED,
	);

	if (
		expiredTeamSubscription &&
		expiredTeamSubscription.needsRenewSince &&
		team &&
		team.role === TeamUserRole.OWNER
	) {
		return {
			needsRenewSince: expiredTeamSubscription.needsRenewSince,
		};
	}

	// Then an expired INDIVIDUAL subscription
	const expiredIndividualSubscription = subscriptions.find(
		(subscription) =>
			subscription.type === SubscriptionType.INDIVIDUAL &&
			subscription.status === SubscriptionStatus.EXPIRED,
	);

	if (expiredIndividualSubscription && expiredIndividualSubscription.needsRenewSince) {
		return {
			needsRenewSince: expiredIndividualSubscription.needsRenewSince,
		};
	}

	return null;
}

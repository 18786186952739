import en from '@apps/www/locales/en';
import pt from '@apps/www/locales/pt';
import Language from '@pkgs/shared/enums/Language';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
	resources: {
		en: en,
		pt: pt,
	},
	lng: 'en',
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;

export const updateI18nLanguage = (language: ValueOf<typeof Language>) => {
	i18n.changeLanguage(language.split('_')[0].toLowerCase());
};

import { closeOverlays } from '@apps/www/src/www/reducers/ui';
import SVModal from '@pkgs/shared-client/components/SVModal';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { usePrevious } from 'react-use';

/**
 * Dispatch an action to close open overlays when the route location changes
 */
const SVRouterController = () => {
	const router = useRouter();
	const dispatch = useDispatch();
	const previousAsPath = usePrevious(router.asPath);
	const asPath = router.asPath;

	useEffect(() => {
		if (asPath !== previousAsPath) {
			SVModal.closeAll();
			dispatch(closeOverlays());
		}
	}, [asPath, previousAsPath, dispatch]);

	return null;
};

export default SVRouterController;

import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type NewTeamMutationMutation,
	type NewTeamMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';
import TeamFragment from './fragments/TeamFragment';

const NewTeamMutation: TypedDocumentNode<
	NewTeamMutationMutation,
	NewTeamMutationMutationVariables
> = gql`
	${TeamFragment}

	mutation NewTeamMutation($input: NewTeamInput!) {
		newTeam(input: $input) {
			team {
				_id
			}
			auth {
				team {
					...TeamFragment
				}
			}
		}
	}
`;

export default NewTeamMutation;

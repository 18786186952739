import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type NotificationsQueryQuery,
	type NotificationsQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';

const NotificationsQuery: TypedDocumentNode<
	NotificationsQueryQuery,
	NotificationsQueryQueryVariables
> = gql`
	query NotificationsQuery($limit: Int) {
		notifications(limit: $limit) {
			_id
			createdAt
			type
			relatedUser {
				_id
				username
				name
				avatarURL
				url
				isPro
				canFollow
				isFollowing
				isFollowingBack
			}
			relatedItem {
				_id
				url
				shortID
				asset {
					image {
						thumbnail
					}
					colors {
						color
					}
				}
			}
			relatedBoard {
				_id
				name
				slug
				url
				acceptURL
				rejectURL
			}
			relatedTeam {
				_id
				name
				acceptURL
				rejectURL
			}
			relatedComment {
				_id
				content
				user {
					_id
					username
					name
					avatarURL
					url
				}
				isTeamOnly
			}
		}
	}
`;

export default NotificationsQuery;

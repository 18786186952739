import { ALLOWED_IMAGE_EXTENSIONS, ALLOWED_VIDEO_EXTENSIONS } from '@pkgs/shared/constants';
import React from 'react';
import SVLink from './SVLink';

type Props = React.PropsWithChildren<{
	multiple?: boolean;
	className?: string;
	isLogged: boolean;
	onSelect: (files: FileList) => void;
	title?: string;
}>;

class SVSelectFilesButton extends React.Component<Props> {
	elementRef = React.createRef<HTMLDivElement>();
	inputElement: HTMLInputElement | null = null;

	componentDidMount = () => {
		this.mountInput();
	};

	UNSAFE_componentWillMount() {
		this.unmountInput();
	}

	componentWillUnmount() {
		this.unmountInput();
	}

	unmountInput = () => {
		if (this.inputElement && this.inputElement.parentNode) {
			this.inputElement.removeEventListener('change', this.handleInputChange);
			this.inputElement.parentNode.removeChild(this.inputElement);
			this.inputElement = null;
		}
	};

	mountInput = () => {
		this.unmountInput();

		if (this.elementRef.current) {
			this.inputElement = document.createElement('input');
			this.inputElement.setAttribute('type', 'file');
			this.inputElement.classList.add(
				...'absolute inset-0 h-full w-full opacity-0 cursor-pointer'.split(' '),
			);
			if (this.props.multiple) {
				this.inputElement.setAttribute('multiple', 'multiple');
			}
			this.inputElement.setAttribute(
				'accept',
				[
					...Object.values(ALLOWED_IMAGE_EXTENSIONS),
					...Object.values(ALLOWED_VIDEO_EXTENSIONS),
				].join(','),
			);
			this.inputElement.addEventListener('change', this.handleInputChange);

			this.elementRef.current.appendChild(this.inputElement);
		}
	};

	handleInputChange = (event: Event) => {
		const files = (event.target as HTMLInputElement).files;

		this.mountInput();

		this.props.onSelect(files as FileList);
	};

	render() {
		// eslint-disable-next-line no-unused-vars
		const { children, isLogged, onSelect: _, multiple: __, ...props } = this.props;

		if (!isLogged) {
			return (
				<SVLink className="relative overflow-hidden" to="/login/" {...props}>
					{children}
				</SVLink>
			);
		}

		return (
			<div className="relative overflow-hidden" ref={this.elementRef} {...props}>
				{children}
			</div>
		);
	}
}

export default SVSelectFilesButton;

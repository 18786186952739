import { gql } from '@apollo/client';
import BoardFragment from './BoardFragment';
import UserCompanyFragment from './UserCompanyFragment';

const UserFragment = gql`
	${UserCompanyFragment}
	${BoardFragment}

	fragment UserFragment on User {
		_id
		username
		name
		role
		company {
			...UserCompanyFragment
		}
		website
		instagram
		twitter
		url
		avatarURL
		ogImage {
			width
			height
			url
		}
		boards {
			...BoardFragment
		}
		isPro
		itemsCount
		boardsCount
		followingCount
		followersCount
		canFollow
		isFollowing
		isFollowingBack
	}
`;

export default UserFragment;

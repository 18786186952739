/**
 * Hook to check what subscription types the user can see a billing page for, based on many
 * checks like if a user is part of a team or isBeta.
 */

import useAuthHasAtLeastProPrivileges from '@apps/www/src/www/hooks/useAuthHasAtLeastProPrivileges';
import useAuthSubscriptions from '@apps/www/src/www/hooks/useAuthSubscriptions';
import SubscriptionType from '@pkgs/shared/enums/SubscriptionType';

export default function useCanSeeBillingPageForSubscriptionTypes(): Array<
	ValueOf<typeof SubscriptionType>
> {
	const hasProPrivileges = useAuthHasAtLeastProPrivileges();
	const subscriptions = useAuthSubscriptions();

	const types: Array<ValueOf<typeof SubscriptionType>> = [];

	// Can see an individual subscription billing if has pro privileges for any reason or any subscription type with any state
	if (hasProPrivileges || subscriptions.length > 0) {
		types.push(SubscriptionType.INDIVIDUAL);
	}

	// Can see team subscription billing if `canManage` flag is true
	const teamSubscription = subscriptions.find(
		(subscription) => subscription.type === SubscriptionType.TEAM,
	);

	if (teamSubscription && teamSubscription.canManage) {
		types.push(SubscriptionType.TEAM);
	}

	return types;
}

import { gql } from '@apollo/client';

const AuthBillingFragment = gql`
	fragment AuthBillingFragment on Auth {
		token
		user {
			_id
			isPro
		}
		settings {
			_id
			needsUpgradeReason
			subscriptions {
				type
				provider
				planID
				subscriptionEndDate
				needsRenewSince
				autoRenew
				cardLast4
				cardBrandID
				isCardExpired
			}
		}
	}
`;

export default AuthBillingFragment;

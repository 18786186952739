import currency from 'currency.js';

export default function formatCount(value: string | number) {
	// 1000 turns into 1,000
	// return numeral(value).format('0,0');
	return currency(value, { pattern: '#', precision: 0 }).format();

	// 1000 turns into 1k, 1000000 turns into 1m...
	// 	return numeral(value).format('0a');
}

import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type ItemQuery as ItemQueryQuery,
	type ItemQueryVariables as ItemQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';
import ItemFragment from './fragments/ItemFragment';

const ItemQuery: TypedDocumentNode<ItemQueryQuery, ItemQueryQueryVariables> = gql`
	${ItemFragment}

	query Item($shortID: NonEmptyString!) {
		itemByShortID(shortID: $shortID) {
			...ItemFragment
		}
	}
`;

export default ItemQuery;

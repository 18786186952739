import { gql, type TypedDocumentNode } from '@apollo/client';
import { type AuthQueryQuery } from '@apps/www/src/__generated__/graphql';
import AuthFragment from './fragments/AuthFragment';

const AuthQuery: TypedDocumentNode<AuthQueryQuery> = gql`
	${AuthFragment}

	query AuthQuery {
		auth {
			...AuthFragment
		}
	}
`;

export default AuthQuery;

import { getClassesArray } from '@pkgs/shared-client/helpers/dom';
import clsx, { type ClassValue } from 'clsx';
import React from 'react';
import wrapDisplayName from 'recompose/wrapDisplayName';

function toggleClasses<P>(
	oldClasses,
	validator: (props: P) => string,
	props,
	applyToHTMLElement = false,
) {
	const classes = getClassesArray(validator(props));
	const toDelete = oldClasses.filter((klass) => !classes.includes(klass));
	const element = applyToHTMLElement ? document.getElementsByTagName('html')[0] : document.body;

	classes.forEach((klass) => element.classList.add(klass));
	toDelete.forEach((klass) => element.classList.remove(klass));

	return classes;
}

const SVWithBodyClass =
	(validator: ClassValue | ((props: any) => string), applyToHTMLElement = false) =>
	<P,>(WrappedComponent: React.ComponentType<P>) => {
		let validatorFn: (props: P) => string;

		if (typeof validator !== 'function') {
			const className = clsx(validator);
			validatorFn = () => className;
		} else {
			validatorFn = validator as (props: P) => string;
		}

		class SVWithBodyClass extends React.Component<P> {
			static displayName = wrapDisplayName(WrappedComponent, 'SVWithBodyClass');

			classes: string[] = [];

			componentDidMount() {
				this.toggleWithValidator(this.props);
			}

			componentWillUnmount() {
				this.classes.forEach((klass) => document.body.classList.remove(klass));
				this.classes = [];
			}

			UNSAFE_componentWillUpdate(nextProps: P) {
				this.toggleWithValidator(nextProps);
			}

			toggleWithValidator(props: P) {
				this.classes = toggleClasses(this.classes, validatorFn, props, applyToHTMLElement);
			}

			render() {
				return <WrappedComponent {...(this.props as P & JSX.IntrinsicAttributes)} />;
			}
		}

		return SVWithBodyClass;
	};

export default SVWithBodyClass;

/**
 * Given a list of active subscription tiers, check if a specific subscription tier is included/allowed.
 *
 * For example if a user has a `SubscriptionTier.TEAM`, this function also returns true when checking for `SubscriptionTier.PRO`.
 */

import SubscriptionTier from '@pkgs/shared/enums/SubscriptionTier';
import SubscriptionType from '@pkgs/shared/enums/SubscriptionType';
import { getSubscriptionTypeFromTier } from '@pkgs/shared/plans';

export default function subscriptionTiersHasSubscriptionTierPrivileges(
	activeSubscriptionTiers: ValueOf<typeof SubscriptionTier>[],
	subscriptionTier: ValueOf<typeof SubscriptionTier>,
): boolean {
	if (activeSubscriptionTiers && activeSubscriptionTiers.length > 0) {
		// Straightforward check
		if (activeSubscriptionTiers.includes(subscriptionTier)) {
			return true;
		}

		// Team subscription types also grants PRO privileges
		if (
			subscriptionTier === SubscriptionTier.PRO &&
			activeSubscriptionTiers.some(
				(tier) => getSubscriptionTypeFromTier(tier) === SubscriptionType.TEAM,
			)
		) {
			return true;
		}

		// SITE_MAKER tier also grants PRO privileges
		if (
			subscriptionTier === SubscriptionTier.PRO &&
			activeSubscriptionTiers.includes(SubscriptionTier.SITE_MAKER)
		) {
			return true;
		}

		// ENTERPRISE subscriptions also grant TEAM privileges
		if (
			subscriptionTier === SubscriptionTier.TEAM &&
			activeSubscriptionTiers.includes(SubscriptionTier.ENTERPRISE)
		) {
			return true;
		}
	}

	return false;
}

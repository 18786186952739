import { type ViewportNameType } from '@pkgs/shared/constants';
import ViewportKind from '@pkgs/shared/enums/ViewportKind';

function getViewportKindFromViewportName(
	viewportName: ViewportNameType | null,
): ValueOf<typeof ViewportKind> {
	return viewportName === 'small' || viewportName === 'small-landscape'
		? ViewportKind.MOBILE
		: ViewportKind.REGULAR;
}

export default getViewportKindFromViewportName;

import { Transition } from '@headlessui/react';
import React, { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

const SVTransition = ({
	show,
	className,
	enterFrom,
	enterTo,
	leaveFrom,
	leaveTo,
	children,
	afterLeave,
	...props
}: React.PropsWithChildren<{
	show: boolean;
	className?: string;
	enterFrom?: string;
	enterTo?: string;
	leaveFrom?: string;
	leaveTo?: string;
	afterLeave?: () => void;
}> &
	Pick<React.ComponentProps<typeof Transition>, 'afterEnter' | 'afterLeave' | 'style'>) => {
	// This is a bug fix: Make sure afterLeave is called after transition is finished.
	// When multiple modals are open, we need to make sure that afterLeave is called as
	// `@headlessui/react` `Transition` only call for the last one. It happens because all
	// modals are nested on each other, behavior from NiceModal engine.
	useEffect(() => {
		let timeoutId: ReturnType<typeof setTimeout> | null = null;

		if (!show && afterLeave) {
			timeoutId = setTimeout(() => {
				afterLeave?.();
			}, 400);
		}

		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
				timeoutId = null;
			}
		};
	}, [show, afterLeave]);

	return (
		<Transition.Root
			enterFrom={enterFrom}
			enterTo={enterTo}
			entered={enterTo}
			leaveFrom={leaveFrom || enterTo}
			leaveTo={leaveTo || enterFrom}
			className={twMerge(className, show && enterTo)}
			show={show}
			{...props}
		>
			{children}
		</Transition.Root>
	);
};

export const SVTransition_Root = SVTransition;

export const SVTransition_Child = ({
	as,
	className,
	enterFrom,
	enterTo,
	leaveFrom,
	leaveTo,
	children,
	...props
}: React.PropsWithChildren<{
	as?: React.ComponentType<any>;
	className?: string;
	enterFrom: string;
	enterTo: string;
	leaveFrom?: string;
	leaveTo?: string;
}> &
	Pick<React.ComponentProps<typeof Transition.Child>, 'onClick'>) => {
	return (
		<Transition.Child
			as={as}
			enterFrom={enterFrom}
			enterTo={enterTo}
			entered={enterTo}
			leaveFrom={leaveFrom || enterTo}
			leaveTo={leaveTo || enterFrom}
			className={className}
			{...props}
		>
			{children}
		</Transition.Child>
	);
};

export default SVTransition;

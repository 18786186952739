/* eslint-disable jsx-a11y/alt-text */
import { type AssetImage } from '@apps/www/src/__generated__/graphql';
import SVImage from '@pkgs/shared-client/components/SVImage';
import React from 'react';
import SVTransition from './SVTransition';

type Props = {
	thumbnail: AssetImage['thumbnail'] | undefined;
	color: string | undefined;
};

const SVGridItemSorting = React.forwardRef<HTMLDivElement, Props>(
	({ thumbnail, color }, forwardedRef) => (
		<div
			ref={forwardedRef}
			className="z-index-grid-area-select pointer-events-none absolute top-0 left-0"
		>
			<SVTransition
				show={!!thumbnail}
				className="duration-over transform shadow-xl transition-all ease-out"
				enterFrom="opacity-0 scale-100"
				enterTo="opacity-80 scale-125"
				style={{ backgroundColor: color || '#000000' }}
			>
				<SVImage className="h-full w-full" src={thumbnail} />
			</SVTransition>
		</div>
	),
);

export default SVGridItemSorting;

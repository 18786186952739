// import documentReady from 'document-ready';
import SVWithNoSSR from '@apps/www/src/www/containers/SVWithNoSSR';
import useDebouncedCallback from '@apps/www/src/www/hooks/useDebouncedCallback';
import { UIStateKeys, useSetUIState } from '@apps/www/src/www/hooks/useUIState';
import getViewportKindFromViewportName from '@pkgs/shared-client/helpers/getViewportKindFromViewportName';
import getViewportName from '@pkgs/shared-client/helpers/getViewportName';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import React from 'react';
import { useMount, useUnmount } from 'react-use';

const _SVViewportController = () => {
	const setViewportName = useSetUIState(UIStateKeys.VIEWPORT_NAME);
	const setViewportKind = useSetUIState(UIStateKeys.VIEWPORT_KIND);

	const updateViewportName = useEventCallback(() => {
		const viewportName = getViewportName();

		setViewportName(viewportName);
		setViewportKind(getViewportKindFromViewportName(viewportName));
	});

	const debouncedHandleResize = useDebouncedCallback(() => {
		updateViewportName();
	}, 80);

	useMount(() => {
		// documentReady(handleResize);
		updateViewportName();

		window.addEventListener('resize', debouncedHandleResize, true);
	});

	useUnmount(() => {
		window.removeEventListener('resize', debouncedHandleResize);
	});

	return null;
};

const SVViewportController = SVWithNoSSR(React.memo(_SVViewportController));

export default SVViewportController;

import childrenOnlyText from '@pkgs/shared-client/helpers/childrenOnlyText';
import IconLoadingSVG from '@pkgs/shared-client/img/icon-loading-inlined.svg';
import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import SVKeyboardKey, { formatTitleWithKeys, KeyboardKeys } from './SVKeyboardKey';
import SVLink from './SVLink';
import SVSelectFilesButton from './SVSelectFilesButton';

const USES = {
	DEFAULT: 'default',
	PRIMARY: 'primary',
	HIGHLIGHT: 'highlight',
	VIOLATOR: 'violator',
	EXTENSION: 'extension',
	OUTLINE: 'outline',
	FULL: 'full',
	GRADIENT: 'gradient',
	TRANSPARENT: 'transparent',
} as const;

const SIZES = {
	WIDE: 'wide',
	DEFAULT: 'default',
	SMALL: 'small',
	EXTRA_SMALL: 'extra-small',
	TINY: 'tiny',
	PAYMENT: 'payment',
} as const;

const USES_CLASSES = {
	[USES.DEFAULT]: 'rounded-2xl text-primary bg-gray-800 hover:bg-gray-900',
	[USES.OUTLINE]:
		'rounded-2xl text-primary bg-transparent hover:bg-gray-800 ring-2 ring-gray-700',
	[USES.PRIMARY]: 'rounded-2xl text-background bg-primary hover:bg-opacity-80',
	[USES.GRADIENT]:
		'rounded-2xl text-white bg-gradient-to-r from-[#3E2DFF] to-[#4F40FF] animate-rotate-gradient bg-[length:150%_150%] hover:bg-opacity-80',
	[USES.HIGHLIGHT]: 'rounded-2xl text-white bg-brand hover:bg-opacity-80',
	[USES.VIOLATOR]:
		'rounded text-gray-400 bg-background bg-opacity-0 hover:text-primary ring-2 ring-primary/20 hover:ring-gray-600 font-semibold uppercase',
	[USES.EXTENSION]:
		'rounded-md text-gray-400 bg-background bg-opacity-0 hover:bg-gray-900 ring-2 ring-gray-700 hover:bg-opacity-100 uppercase',
	[USES.FULL]: '',
	[USES.TRANSPARENT]: 'bg-transparent text-sm hover:opacity-80',
} as const;

const SIZES_CLASSES = {
	[SIZES.WIDE]: 'w-full block type-base h-11 py-3.5 px-8 font-semibold',
	[SIZES.DEFAULT]: 'type-base py-4 px-8 font-semibold',
	[SIZES.SMALL]: 'type-small px-6 py-4 font-medium rounded-xl',
	[SIZES.EXTRA_SMALL]: 'type-small px-3 py-3 rounded-lg font-medium',
	[SIZES.TINY]: 'type-label px-4 py-2 font-medium',
	[SIZES.PAYMENT]: 'type-base h-[50px] py-4 px-8 font-semibold flex items-center',
} as const;

const defaultProps: {
	use: ValueOf<typeof USES>;
	size: ValueOf<typeof SIZES>;
} = {
	use: USES.DEFAULT,
	size: SIZES.DEFAULT,
};

export type Props = React.PropsWithChildren<
	Partial<typeof defaultProps> & {
		title?: HTMLButtonElement['title'];
		keys?: KeyboardKeys;
		icon?: JSX.Element;
		className?: string;
		isLoading?: boolean;
		isDisabled?: boolean;
	} & (
			| {
					Component: 'a';
					href: string;
					onClick?: (event: React.UIEvent) => void;
					target?: HTMLAnchorElement['target'];
					rel?: HTMLAnchorElement['rel'];
			  }
			| ({
					Component: typeof SVLink;
			  } & React.ComponentPropsWithoutRef<typeof SVLink>)
			| ({
					Component: typeof SVSelectFilesButton;
			  } & React.ComponentProps<typeof SVSelectFilesButton>)
			| {
					Component?: 'button';
					onClick?: (event: React.UIEvent) => void;
					type?: HTMLButtonElement['type'];
			  }
		)
>;

const SVButton = React.forwardRef<HTMLButtonElement & HTMLAnchorElement, Props>(
	(
		{
			title,
			Component = 'button',
			keys,
			icon,
			children,
			className,
			use,
			size,
			isLoading,
			isDisabled,
			...props
		}: Props,
		forwardedRef,
	) => {
		return (
			// @ts-ignore fix this later
			<Component
				ref={forwardedRef}
				title={formatTitleWithKeys(title || childrenOnlyText(children), keys)}
				className={twMerge(
					clsx(
						'duration-over relative m-0 box-border inline-flex h-auto cursor-pointer select-none overflow-visible whitespace-nowrap text-center align-middle no-underline outline-none transition-colors ease-out',
						use && USES_CLASSES[use],
						size && SIZES_CLASSES[size],
						use === USES.VIOLATOR && size === SIZES.TINY && 'px-2 py-1',
						(isLoading || isDisabled) && 'disabled',
						isDisabled && 'opacity-60',
						'leading-none',
					),
					className,
				)}
				{...props}
			>
				{isLoading && (
					<span
						role="progressbar"
						className="absolute inset-0 transition-none flex-center"
					>
						<IconLoadingSVG
							style={{
								transform:
									size === SIZES.SMALL
										? `scale(${13 / 16}, ${13 / 16})`
										: size === SIZES.TINY
										? `scale(${11 / 16}, ${11 / 16})`
										: 'none',
							}}
						/>
					</span>
				)}
				{keys && 'onClick' in props && (
					<SVKeyboardKey
						keys={keys}
						isDisabled={isDisabled || isLoading}
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						onTrigger={props.onClick!}
					/>
				)}
				<span className={clsx('flex-center', !icon && 'w-full', isLoading && 'invisible')}>
					{icon ? (
						<span
							className={clsx(
								'mr-2 flex-shrink-0',
								size === SIZES.TINY && 'mr-1 max-h-[10px] max-w-[10px]',
							)}
						>
							{icon}
						</span>
					) : null}
					{children}
				</span>
			</Component>
		);
	},
);

SVButton.defaultProps = defaultProps;

export const SVButtonUSES = USES;
export const SVButtonSIZES = SIZES;

const _t = () => {
	return (
		<>
			<SVButton Component={SVLink} to="/profile/" />
			<SVButton Component="a" href="/profile/" />
		</>
	);
};

export default SVButton;

import useViewportName from '@apps/www/src/www/hooks/useViewportName';
import formatCount from '@pkgs/shared-client/helpers/formatCount';
import IconNotificationSVG from '@pkgs/shared-client/img/icon-notification-inlined.svg';
import IconNotificationSmallSVG from '@pkgs/shared-client/img/icon-notification-small-inlined.svg';
import clsx from 'clsx';
import React from 'react';

type Props = {
	count?: number;
	onClick?: (event: React.UIEvent) => void;
	onMouseOver?: (event: React.UIEvent) => void;
	isOutsideTheMenu?: boolean;
};

const SVNotificationIcon: React.ComponentType<Props> = React.forwardRef<
	HTMLButtonElement & HTMLAnchorElement,
	Props
>(({ count = 0, isOutsideTheMenu, onClick, ...otherProps }, forwardedRef) => {
	const viewportName = useViewportName();

	const isSmall =
		viewportName === 'small' || viewportName === 'medium' || viewportName === 'medium-portrait';

	return count > 0 ? (
		<span
			className={clsx(
				'theme-dark flex-center type-label text-primary duration-over bg-brand h-5 rounded-full py-3 font-medium transition ease-out group-hover:brightness-75',
				count > 9 && !isSmall ? 'w-auto px-2' : 'w-5 px-3',
				isSmall && '-md:ml-1 -sm:ml-0',
			)}
		>
			{formatCount(Math.min(isSmall ? 99 : 9999, count))}
		</span>
	) : isOutsideTheMenu ? (
		<IconNotificationSVG
			ref={forwardedRef}
			label="Notification"
			{...otherProps}
			className="h-6 w-6 text-gray-400"
		/>
	) : (
		<IconNotificationSmallSVG
			ref={forwardedRef}
			label="Notification"
			{...otherProps}
			className="-md:ml-1.5 -sm:ml-0 ml-1 h-5 w-5"
		/>
	);
});

export default SVNotificationIcon;

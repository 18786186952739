import { Dialog } from '@headlessui/react';
import { SVKeyboardContext } from '@pkgs/shared-client/components/SVKeyboardKey';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import SVModal, { type Props as SVModalProps } from './SVModal';
import { SVTransition_Child, SVTransition_Root } from './SVTransition';

const SVOverlay = ({
	isOpen,
	onClose,
	className,
	children,
	afterOpen,
	afterClose,
	childrenAfter,
}: SVModalProps) => {
	return (
		<SVTransition_Root show={!!isOpen} afterEnter={afterOpen} afterLeave={afterClose}>
			<Dialog
				// static={true}
				onClose={onClose}
				className="z-index-overlay fixed inset-0 overflow-y-auto"
			>
				<SVTransition_Child
					className="duration-over transition-opacity ease-in-out"
					enterFrom="opacity-0"
					enterTo="opacity-100"
				>
					<Dialog.Overlay className="fixed inset-0" />
				</SVTransition_Child>
				<SVTransition_Child
					className={twMerge(
						'duration-over overflow-hidden bg-black/90 transition-all ease-in-out',
						'flex-center absolute inset-0 flex-col',
						className,
					)}
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
				>
					<SVKeyboardContext>{children}</SVKeyboardContext>
				</SVTransition_Child>
				{childrenAfter}
			</Dialog>
		</SVTransition_Root>
	);
};

SVOverlay.create = <TOverlayProps extends object>(
	Component: React.ComponentType<TOverlayProps>,
	options: { className?: string } = {
		className: undefined,
	},
) => {
	return SVModal.create(Component, {
		layout: SVModal.LAYOUTS.OVERLAY,
		className: options.className,
	});
};

export default SVOverlay;

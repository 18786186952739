/**
 * Returns the first field on a graphql query adding the necessary variables.
 * Like: UserItemsQuery -> `userByUsername({"username":"someusername"})`
 */

import { type OperationVariables } from '@apollo/client';
import { Kind, type DocumentNode, type FieldNode, type OperationDefinitionNode } from 'graphql';

const fieldNameFromQuery = (query: DocumentNode, variables: OperationVariables = {}) => {
	const firstQueryOperation = query.definitions.find(
		(definition) => definition.kind === Kind.OPERATION_DEFINITION,
	) as OperationDefinitionNode;

	if (!firstQueryOperation) {
		return null;
	}

	const firstQueryField = firstQueryOperation.selectionSet.selections.find(
		(selection) => selection.kind === Kind.FIELD,
	) as FieldNode;
	const hasVariables = Object.keys(variables).length > 0;

	return `${firstQueryField.name.value}${hasVariables ? `(${JSON.stringify(variables)})` : ''}`;
};

export default fieldNameFromQuery;

/**
 * Given a columnConfigID and paddingConfigID, usually from user perferences, return the calculated grid spacings
 * to be used on the grid as well as the generic page margin component
 */
import usePersistentSetting, {
	PersistentSettingKeys,
} from '@apps/www/src/www/hooks/usePersistentSetting';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import { columnsRatioToConfigID, spacingsRatioToConfigID } from '@pkgs/shared-client/config';
import {
	GRID_COLUMNS,
	GRID_SPACINGS,
	type GridColumnsConfigID,
	type GridSpacingsConfigID,
} from '@pkgs/shared/constants';
import { createSelector } from 'reselect';

const spacingConfigSelector = createSelector(
	(spacingConfigID: GridSpacingsConfigID) => spacingConfigID,
	(spacingConfigID) =>
		GRID_SPACINGS.find((item) => {
			return item.id == spacingConfigID;
		}),
);

const columnConfigSelector = createSelector(
	(columnsConfigID: GridColumnsConfigID) => columnsConfigID,
	(columnsConfigID) =>
		GRID_COLUMNS.find((item) => {
			return item.id == columnsConfigID;
		}),
);

export default function useGridSpacings(
	columnsConfigID: GridColumnsConfigID | undefined,
	spacingConfigID: GridSpacingsConfigID,
) {
	const columnConfig = columnsConfigID ? columnConfigSelector(columnsConfigID) : null;
	const spacingConfig = spacingConfigSelector(spacingConfigID);

	const spacingPerc =
		columnConfig && spacingConfig && columnsConfigID
			? columnConfig.spacing * spacingConfig.scale * columnsConfigID
			: 0;

	return {
		columnConfig,
		spacingConfig,
		spacingPerc,
	};
}

export function useGridSpacingsFromUserSettings() {
	const [viewportName] = useUIState(UIStateKeys.VIEWPORT_NAME);
	const [gridColumnsRatio] = usePersistentSetting(PersistentSettingKeys.GRID_COLUMNS_RATIO);
	const columnsConfigID =
		(viewportName && columnsRatioToConfigID(viewportName, gridColumnsRatio)) || 6;

	const [gridSpacingRatio] = usePersistentSetting(PersistentSettingKeys.GRID_SPACING_RATIO);
	const spacingConfigID = spacingsRatioToConfigID(gridSpacingRatio);

	return useGridSpacings(columnsConfigID, spacingConfigID);
}

export const gridUpload = {
	ADD_ITEMS: 'grid-upload/ADD_ITEMS',
	UPDATE_ITEM_THUMBNAIL: 'grid-upload/UPDATE_ITEM_THUMBNAIL',
	ITEM_UPLOAD_START: 'grid-upload/ITEM_UPLOAD_START',
	ITEM_UPLOAD_PROGRESS: 'grid-upload/ITEM_UPLOAD_PROGRESS',
	ITEM_UPLOAD_COMPLETE: 'grid-upload/ITEM_UPLOAD_COMPLETE',
	ITEM_UPLOAD_ERROR: 'grid-upload/ITEM_UPLOAD_ERROR',
	CLEAR_ITEMS: 'grid-upload/CLEAR_ITEMS',
	REMOVE_ITEM: 'grid-upload/REMOVE_ITEM',
	TOGGLE_IS_DROPPING: 'grid-upload/TOGGLE_IS_DROPPING',
} as const;

export const grid = {
	UPDATE_ITEM_SELECTION: 'grid/UPDATE_ITEM_SELECTION',
	CLEAR_ITEMS_SELECTION: 'grid/CLEAR_ITEMS_SELECTION',
	TOGGLE_SORTING: 'grid/TOGGLE_SORTING',
	SORT_LOADING: 'grid/SORT_LOADING',
	SORT_SUCCESS: 'grid/SORT_SUCCESS',
	TOGGLE_EDITING: 'grid/TOGGLE_EDITING',
	TOGGLE_AREA_SELECT: 'grid/TOGGLE_AREA_SELECT',
} as const;

export const ui = {
	OPEN_GRID_SETTINGS: 'ui/OPEN_GRID_SETTINGS',
	CLOSE_GRID_SETTINGS: 'ui/CLOSE_GRID_SETTINGS',
	OPEN_NOTIFICATIONS: 'ui/OPEN_NOTIFICATIONS',
	CLOSE_NOTIFICATIONS: 'ui/CLOSE_NOTIFICATIONS',
	OPEN_NAV: 'ui/OPEN_NAV',
	CLOSE_NAV: 'ui/CLOSE_NAV',
	SHOW_GRID_DETAILS_INFO: 'ui/SHOW_GRID_DETAILS_INFO',
	HIDE_GRID_DETAILS_INFO: 'ui/HIDE_GRID_DETAILS_INFO',
	OPEN_GRID_UPLOAD: 'ui/OPEN_GRID_UPLOAD',
	CLOSE_GRID_UPLOAD: 'ui/CLOSE_GRID_UPLOAD',
	CLOSE_OVERLAYS: 'ui/CLOSE_OVERLAYS',

	UPDATE_UI_STATE: 'ui/UPDATE_UI_STATE',
} as const;

import config from '@pkgs/shared-client/config';
import { formatURL } from '@pkgs/shared-client/helpers/format';
import React from 'react';
// import IconLeftArrowSVG from '../img/arrow-left-inlined.svg';
import IconRightArrowSVG from '@pkgs/shared-client/img/arrow-right-inlined.svg';
import videoOnboard1Mp4 from '@pkgs/shared-client/videos/onboard-1-grid.mp4';
import videoOnboard1Webm from '@pkgs/shared-client/videos/onboard-1-grid.webm';
import videoOnboard2Mp4 from '@pkgs/shared-client/videos/onboard-2-shift.mp4';
import videoOnboard2Webm from '@pkgs/shared-client/videos/onboard-2-shift.webm';
import videoOnboard3Mp4 from '@pkgs/shared-client/videos/onboard-3-import.mp4';
import videoOnboard3Webm from '@pkgs/shared-client/videos/onboard-3-import.webm';
import videoOnboard4Mp4 from '@pkgs/shared-client/videos/onboard-4-extension.mp4';
import videoOnboard4Webm from '@pkgs/shared-client/videos/onboard-4-extension.webm';
import SVIconButton from './SVIconButton';
import SVModal from './SVModal';
import SVVideo from './SVVideo';

const _VideoPlayer = ({ mp4, webm }: { mp4: string; webm: string }) => (
	<SVVideo
		className="mt-2 mb-8"
		videoClassName="block !w-full !h-auto"
		sources={[webm, mp4]}
		width={680}
		height={260}
		muted
		autoPlay
		playsInline
		loop
	/>
);

const _Content = ({ children, onNext }: React.PropsWithChildren<{ onNext: () => void }>) => (
	<div className="flex items-center">
		{/* {onPrev && <SVIconButton src={IconLeftArrowSVG} onClick={onPrev} />} */}
		<div className="flex flex-1 flex-col">{children}</div>
		{onNext && <SVIconButton src={IconRightArrowSVG} onClick={onNext} />}
	</div>
);

const createStep =
	(mp4: string, webm: string, title: string, text: string) =>
	({ onNext }: { onNext: () => void }) =>
		(
			<div className="-md:w-[500px] -sm:w-full w-[680px] text-left">
				<_VideoPlayer
					mp4={formatURL(config.staticURL, mp4)}
					webm={formatURL(config.staticURL, webm)}
				/>
				<_Content onNext={onNext}>
					<SVModal.Title className="mb-2">{title}</SVModal.Title>
					<p>{text}</p>
				</_Content>
			</div>
		);

const SVOnboardingModalContent = {
	Step1: createStep(
		videoOnboard1Mp4,
		videoOnboard1Webm,
		'Your own Grid',
		'We believe that you know what’s best for your content, so we let you chose the number of columns and the padding between saves.',
	),
	Step2: createStep(
		videoOnboard2Mp4,
		videoOnboard2Webm,
		'Selection is Key',
		'Hold shift and select as many saves you want.',
	),
	Step3: createStep(
		videoOnboard3Mp4,
		videoOnboard3Webm,
		'Import your Content',
		'Your inspiration is important. This is the reason we help you import your saves from Pinterest or your Computer.',
	),
	Step4: createStep(
		videoOnboard4Mp4,
		videoOnboard4Webm,
		'Browser Extension',
		'Download our extension and grab images and videos from any site.',
	),
} as const;

export default SVOnboardingModalContent;

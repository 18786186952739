import {
	type Board,
	type User,
	type UserFragmentFragment,
} from '@apps/www/src/__generated__/graphql';
import SVFollowButtonContainer from '@apps/www/src/www/containers/SVFollowButtonContainer';
import { preventDefault } from '@pkgs/shared-client/helpers/dom';
import IconCheckSVG from '@pkgs/shared-client/img/icon-check-inlined.svg';
import IconFirstSavedSVG from '@pkgs/shared-client/img/icon-first-saved-inlined.svg';
import IconLoadingSVG from '@pkgs/shared-client/img/icon-loading-inlined.svg';
import IconLockSVG from '@pkgs/shared-client/img/icon-lock-inlined.svg';
import IconPlusSVG from '@pkgs/shared-client/img/icon-plus-inlined.svg';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';
import clsx from 'clsx';
import React, { memo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import SVA from './SVA';
import SVAvatar from './SVAvatar';
import SVAvatarsStack from './SVAvatarsStack';
import { SVButtonSIZES } from './SVButton';
import SVFlexSpacer from './SVFlexSpacer';
import SVKeyboardKey, { type KeyboardKeys } from './SVKeyboardKey';
import SVLink from './SVLink';
import SVProBadge from './SVProBadge';
import SVToggle from './SVToggle';

const _Wrapper = ({ className, ...props }: React.PropsWithChildren<{ className?: string }>) => (
	<span
		className={twMerge(
			'font-base text-secondary flex flex-col items-stretch p-4 text-left font-normal',
			className,
		)}
		{...props}
	/>
);

const _BoardItemThumb = ({
	images,
	isSelected,
}: {
	images: ArrayElement<UserFragmentFragment['boards']>['thumbnails'];
	isSelected: boolean;
}) => (
	<span
		className={twMerge(
			'duration-over mr-2 h-8 w-8 flex-shrink-0 bg-gray-800 bg-cover bg-center bg-no-repeat transition-opacity ease-out group-hover:opacity-80',
			isSelected && 'opacity-60',
		)}
		style={
			images?.length > 0
				? {
						backgroundImage: `url(${images[0].image.thumbnail})`,
				  }
				: {}
		}
	></span>
);

const _BoardNewThumb = () => (
	<span className="text-primary duration-over mr-2 flex h-8 w-8 flex-shrink-0 items-center justify-center bg-gray-800 transition-opacity ease-out group-hover:opacity-80">
		<IconPlusSVG className="h-3 w-3" />
	</span>
);

const _BoardItem = ({
	board,
	onClick,
	isSelected,
	hasAnyPrivate,
	keepOpenOnClick,
	...props
}: {
	board: ArrayElement<UserFragmentFragment['boards']>;
	onClick: (board: ArrayElement<UserFragmentFragment['boards']>) => void;
	isSelected: boolean;
	hasAnyPrivate: boolean;
	keepOpenOnClick: boolean;
}) => (
	<SVA
		className={twMerge(
			'text-muted hover:text-primary relative flex min-w-0 shrink-0 items-center py-1 text-left',
			isSelected && 'text-primary',
		)}
		Component="button"
		onClick={
			keepOpenOnClick
				? preventDefault(() => {
						onClick(board);
				  })
				: () => {
						onClick(board);
				  }
		}
		title={`${isSelected ? 'Remove from' : 'Add to'} ${board.name}`}
		{...props}
	>
		{isSelected && (
			<IconCheckSVG className="text-primary absolute ml-[-13px] h-[9px] w-[9px] flex-shrink-0" />
		)}
		<_BoardItemThumb images={board.thumbnails} isSelected={isSelected} />
		<span className="mr-2 min-w-[120px] truncate">{board.name}</span>
		<SVFlexSpacer />
		{board.collaborators.length + board.invites.length > 1 && (
			<SVAvatarsStack
				users={board.collaborators}
				invites={board.invites}
				visibleCount={3}
				showDropdown={false}
			/>
		)}
		<span className="type-label text-muted ml-2 flex-shrink-0">{board.itemsCount}</span>
		{hasAnyPrivate ? (
			<span className="w-6 flex-shrink-0">
				{board.isPrivate && (
					<span
						className={twMerge(
							'text-muted duration-over relative ml-2 flex h-4 w-4 items-center justify-center rounded-full bg-gray-800 transition-opacity',
							isSelected && 'text-primary bg-gray-700',
						)}
					>
						<IconLockSVG className="h-2 w-2" />
					</span>
				)}
			</span>
		) : null}
	</SVA>
);

const _Boards = ({
	boards,
	selectedIDs,
	hasAnyPrivate,
	keepOpenOnClick,
	onBoardClick,
}: {
	boards: UserFragmentFragment['boards'];
	selectedIDs: Board['_id'][] | null | undefined;
	hasAnyPrivate: boolean;
	keepOpenOnClick: boolean;
	onBoardClick: (board: ArrayElement<UserFragmentFragment['boards']>) => void;
}) => (
	<>
		{boards.map((board) => (
			<_BoardItem
				key={board._id}
				board={board}
				isSelected={Boolean(selectedIDs && selectedIDs.includes(board._id))}
				hasAnyPrivate={hasAnyPrivate}
				keepOpenOnClick={keepOpenOnClick}
				onClick={onBoardClick}
			/>
		))}
	</>
);

const SVBoardsDropdownContent = ({
	boards,
	onNewBoard,
	onBoardClick,
	selectedIDs,
	keepOpenOnClick = false,
	showTitle = true,
}: {
	boards: UserFragmentFragment['boards'];
	onNewBoard: React.MouseEventHandler | null;
	onBoardClick: (board: ArrayElement<UserFragmentFragment['boards']>) => void;
	selectedIDs?: Board['_id'][];
	keepOpenOnClick?: boolean;
	showTitle?: boolean;
}) => {
	// Save board.lastItemAddedOrder state on a map so the order don't change whenever items get added to a board
	// when keepOpenOnClick is `true` (multi select)
	const [lastItemAddedOrderMap] = useState<Record<string, number | null | undefined>>(() => {
		return boards.reduce((sum: Record<string, number | null | undefined>, board) => {
			sum[board._id] = board.lastItemAddedOrder;

			return sum;
		}, {});
	});

	const lastUsedBoards =
		boards.length > 3
			? [...boards]
					.sort((a, b) => {
						const aOrder = lastItemAddedOrderMap[a._id] || 0;
						const bOrder = lastItemAddedOrderMap[b._id] || 0;

						return bOrder - aOrder;
					})
					.slice(0, 3)
			: [];
	const remainingBoards = boards.filter((board) => !lastUsedBoards.includes(board));

	const userBoards = remainingBoards.filter(
		(board) => board.ownershipType === BoardOwnershipType.USER,
	);
	const teamBoards = remainingBoards.filter(
		(board) => board.ownershipType === BoardOwnershipType.TEAM,
	);

	const hasAnyPrivate = boards.some((board) => board.isPrivate);

	return (
		<>
			<_Wrapper className="bg-background max-w-[360px] overflow-y-auto overscroll-contain whitespace-nowrap p-4 pb-3">
				{showTitle && (
					<span
						className={clsx(
							'text-muted shrink-0 leading-none',
							remainingBoards.length + lastUsedBoards.length > 0
								? 'border-separator mb-3 border-b pb-4'
								: 'pb-2',
						)}
					>
						Save to board
					</span>
				)}
				{lastUsedBoards.length > 0 ? (
					<span className="type-label text-muted mt-1 mb-1 shrink-0">Last used</span>
				) : null}
				<_Boards
					boards={lastUsedBoards}
					selectedIDs={selectedIDs}
					hasAnyPrivate={hasAnyPrivate}
					keepOpenOnClick={keepOpenOnClick}
					onBoardClick={onBoardClick}
				/>
				{userBoards.length > 0 && lastUsedBoards.length > 0 ? (
					<span className="type-label text-muted mt-3 mb-1 shrink-0">
						{teamBoards.length > 0 ? 'User boards' : 'All boards'}
					</span>
				) : null}
				<_Boards
					boards={userBoards}
					selectedIDs={selectedIDs}
					hasAnyPrivate={hasAnyPrivate}
					keepOpenOnClick={keepOpenOnClick}
					onBoardClick={onBoardClick}
				/>
				{teamBoards.length > 0 ? (
					<span className="type-label text-muted mt-3 mb-1 shrink-0">Team boards</span>
				) : null}
				<_Boards
					boards={teamBoards}
					selectedIDs={selectedIDs}
					hasAnyPrivate={hasAnyPrivate}
					keepOpenOnClick={keepOpenOnClick}
					onBoardClick={onBoardClick}
				/>
			</_Wrapper>
			{onNewBoard && (
				<>
					<span className="border-separator flex flex-shrink-0 whitespace-nowrap border-t p-4 pt-0 font-normal">
						<SVA
							className="mt-4 flex min-w-0 flex-grow items-center text-left"
							key="add"
							Component="button"
							onClick={onNewBoard}
						>
							<_BoardNewThumb />
							<span className="mr-2">Create new board</span>
						</SVA>
					</span>
				</>
			)}
		</>
	);
};

type UserFragment = {
	_id: User['_id'];
	name: User['name'];
	url: User['url'];
	avatarURL: User['avatarURL'];
	isPro: User['isPro'];
	username: User['username'];
	canFollow: User['canFollow'];
	isFollowing: User['isFollowing'];
	isFollowingBack: User['isFollowingBack'];
};

type LikeUser = UserFragment | { user: UserFragment };

const UserItem = memo(({ item, isAuthor }: { item: LikeUser; isAuthor?: boolean }) => {
	const user = 'user' in item ? item.user : item;

	return (
		<li className="flex items-center space-x-2 leading-none">
			<SVA
				className="flex min-w-0 items-center space-x-2"
				Component={SVLink}
				to={user.url}
				title={user.name}
			>
				<SVAvatar className="h-8 w-8" src={user.avatarURL} />
				<span className="flex min-w-0 flex-col space-y-1">
					<span className="flex min-w-0 items-center">
						<span className="truncate">{user.name}</span>
						{user.isPro && (
							<SVProBadge className="duration-over transition-opacity ease-out group-hover:opacity-80" />
						)}
					</span>
					<span className="type-label text-muted duration-over flex items-center space-x-2 transition-colors ease-out group-hover:text-gray-600">
						<span className="truncate">{`@${user.username}`}</span>
						{isAuthor && (
							<span className="flex items-center space-x-1">
								<IconFirstSavedSVG className="text-brand" />
								<span>First saved</span>
							</span>
						)}
					</span>
				</span>
			</SVA>
			<SVFlexSpacer />
			<SVFollowButtonContainer
				userID={user._id}
				canFollow={user.canFollow}
				isFollowing={user.isFollowing}
				isFollowingBack={user.isFollowingBack}
				size={SVButtonSIZES.TINY}
			/>
		</li>
	);
});

const SVUsersDropdownContent = ({
	users,
	firstIsAuthor = false,
	...props
}: {
	users: LikeUser[];
	firstIsAuthor?: boolean;
}) => (
	<_Wrapper className="space-y-4 whitespace-nowrap" {...props}>
		{users.map((item, index) => {
			const user = 'user' in item ? item.user : item;

			return (
				<UserItem
					key={user.username}
					item={item}
					isAuthor={!!firstIsAuthor && index === 0}
				/>
			);
		})}
	</_Wrapper>
);

const LINK_USES = {
	DEFAULT: 'default',
	HIGHLIGHT: 'highlight',
} as const;

const _LinkItemWrapper = React.forwardRef<
	HTMLDivElement,
	React.PropsWithChildren<{
		className?: string;
	}>
>(({ className, children }, forwardedRef) => (
	<div
		ref={forwardedRef}
		className={twMerge('relative flex min-w-0 max-w-[320px] items-center', className)}
	>
		{children}
	</div>
));

type LinkItemPropsBase = React.PropsWithChildren<{
	className?: string;
	isLoading?: boolean;
	use?: ValueOf<typeof LINK_USES>;
	isSelected?: boolean;
	title?: React.HTMLProps<HTMLButtonElement>['title'];
}>;

type LinkItemButtonProps = LinkItemPropsBase & {
	Component: 'button';
	onClick: (event: React.UIEvent) => void;
	keys?: KeyboardKeys;
};

type LinkItemLinkProps = LinkItemPropsBase &
	(
		| ({
				Component?: typeof SVLink;
		  } & Pick<React.ComponentPropsWithoutRef<typeof SVLink>, 'to' | 'onClick' | 'keys'>)
		| ({
				Component: 'a';
		  } & Pick<React.HTMLProps<HTMLAnchorElement>, 'href' | 'target' | 'rel'>)
	);

const _LinkItemContent = ({
	className,
	isLoading,
	use,
	Component = SVLink,
	isSelected,
	children,
	...props
}: LinkItemButtonProps | LinkItemLinkProps) => {
	return (
		// @ts-expect-error
		<Component
			className={twMerge(
				clsx(
					'text-primary hover:text-muted group relative flex flex-grow cursor-pointer items-center py-1 transition-colors',
					isLoading && 'opacity-40',
					use === LINK_USES.HIGHLIGHT && 'font-semibold',
					isSelected === true && '',
					isSelected === false && 'text-muted hover:text-primary',
				),
				className,
			)}
			{...props}
		>
			{Component === 'button' && 'keys' in props && 'onClick' in props && (
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				<SVKeyboardKey keys={props.keys!} onTrigger={props.onClick!} />
			)}
			{isSelected === true ? (
				<IconCheckSVG className="text-primary absolute ml-[-12px] h-[9px] w-[9px] flex-shrink-0" />
			) : null}
			{children}
		</Component>
	);
};

const _LinkItemLoading = () => (
	<div className="flex-center pointer-events-none absolute inset-0" role="progressbar">
		<IconLoadingSVG className="scale-75" />
	</div>
);

const LinkSeparator = () => <div className="border-separator my-4 border-b bg-opacity-100" />;

const LinkItemLabel = ({ children }: OnlyChildrenProps) => (
	<span className="type-label text-muted">{children}</span>
);

const linkItemLinkDefaultProps = {
	Component: SVLink,
};

const LinkItemLink = ({ children, isLoading, use, ...props }: LinkItemLinkProps) => (
	<_LinkItemWrapper className={clsx(isLoading && 'pointer-events-none')}>
		{isLoading && <_LinkItemLoading />}
		<_LinkItemContent isLoading={isLoading} use={use} {...props}>
			{children}
		</_LinkItemContent>
	</_LinkItemWrapper>
);

LinkItemLink.defaultProps = linkItemLinkDefaultProps;

const LinkItem = React.forwardRef<HTMLDivElement, Omit<LinkItemButtonProps, 'Component'>>(
	({ children, isLoading, use, ...props }, forwardedRef) => (
		<_LinkItemWrapper ref={forwardedRef} className={clsx(isLoading && 'pointer-events-none')}>
			{isLoading && <_LinkItemLoading />}
			<_LinkItemContent Component="button" isLoading={isLoading} use={use} {...props}>
				{children}
			</_LinkItemContent>
		</_LinkItemWrapper>
	),
);

const LinkItemPreventClose = React.forwardRef<
	HTMLDivElement,
	Omit<LinkItemButtonProps, 'Component'>
>(({ onClick, ...props }, forwardedRef) => (
	<LinkItem ref={forwardedRef} onClick={preventDefault(onClick)} {...props} />
));

const LinkItemMain = ({
	label,
	children,
	use,
	...props
}: LinkItemLinkProps & { label: string }) => (
	<_LinkItemWrapper>
		<_LinkItemContent className="min-w-0 flex-col items-start space-y-1" use={use} {...props}>
			<span className="max-w-full truncate font-semibold">{children}</span>
			{label && <span className="type-label text-muted">{label}</span>}
		</_LinkItemContent>
	</_LinkItemWrapper>
);

const LinkItemToggle = ({
	children,
	onClick,
	isPressed,
	isLoading,
	...props
}: Omit<LinkItemButtonProps, 'Component'> & { isPressed: boolean }) => (
	<_LinkItemWrapper>
		{isLoading && <_LinkItemLoading />}
		<SVToggle
			className="relative"
			size={SVToggle.SIZES.SMALL}
			isPressed={isPressed}
			onClick={preventDefault(onClick)}
		/>
		<_LinkItemContent
			className="ml-2"
			Component="button"
			onClick={preventDefault(onClick)}
			{...props}
		>
			<span>{children}</span>
		</_LinkItemContent>
	</_LinkItemWrapper>
);

const SVLinksDropdownContent = ({ children }: OnlyChildrenProps) => (
	<_Wrapper className="min-w-[130px] whitespace-nowrap">{children}</_Wrapper>
);

SVLinksDropdownContent.ItemLink = LinkItemLink;
SVLinksDropdownContent.Item = LinkItem;
SVLinksDropdownContent.Item_USES = LINK_USES;
SVLinksDropdownContent.ItemLabel = LinkItemLabel;
SVLinksDropdownContent.ItemPreventClose = LinkItemPreventClose;
SVLinksDropdownContent.ItemLinkMain = LinkItemMain;
SVLinksDropdownContent.ItemToggle = LinkItemToggle;
SVLinksDropdownContent.Separator = LinkSeparator;

const SVDropdownContent = {
	Boards: SVBoardsDropdownContent,
	Users: SVUsersDropdownContent,
	Links: SVLinksDropdownContent,
};

export default SVDropdownContent;

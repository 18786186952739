import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type ReorderItemsMutationMutation,
	type ReorderItemsMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const ReorderItemsMutation: TypedDocumentNode<
	ReorderItemsMutationMutation,
	ReorderItemsMutationMutationVariables
> = gql`
	mutation ReorderItemsMutation($input: ReorderItemsInput!) {
		reorderItems(input: $input)
	}
`;

export default ReorderItemsMutation;

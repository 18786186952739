import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type SaveItemsMutationMutation,
	type SaveItemsMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const SaveItemsMutation: TypedDocumentNode<
	SaveItemsMutationMutation,
	SaveItemsMutationMutationVariables
> = gql`
	mutation SaveItemsMutation($input: SaveItemsInput!) {
		saveItems(input: $input) {
			auth {
				user {
					_id
					itemsCount
				}
				settings {
					_id
					needsUpgradeReason
				}
			}
			board {
				_id
				thumbnails {
					image {
						thumbnail
					}
				}
				itemsCount
				lastItemAddedOrder
			}
			assets {
				_id
				isSaved
				itemsCount
				ownBoards {
					_id
				}
			}
		}
	}
`;

export default SaveItemsMutation;

import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type UserItemsQueryQuery,
	type UserItemsQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';
import ItemFragment from './fragments/ItemFragment';

const UserItemsQuery: TypedDocumentNode<UserItemsQueryQuery, UserItemsQueryQueryVariables> = gql`
	${ItemFragment}

	query UserItemsQuery(
		$username: NonEmptyString!
		$sortMethod: ItemsSortMethod
		$limit: Int
		$cursor: String
	) {
		userByUsername(username: $username) {
			items(sortMethod: $sortMethod, limit: $limit, cursor: $cursor) {
				items {
					...ItemFragment
				}
				pageInfo {
					nextCursor
				}
			}
		}
	}
`;

export default UserItemsQuery;

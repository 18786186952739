import { combineReducers } from 'redux';
import grid from './grid';
import gridUpload from './gridUpload';
import ui from './ui';

// TODO: (graphql-after) Remove as much as possible from redux into useUIState

const reducers = {
	ui,
	grid,
	gridUpload,
};

const rootReducer = combineReducers<RootState>(reducers);

export default rootReducer;

export type RootState = {
	ui: ReturnType<typeof ui>;
	grid: ReturnType<typeof grid>;
	gridUpload: ReturnType<typeof gridUpload>;
};

import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type BoardItemsQueryQuery,
	type BoardItemsQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';
import ItemFragment from './fragments/ItemFragment';

const BoardItemsQuery: TypedDocumentNode<BoardItemsQueryQuery, BoardItemsQueryQueryVariables> = gql`
	${ItemFragment}

	query BoardItemsQuery(
		$boardID: ID!
		$sortMethod: ItemsSortMethod
		$limit: Int
		$cursor: String
	) {
		boardByID(boardID: $boardID) {
			items(sortMethod: $sortMethod, limit: $limit, cursor: $cursor) {
				items {
					...ItemFragment
				}
				pageInfo {
					nextCursor
				}
			}
		}
	}
`;

export default BoardItemsQuery;

import { type ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import SVActions from '@pkgs/shared-client/components/SVActions';
import SVSaveButtonContainer from './SVSaveButtonContainer';

type Props = {
	items: ItemFragmentFragment[];
	use?: ValueOf<typeof SVActions.USES>;
};

const SVSaveItemsActionContainer = ({ items, use }: Props) => (
	<SVSaveButtonContainer
		renderButton={({ isSaved, itemsCount: _, ...props }) => (
			<SVActions.Item keys={'s'} use={use} {...props}>
				{isSaved ? 'Saved' : 'Save'}
			</SVActions.Item>
		)}
		items={items}
	/>
);

export default SVSaveItemsActionContainer;

import React from 'react';
import wrapDisplayName from 'recompose/wrapDisplayName';
import SVNoSSR from './SVNoSSR';

const SVWithNoSSR = <P,>(WrappedComponent: React.ComponentType<P>) => {
	const SVWithNoSSR = (props: P) => {
		return (
			<SVNoSSR>
				<WrappedComponent {...(props as P & JSX.IntrinsicAttributes)} />
			</SVNoSSR>
		);
	};

	SVWithNoSSR.displayName = wrapDisplayName(WrappedComponent, 'SVWithNoSSR');

	return SVWithNoSSR;
};

export default SVWithNoSSR;

// import type { NextRouter } from 'next/router';
import { isSSR } from '@pkgs/shared-client/helpers/dom';
import Router from 'next/router';
import { useMount } from 'react-use';

// Export this so SVWithApolloApp can catch it and redirect on server side
export class AppRedirectError extends Error {
	public url: string;

	constructor(url: string) {
		super();

		this.url = url;
	}
}

type Props = {
	// to: Parameters<NextRouter['push']>[0];
	to: string;
	replace?: boolean;
};

const SVRedirect = ({ to, replace }: Props) => {
	// On SSR, throw an error so SVWithApolloApp can catch it and redirect
	if (isSSR) {
		throw new AppRedirectError(String(to));
	}

	useMount(() => {
		Router[replace ? 'replace' : 'push'](to);
	});

	return null;
};

export default SVRedirect;

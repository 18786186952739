const SubscriptionType = {
	INDIVIDUAL: 'individual',
	TEAM: 'team',
} as const;

export default SubscriptionType;

export function isValidSubscriptionType(type: any): type is ValueOf<typeof SubscriptionType> {
	return type && Object.values(SubscriptionType).includes(String(type));
}

export function getSubscriptionTypeLabel(type: ValueOf<typeof SubscriptionType>) {
	switch (type) {
		case SubscriptionType.INDIVIDUAL:
		default:
			return 'Individual';
		case SubscriptionType.TEAM:
			return 'Team';
	}
}

import convert from 'color-convert';

const colorHEXToHSV = (
	hexString: string,
): {
	h: number;
	s: number;
	v: number;
} | null => {
	const hsv = convert.hex.hsv(hexString.split('#').join(''));

	if (!hsv) {
		return null;
	}

	return {
		h: hsv[0],
		s: hsv[1],
		v: hsv[2],
	};
};

export default colorHEXToHSV;

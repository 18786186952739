import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	StartStripeUpdateBillingMethodMutationMutationVariables,
	type StartStripeUpdateBillingMethodMutationMutation,
} from '@apps/www/src/__generated__/graphql';

const StartStripeUpdateBillingMethodMutation: TypedDocumentNode<
	StartStripeUpdateBillingMethodMutationMutation,
	StartStripeUpdateBillingMethodMutationMutationVariables
> = gql`
	mutation StartStripeUpdateBillingMethodMutation($input: StartStripeUpdateBillingMethodInput!) {
		startStripeUpdateBillingMethod(input: $input) {
			sessionID
			url
		}
	}
`;

export default StartStripeUpdateBillingMethodMutation;

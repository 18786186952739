import { useQuery } from '@apollo/client';
import { type AuthSettings } from '@apps/www/src/__generated__/graphql';
import createAuthSettingsQuery from '@apps/www/src/www/queries/createAuthSettingsQuery';

const useAuthSettings = <T extends keyof AuthSettings>(
	fields: T[],
): Pick<AuthSettings, T | '_id'> | null => {
	const { data } = useQuery(createAuthSettingsQuery(fields), { fetchPolicy: 'cache-only' });

	return data?.auth?.settings || null;
};

export default useAuthSettings;

import { gql } from '@apollo/client';

const ItemFragment = gql`
	fragment ItemFragment on Item {
		_id
		shortID
		url
		createdAt
		name
		pageURL
		sourceDisplayURL
		sourceURL
		isPrivate
		isOwner
		user {
			_id
			name
			url
		}
		asset {
			_id
			image {
				thumbnail
				original
				ratio
				width
				height
			}
			isSaved
			itemsCount
			viewsCount
			colors {
				color
				amount
			}
			type
			ownBoards {
				_id
			}
		}
	}
`;

export default ItemFragment;

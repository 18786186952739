import IconCheckSVG from '@pkgs/shared-client/img/icon-check-inlined.svg';
import SVButton, { SVButtonSIZES, SVButtonUSES, type Props as SVButtonProps } from './SVButton';

type Props = Pick<SVButtonProps, 'size' | 'className'> & {
	onClick: React.UIEventHandler;
	isFollowing: boolean;
	isFollowingBack: boolean;
};

const SVFollowButton = ({ isFollowing, isFollowingBack, ...otherProps }: Props) => {
	return (
		<SVButton
			use={isFollowing ? SVButtonUSES.DEFAULT : SVButtonUSES.PRIMARY}
			icon={
				isFollowing && otherProps.size !== SVButtonSIZES.TINY ? <IconCheckSVG /> : undefined
			}
			{...otherProps}
		>
			{isFollowing ? 'Following' : isFollowingBack ? 'Follow back' : 'Follow'}
		</SVButton>
	);
};

export default SVFollowButton;

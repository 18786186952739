import { type ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import type SVActions from '@pkgs/shared-client/components/SVActions';
import type SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import SVTooltip from '@pkgs/shared-client/components/SVTooltip';
import config from '@pkgs/shared-client/config';
import { formatURL } from '@pkgs/shared-client/helpers/format';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import ctc from 'copy-to-clipboard';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';
import SVCreateListActionContainer from './SVCreateListActionContainer';

type Props = React.PropsWithChildren<
	(
		| {
				items: ItemFragmentFragment[];
		  }
		| {
				url: string;
		  }
	) &
		(
			| {
					Component: typeof SVDropdownContent.Links.ItemPreventClose;
					title?: string;
			  }
			| {
					Component: typeof SVActions.Item;
					title?: string;
			  }
		)
>;

const SVShareActionContainer = ({ Component, ...otherProps }: Props) => {
	const ref = useRef<HTMLButtonElement & HTMLDivElement>(null);
	const router = useRouter();

	const handleClick = useEventCallback(() => {
		const urlToShare = ('url' in otherProps ? otherProps.url : null) || router.asPath;

		shareURL(urlToShare);
	});

	const handleListCreate = useEventCallback((listURL: string) => {
		shareURL(listURL);
	});

	const shareURL = (urlToShare: string) => {
		ctc(formatURL(config.baseURL, urlToShare));

		if (ref.current) {
			SVTooltip.show(ref.current, 'Link copied.');
		}
	};

	if ('items' in otherProps) {
		return (
			// @ts-expect-error fix this later
			<SVCreateListActionContainer
				ref={ref}
				Component={Component}
				onCreate={handleListCreate}
				{...otherProps}
			/>
		);
	}

	const { url: _, ...otherPropsWithoutURL } = otherProps;

	return <Component ref={ref} onClick={handleClick} {...otherPropsWithoutURL} />;
};

export default SVShareActionContainer;

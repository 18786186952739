import { useQuery } from '@apollo/client';
import { type UserFragmentFragment } from '@apps/www/src/__generated__/graphql';
import AuthQuery from '@apps/www/src/www/queries/AuthQuery';

const useAuthBoards = (): UserFragmentFragment['boards'] | null => {
	const { data } = useQuery(AuthQuery);

	return data?.auth?.user.boards || null;
};

export default useAuthBoards;

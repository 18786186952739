import { useApolloClient } from '@apollo/client';
import { type NewTeamMutationMutationVariables } from '@apps/www/src/__generated__/graphql';
import useAllowedFeatureState, {
	ALLOWED_STATES,
} from '@apps/www/src/www/hooks/useAllowedFeatureState';
import NewTeamMutation from '@apps/www/src/www/queries/NewTeamMutation';
import SVForm from '@pkgs/shared-client/components/SVForm';
import { SVInputText } from '@pkgs/shared-client/components/SVFormInputs';
import SVModal, { useModalClose } from '@pkgs/shared-client/components/SVModal';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import AllowedFeature from '@pkgs/shared/enums/AllowedFeature';
import { useRouter } from 'next/router';

const SVNewTeamModal = SVModal.create(() => {
	const client = useApolloClient();
	const router = useRouter();
	const close = useModalClose();
	const hasTeamPlan =
		useAllowedFeatureState(AllowedFeature.MANAGE_TEAM) === ALLOWED_STATES.ALLOWED;

	const handleSubmit = useEventCallback(
		async (values: NewTeamMutationMutationVariables['input']) => {
			await client.mutate({
				mutation: NewTeamMutation,
				variables: {
					input: {
						...values,
					},
				},
			});

			router.push(`/team/users/${hasTeamPlan ? '' : '#upgrade'}`);

			close();
		},
	);

	return (
		<>
			<SVModal.Title>New Team</SVModal.Title>
			<SVForm
				onSubmit={handleSubmit}
				submitLabel="Create"
				initialValues={{ name: '', email: '' }}
				submitAlignment="right"
			>
				<SVInputText name="name" label="Name" autoComplete="off" required={true} />
				<SVInputText
					label="Contact Email"
					name="email"
					type="email"
					autoComplete="off"
					required={true}
				/>
			</SVForm>
		</>
	);
});

export default SVNewTeamModal;

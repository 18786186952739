import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type ToggleFollowUserMutationMutation,
	type ToggleFollowUserMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const ToggleFollowUserMutation: TypedDocumentNode<
	ToggleFollowUserMutationMutation,
	ToggleFollowUserMutationMutationVariables
> = gql`
	mutation ToggleFollowUserMutation($input: ToggleFollowUserInput!) {
		toggleFollowUser(input: $input) {
			user {
				_id
				isFollowing
			}
			auth {
				user {
					_id
					followingCount
				}
			}
		}
	}
`;

export default ToggleFollowUserMutation;

import { useApolloClient } from '@apollo/client';
import {
	type Item,
	type NewBoardMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';
import NewBoardMutation from '@apps/www/src/www/queries/NewBoardMutation';
import SVForm from '@pkgs/shared-client/components/SVForm';
import {
	SVInputCheckbox,
	SVInputStatic,
	SVInputText,
} from '@pkgs/shared-client/components/SVFormInputs';
import SVFormLayout from '@pkgs/shared-client/components/SVFormLayout';
import SVModal, { useModalClose } from '@pkgs/shared-client/components/SVModal';
import plural from '@pkgs/shared-client/helpers/plural';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';
import { useRouter } from 'next/router';

const SVNewBoardModal = SVModal.create(
	({
		items,
		fromExtension,
		ownershipType,
		autoRedirect = true,
	}: {
		items?: Array<Pick<Item, '_id'>>;
		fromExtension?: boolean;
		ownershipType: ValueOf<typeof BoardOwnershipType>;
		autoRedirect?: boolean;
	}) => {
		const client = useApolloClient();
		const router = useRouter();
		const close = useModalClose();

		const isTeamBoard = ownershipType === BoardOwnershipType.TEAM;

		const handleSubmit = useEventCallback(
			async (
				values: Omit<
					NewBoardMutationMutationVariables['input'],
					'itemIDs' | 'ownershipType'
				>,
			) => {
				const itemIDs = items?.map((item) => item._id);

				const { data } = await client.mutate({
					mutation: NewBoardMutation,
					variables: {
						input: {
							itemIDs,
							ownershipType,
							...values,
						},
					},
				});

				// Redirect to new board if no items were added to it
				if (!fromExtension && !items?.length && data && autoRedirect) {
					router.push(data.newBoard.board.url);
				}

				close();
			},
		);

		return (
			<>
				<SVModal.Title>New {isTeamBoard ? 'Team ' : ' '}Board</SVModal.Title>
				<SVForm
					onSubmit={handleSubmit}
					submitLabel="Create"
					initialValues={{
						name: '',
						isPrivate: isTeamBoard ? true : false,
						isTeamEditorAnEditor: isTeamBoard,
					}}
					submitAlignment="right"
				>
					{isTeamBoard ? (
						<SVFormLayout.P>
							Team boards enable collaboration among your entire team.
						</SVFormLayout.P>
					) : null}
					{items && items.length > 0 ? (
						<SVInputStatic value={plural(items.length, 'save')} />
					) : null}
					<SVInputText name="name" label="Name" required={true} />
					<SVInputCheckbox
						name="isPrivate"
						label="Private"
						isDisabled={isTeamBoard}
						sublabel={
							isTeamBoard
								? 'Team boards are always private'
								: 'So only you and collaborators can see it.'
						}
					/>
					{isTeamBoard ? (
						<SVInputCheckbox
							name="isTeamEditorAnEditor"
							label="Team editor can edit"
							sublabel="If disabled, only team owners, admins and curators can add/remove saves from this board."
						/>
					) : null}
				</SVForm>
			</>
		);
	},
);

export default SVNewBoardModal;

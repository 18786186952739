import { useApolloClient } from '@apollo/client';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import TrackUpgradeClickMutation from '@apps/www/src/www/queries/TrackUpgradeClickMutation';
import SVA from '@pkgs/shared-client/components/SVA';
import SVButton, { SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import SVLink from '@pkgs/shared-client/components/SVLink';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import UpgradeButtonSource from '@pkgs/shared/enums/UpgradeButtonSource';
import React from 'react';

const SVTrackedUpgradeButtonContainer = ({
	source,
	children,
	onClick,
	to,
	className,
	title,
	...props
}: React.PropsWithChildren<{
	source: ValueOf<typeof UpgradeButtonSource>;
	onClick?: () => void | false;
	to?: string;
	className?: string;
	title?: string;
}> &
	(
		| ({
				Component?: typeof SVButton;
		  } & Pick<React.ComponentProps<typeof SVButton>, 'use' | 'size'>)
		| {
				Component: typeof SVA | typeof SVDropdownContent.Links.ItemLink | 'a';
		  }
	)) => {
	const isLoggedIn = useIsLoggedIn();
	const client = useApolloClient();

	const handleClick = useEventCallback((event: React.UIEvent) => {
		if (isLoggedIn) {
			// set and forget, no need to wait for response
			client.mutate({
				mutation: TrackUpgradeClickMutation,
				variables: {
					input: {
						source,
					},
				},
			});
		}

		if (onClick) {
			const result = onClick();

			if (result === false) {
				event.preventDefault();
			}
		}
	});

	const Component = props.Component || SVButton;

	return (
		<Component
			className={className}
			title={title}
			Component={SVLink}
			to={to || '/billing/'}
			// @ts-expect-error fine here
			size={Component === SVButton ? props.size : undefined}
			// @ts-expect-error fine here
			use={Component === SVButton ? props.use || SVButtonUSES.PRIMARY : undefined}
			onClick={handleClick}
		>
			{children}
		</Component>
	);
};

export default SVTrackedUpgradeButtonContainer;

import TeamUserRole from '@pkgs/shared/enums/TeamUserRole';

export default function teamUserRoleHasTeamUserRolePrivileges(
	teamUserRole: ValueOf<typeof TeamUserRole> | null | undefined,
	teamUserRoleToCheck: ValueOf<typeof TeamUserRole>,
) {
	if (!teamUserRole) {
		return false;
	}

	if (teamUserRoleToCheck === TeamUserRole.OWNER) {
		return teamUserRole === TeamUserRole.OWNER;
	} else if (teamUserRoleToCheck === TeamUserRole.ADMIN) {
		return teamUserRole === TeamUserRole.OWNER || teamUserRole === TeamUserRole.ADMIN;
	} else if (teamUserRoleToCheck === TeamUserRole.CURATOR) {
		return (
			teamUserRole === TeamUserRole.OWNER ||
			teamUserRole === TeamUserRole.ADMIN ||
			teamUserRole === TeamUserRole.CURATOR
		);
	} else if (teamUserRoleToCheck === TeamUserRole.EDITOR) {
		return (
			teamUserRole === TeamUserRole.OWNER ||
			teamUserRole === TeamUserRole.ADMIN ||
			teamUserRole === TeamUserRole.CURATOR ||
			teamUserRole === TeamUserRole.EDITOR
		);
	}

	return false;
}

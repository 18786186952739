import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type TeamFeedItemsQueryQuery,
	type TeamFeedItemsQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';
import ItemFragment from './fragments/ItemFragment';

const TeamFeedItemsQuery: TypedDocumentNode<
	TeamFeedItemsQueryQuery,
	TeamFeedItemsQueryQueryVariables
> = gql`
	${ItemFragment}

	query TeamFeedItemsQuery($cursor: String) {
		auth {
			team {
				feedItems(cursor: $cursor) {
					items {
						...ItemFragment
					}
					pageInfo {
						nextCursor
					}
				}
			}
		}
	}
`;

export default TeamFeedItemsQuery;

import { scrollToElementAnimated } from '@pkgs/shared-client/helpers/dom';
import { useLayoutEffect, useRef } from 'react';

const SVScrollTo = ({ children }: OnlyChildrenProps) => {
	const elementRef = useRef(null);

	useLayoutEffect(() => {
		elementRef.current && scrollToElementAnimated(elementRef.current);
	}, [elementRef]);

	return <div ref={elementRef}>{children}</div>;
};

export default SVScrollTo;

const Language = {
	EN_US: 'en_US',
	PT_BR: 'pt_BR',
} as const;

export const getLanguageLabel = (language: ValueOf<typeof Language>) => {
	switch (language) {
		case Language.PT_BR:
			return 'Português';
		default:
		case Language.EN_US:
			return 'English';
	}
};

export const getLanguageFromCountryCode = (countryCode: string) => {
	switch (countryCode) {
		case 'AO': // Angola
		case 'BR': // Brazil
		case 'PT': // Portugal
		case 'ST': // São Tomé and Príncipe
		case 'MZ': // Mozambique
			return Language.PT_BR;
		default:
			return Language.EN_US;
	}
};

export default Language;

import useUpgradeWarning from '@apps/www/src/www/hooks/useUpgradeWarning';
import SVUpgradeWarning from '@pkgs/shared-client/components/SVUpgradeWarning';

const SVUpgradeWarningContainer = () => {
	const upgradeWarning = useUpgradeWarning();

	if (upgradeWarning) {
		return <SVUpgradeWarning {...upgradeWarning} />;
	}

	return null;
};

export default SVUpgradeWarningContainer;

import { gql, type TypedDocumentNode } from '@apollo/client';
import { type AuthUserQueryQuery } from '@apps/www/src/__generated__/graphql';
import UserFragment from './fragments/UserFragment';

const AuthUserQuery: TypedDocumentNode<AuthUserQueryQuery> = gql`
	${UserFragment}

	query AuthUserQuery {
		auth {
			user {
				...UserFragment
			}
		}
	}
`;

export default AuthUserQuery;

import { type RootState } from '@apps/www/src/www/reducers';
import { updateUIState } from '@apps/www/src/www/reducers/ui';
import { type LikeItem } from '@pkgs/shared-client/components/SVGrid';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import { type ViewportNameType } from '@pkgs/shared/constants';
import ItemsSortMethod from '@pkgs/shared/enums/ItemsSortMethod';
import Language from '@pkgs/shared/enums/Language';
import type ViewportKind from '@pkgs/shared/enums/ViewportKind';
import { useDispatch, useSelector } from 'react-redux';

export const UIStateKeys = {
	GRID_COLUMNS_RATIO: 'grid_columns_ratio',
	GRID_SPACING_RATIO: 'grid_spacing_ratio',
	DARK_MODE: 'dark_mode',
	FIT_TO_SCREEN: 'fit_to_screen',
	FEED_BANNER_EXPANDED: 'feed_banner_expanded',
	MODAL_ONBOARD_OPEN: 'modal_onboard_open',
	LOCK_COUNT: 'lock_count',
	VIEWPORT_NAME: 'viewport_name',
	VIEWPORT_KIND: 'viewport_kind',
	SHOW_LOADING: 'show_loading',
	CURRENT_PAGE_GRID_ITEMS: 'current_page_grid_items',
	SHOW_GRID_CONTROLS: 'show_grid_controls',
	SKIP_ITEM_DETAILS: 'skip_item_details',
	ITEMS_SORT_METHOD: 'items_sort_method',
	LANGUAGE: 'language',
	MUTED: 'muted',
} as const;

export type State = {
	[UIStateKeys.GRID_COLUMNS_RATIO]: number;
	[UIStateKeys.GRID_SPACING_RATIO]: number;
	[UIStateKeys.DARK_MODE]: boolean;
	[UIStateKeys.FIT_TO_SCREEN]: boolean;
	[UIStateKeys.FEED_BANNER_EXPANDED]: boolean;
	[UIStateKeys.MODAL_ONBOARD_OPEN]: boolean;
	[UIStateKeys.LOCK_COUNT]: number;
	[UIStateKeys.VIEWPORT_NAME]: ViewportNameType | null;
	[UIStateKeys.VIEWPORT_KIND]: ValueOf<typeof ViewportKind> | null;
	[UIStateKeys.SHOW_LOADING]: boolean;
	[UIStateKeys.CURRENT_PAGE_GRID_ITEMS]: LikeItem[];
	[UIStateKeys.SHOW_GRID_CONTROLS]: boolean;
	[UIStateKeys.SKIP_ITEM_DETAILS]: boolean;
	[UIStateKeys.ITEMS_SORT_METHOD]: ValueOf<typeof ItemsSortMethod>;
	[UIStateKeys.LANGUAGE]: ValueOf<typeof Language>;
	[UIStateKeys.MUTED]: boolean;
};

export const UIStateDefaults: State = {
	[UIStateKeys.GRID_COLUMNS_RATIO]: 0.5,
	[UIStateKeys.GRID_SPACING_RATIO]: 0.75,
	[UIStateKeys.DARK_MODE]: true,
	[UIStateKeys.FIT_TO_SCREEN]: false,
	[UIStateKeys.FEED_BANNER_EXPANDED]: false,
	[UIStateKeys.MODAL_ONBOARD_OPEN]: false,
	[UIStateKeys.LOCK_COUNT]: 0,
	// No viewportName on first render to not break hydration from SSR
	// since viewportName is undefined on server (no way to tell viewport width/height)
	[UIStateKeys.VIEWPORT_NAME]: null,
	// viewportKind value comes from server on SSR
	[UIStateKeys.VIEWPORT_KIND]: null,
	[UIStateKeys.SHOW_LOADING]: false,
	[UIStateKeys.CURRENT_PAGE_GRID_ITEMS]: [],
	[UIStateKeys.SHOW_GRID_CONTROLS]: false,
	// When viewing a private board with a public link, we don't need to load the details as it would fail without additional context
	[UIStateKeys.SKIP_ITEM_DETAILS]: false,
	[UIStateKeys.ITEMS_SORT_METHOD]: ItemsSortMethod.CUSTOM,
	// Detects language from browser settings, as default language
	[UIStateKeys.LANGUAGE]: (() => {
		if (typeof navigator !== 'undefined') {
			return navigator.language.startsWith('pt-') ? Language.PT_BR : Language.EN_US;
		}

		return Language.EN_US;
	})(),
	[UIStateKeys.MUTED]: false,
};

const useUIState = <K extends ValueOf<typeof UIStateKeys>>(
	key: K,
): [State[K], (value: State[K]) => void] => {
	const value = useSelector((state: RootState) => readUIStateFromStoreState(state, key));
	const setState = useSetUIState(key);

	return [value, setState];
};

export const useSetUIState = <K extends ValueOf<typeof UIStateKeys>>(
	key: K,
): ((value: State[K]) => void) => {
	const dispatch = useDispatch();

	return useEventCallback((value) => {
		dispatch(updateUIState(key, value));
	});
};

export const readUIStateFromStoreState = <K extends ValueOf<typeof UIStateKeys>>(
	state: RootState,
	key: K,
): State[K] => state.ui[key];

export default useUIState;

import { useMutation } from '@apollo/client';
import { type ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import useAllowedFeatureCheckEvent from '@apps/www/src/www/hooks/useAllowedFeatureCheckEvent';
import UpdateItemsPrivacyMutation from '@apps/www/src/www/queries/UpdateItemsPrivacyMutation';
import AllowedFeature from '@pkgs/shared/enums/AllowedFeature';
import React, { useEffect } from 'react';
import SVWithTooltipErrorBoundary, {
	type Props as SVWithTooltipErrorBoundaryProps,
} from './SVWithTooltipErrorBoundary';

type Props = {
	items: ItemFragmentFragment[];
	render: (
		props: React.PropsWithChildren<{
			onClick: (event: React.UIEvent) => void;
			isLoading: boolean;
			isPressed: boolean;
			title: string;
		}>,
	) => JSX.Element;
} & SVWithTooltipErrorBoundaryProps;

const _SVToggleItemsPrivacyActionContainer = ({ items, render, showTooltipError }: Props) => {
	const itemIDs = items.map((item) => item._id);
	const isPrivate = !!items.some((item) => item.isPrivate);

	const [updateItemsPrivacy, { loading, error }] = useMutation(UpdateItemsPrivacyMutation, {
		variables: {
			input: {
				itemIDs,
				isPrivate: !isPrivate,
			},
		},
	});

	const handleClick = useAllowedFeatureCheckEvent(AllowedFeature.PRIVATE_ITEM, () => {
		if (!items.length) {
			return;
		}

		updateItemsPrivacy();
	});

	useEffect(() => {
		showTooltipError(error);
	}, [showTooltipError, error]);

	return (
		<>
			{render({
				onClick: handleClick,
				isLoading: loading,
				isPressed: isPrivate,
				title: isPrivate ? 'Make public' : 'Make private',
				children: <>Private</>,
			})}
		</>
	);
};

const SVToggleItemsPrivacyActionContainer = SVWithTooltipErrorBoundary(
	_SVToggleItemsPrivacyActionContainer,
);

export default SVToggleItemsPrivacyActionContainer;

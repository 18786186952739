import Picker from '@emoji-mart/react';
import { Suspense, useEffect, useState } from 'react';

const SVEmojiPicker = ({
	onEmojiSelect,
	previewPosition = true,
	searchPosition = true,
	isDarkMode,
}: {
	onEmojiSelect: (emoji: any) => void;
	previewPosition?: boolean | null;
	searchPosition?: boolean | null;
	isDarkMode: boolean;
}) => {
	const [emojiData, setEmojiData] = useState<any>(null);

	useEffect(() => {
		import('@emoji-mart/data').then((module) => setEmojiData(module.default));
	}, []);

	if (!emojiData) {
		return null;
	}

	return (
		<Suspense fallback={null}>
			<Picker
				data={emojiData}
				onEmojiSelect={onEmojiSelect}
				perLine={7}
				previewPosition={previewPosition}
				searchPosition={searchPosition}
				theme={isDarkMode ? 'dark' : 'light'}
			/>
		</Suspense>
	);
};

export default SVEmojiPicker;

/**
 * Given some information from a user, it returns the active subscription tiers.
 *
 * This is necessary to simulate subscriptions for `moderator` and `beta` users.
 */

import SubscriptionTier from '@pkgs/shared/enums/SubscriptionTier';

export default function getUserActiveSubscriptionTiersFromInfo(info: {
	moderator: boolean;
	beta: boolean;
	activeSubscriptionTiers: ValueOf<typeof SubscriptionTier>[];
}): ValueOf<typeof SubscriptionTier>[] {
	if (info.moderator) {
		return [SubscriptionTier.SITE_MAKER, SubscriptionTier.ENTERPRISE];
	}

	if (info.beta) {
		return [SubscriptionTier.PRO];
	}

	return info.activeSubscriptionTiers;
}

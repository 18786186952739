import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

const SIZES = {
	DEFAULT: 'default',
	LARGE: 'large',
	SMALL: 'small',
	EXTRA_LARGE: 'extra-large',
	FULL_SCREEN: 'FULL_SCREEN',
} as const;

const USE = {
	DETAIL_OVERLAY: 'use-detail-overlay',
	BILLING_CYCLE: 'billing-cycle',
	EXTENSION_SETTINGS: 'extension-settings',
} as const;

type Props = React.PropsWithChildren<{
	size?: ValueOf<typeof SIZES>;
	className?: string;
	isDisabled?: boolean;
	isPressed: boolean;
	onClick?: React.MouseEventHandler;
	use?: ValueOf<typeof USE>;
	optionsChildren?: React.ReactNode;
}>;

const SVToggle = ({
	size = SIZES.DEFAULT,
	className,
	children,
	isDisabled,
	isPressed,
	onClick,
	use,
	optionsChildren,
}: Props) => (
	<div className={twMerge('flex-center relative cursor-pointer', className)} onClick={onClick}>
		<span className="absolute block h-full min-h-[32px] w-full min-w-[32px]"></span>
		<div
			className={clsx(
				'duration-over flex-center relative rounded-full transition-all ease-out',
				size === SIZES.LARGE && use === USE.BILLING_CYCLE && 'h-11 w-52 rounded-xl',
				size === SIZES.LARGE && use !== USE.BILLING_CYCLE && 'h-8 w-14',
				size === SIZES.LARGE && !!children && 'mr-6',
				size === SIZES.DEFAULT && 'h-5 w-9',
				size === SIZES.DEFAULT && !!children && 'mr-3',
				size === SIZES.SMALL && 'h-4 w-7',
				size === SIZES.SMALL && !!children && 'mr-2',
				size === SIZES.EXTRA_LARGE && 'h-10 w-40',
				size === SIZES.FULL_SCREEN && 'h-16 w-80',
				use !== USE.DETAIL_OVERLAY &&
					use !== USE.BILLING_CYCLE &&
					(isDisabled
						? 'pointer-events-none cursor-default bg-transparent ring-1 ring-inset ring-gray-800 ring-opacity-70'
						: 'bg-brand cursor-pointer'),

				use !== USE.DETAIL_OVERLAY &&
					use !== USE.BILLING_CYCLE &&
					use !== USE.EXTENSION_SETTINGS &&
					(isPressed ? 'bg-brand' : 'bg-gray-800'),
				(use === USE.DETAIL_OVERLAY || use === USE.BILLING_CYCLE) &&
					'bg-gray-800 bg-opacity-60',
				use === USE.EXTENSION_SETTINGS && (isPressed ? 'bg-brand' : 'bg-gray-700'),
			)}
		>
			{optionsChildren && <div className="z-20">{optionsChildren}</div>}
			<div
				className={clsx(
					'duration-over absolute rounded-full transition-all ease-out',
					size === SIZES.LARGE && use === USE.BILLING_CYCLE && 'h-9 w-24 rounded-xl',
					size === SIZES.LARGE && use !== USE.BILLING_CYCLE && 'h-6 w-6',
					size === SIZES.DEFAULT && 'h-4 w-4',
					size === SIZES.SMALL && ' h-3 w-3',
					size === SIZES.EXTRA_LARGE && 'mt-0.5 h-8 w-20',
					size === SIZES.FULL_SCREEN && 'mt-0.5 h-[56px] w-36',
					!isDisabled && use !== USE.BILLING_CYCLE && 'theme-dark',
					isDisabled ? 'bg-gray-800' : use !== USE.DETAIL_OVERLAY && 'bg-primary',
					size === SIZES.LARGE ? 'top-[4px]' : 'top-[2px]',
					size === SIZES.LARGE &&
						use !== USE.BILLING_CYCLE &&
						(isPressed ? 'left-[28px]' : 'left-[4px]'),
					size === SIZES.LARGE &&
						use === USE.BILLING_CYCLE &&
						(isPressed ? 'left-[4px]' : 'right-[4px]'),
					size === SIZES.EXTRA_LARGE && (isPressed ? 'left-[76px]' : 'left-[4px]'),
					size === SIZES.FULL_SCREEN && (isPressed ? ' right-[4px]' : 'left-[4px]'),
					size === SIZES.DEFAULT && (isPressed ? 'left-[18px]' : 'left-[2px]'),
					size === SIZES.SMALL && (isPressed ? 'left-[14px]' : 'left-[2px]'),
					use === USE.DETAIL_OVERLAY && 'bg-background',
					use === USE.BILLING_CYCLE && 'bg-primary',
				)}
			/>
		</div>
		{children}
	</div>
);

SVToggle.SIZES = SIZES;

SVToggle.USES = USE;

export default SVToggle;

import BoardUserRole from '@pkgs/shared/enums/BoardUserRole';

export default function boardUserRoleHasBoardUserRolePrivileges(
	boardUserRole: ValueOf<typeof BoardUserRole> | undefined | null,
	boardUserRoleToCheck: ValueOf<typeof BoardUserRole>,
) {
	if (!boardUserRole) {
		return false;
	}

	if (boardUserRoleToCheck === BoardUserRole.OWNER) {
		return boardUserRole === BoardUserRole.OWNER;
	} else if (boardUserRoleToCheck === BoardUserRole.ADMIN) {
		return boardUserRole === BoardUserRole.OWNER || boardUserRole === BoardUserRole.ADMIN;
	} else if (boardUserRoleToCheck === BoardUserRole.EDITOR) {
		return (
			boardUserRole === BoardUserRole.OWNER ||
			boardUserRole === BoardUserRole.ADMIN ||
			boardUserRole === BoardUserRole.EDITOR
		);
	} else if (boardUserRoleToCheck === BoardUserRole.VIEWER) {
		return Boolean(boardUserRole);
	}

	return false;
}

import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import clsx from 'clsx';
import closest from 'dom-closest';
import React, { PropsWithChildren, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { twMerge } from 'tailwind-merge';
import wordWrap from 'word-wrap';
import SVTransition from './SVTransition';

const _Tooltip = React.memo(
	React.forwardRef<HTMLDivElement, React.PropsWithChildren<{ className?: string }>>(
		({ className, children }, forwardedRef) => (
			<div
				ref={forwardedRef}
				className={twMerge(
					'tooltip z-index-max type-label bg-secondary text-background whitespace-nowrap rounded-md px-3 py-2 text-center indent-0 text-[11px] font-semibold normal-case',
					className,
				)}
			>
				{children}
			</div>
		),
	),
);

function findContainer(element: HTMLElement) {
	while (
		element?.parentNode &&
		'tagName' in element &&
		(element.tagName == 'A' || element.tagName == 'BUTTON' || element.tagName == 'SPAN')
		// || element.classList.contains('dropdown-container')
	) {
		element = element.parentNode as HTMLElement;
	}

	return element;
}

const POPPER_OPTIONS = {
	placement: 'auto',
	strategy: 'fixed',
	modifiers: [
		{
			name: 'flip',
			options: {
				allowedAutoPlacements: ['top', 'bottom'], // by default, all the placements are allowed
			},
		},
		{
			name: 'offset',
			options: {
				offset: [0, 12],
			},
		},
		{
			name: 'preventOverflow',
			options: {
				padding: 32,
			},
		},
	],
};

const _PositionAwareTooltip = ({
	isOpen,
	onExited,
	children,
	...props
}: PropsWithChildren<{
	isOpen: boolean;
	onExited: () => void;
}>) => {
	let trimmedChildren = children;

	if (typeof trimmedChildren === 'string') {
		const trimmedChildrenArray = wordWrap(trimmedChildren, { width: 40 })
			.split('<br />')
			.join('\n')
			.split('<br>')
			.join('\n');

		trimmedChildren = (
			<>
				{trimmedChildrenArray.split('\n').map((chunk, index) => (
					<span key={index} className="block">
						{chunk}
					</span>
				))}
			</>
		);
	}

	const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
	const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
	const { styles, attributes /* , state */ } = usePopper(
		referenceElement,
		popperElement,
		// @ts-expect-error can't cast placement
		POPPER_OPTIONS,
	);

	const setReferenceRef = useEventCallback((ref: HTMLElement | null) => {
		if (ref) {
			setReferenceElement(ref.parentElement);
		}
	});

	// const isInverted = state?.placement === 'top';
	const classEnterFrom = 'opacity-0';
	const classEnterTo = 'opacity-100';

	return (
		<div ref={setReferenceRef}>
			<SVTransition
				show={isOpen}
				className="z-index-max duration-over absolute bottom-0 transition-all ease-out"
				enterFrom={classEnterFrom}
				enterTo={classEnterTo}
				leaveFrom={clsx(classEnterTo, 'pointer-events-none')}
				leaveTo={clsx(classEnterFrom, 'pointer-events-none')}
				afterLeave={onExited}
			>
				<div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
					<_Tooltip {...props}>{trimmedChildren}</_Tooltip>
				</div>
			</SVTransition>
		</div>
	);
};

type Props = React.PropsWithChildren<{
	__secret: string;
}>;

class SVTooltip extends React.Component<
	Props,
	{
		isOpen: boolean;
	}
> {
	static _Tooltip = _PositionAwareTooltip; // For tests only

	static show = (element: HTMLElement, label: string) => {
		setTimeout(() => {
			// const insideDropdown = matchesClosest(element, '.dropdown-trigger');
			// if (insideDropdown) {
			// 	element = insideDropdown;
			// }

			const containerElement = findContainer(element);

			// Remove any existing tooltip
			const existingTooltips = Array.from(
				containerElement.querySelectorAll('.tooltip-wrapper'),
			);
			existingTooltips.forEach((element) => element.parentNode?.removeChild(element));

			containerElement.classList.add('tooltip-container');

			const tooltipElement = document.createElement('div');
			tooltipElement.classList.add('tooltip-wrapper');

			containerElement.appendChild(tooltipElement);

			ReactDOM.render(<SVTooltip __secret="private">{label}</SVTooltip>, tooltipElement);

			tooltipElement.offsetTop; // force flush
		}, 50);
	};

	state = {
		isOpen: false,
	};

	closeTimeout: ReturnType<typeof setTimeout> | null = null;

	constructor(props: Props) {
		super(props);

		if (props.__secret !== 'private') {
			throw new Error('SVTooltip can`t be used directly. Use `SVTooltip.show`');
		}
	}

	componentDidMount() {
		this.setState({
			isOpen: true,
		});

		this.closeTimeout = setTimeout(() => {
			if (this.closeTimeout) {
				this.setState({
					isOpen: false,
				});
			}
		}, 1500);
	}

	componentWillUnmount() {
		if (this.closeTimeout) {
			clearTimeout(this.closeTimeout);
			this.closeTimeout = null;
		}
	}

	handleCloseEnd = (element: HTMLElement | null = null) => {
		// TODO: SVTransition doesn't return an element here. Check on storybook
		// if the dom is actually being removed after the tooltip closes
		const tooltipElement = element && closest(element, '.tooltip-wrapper');

		if (tooltipElement && tooltipElement.parentNode) {
			tooltipElement.parentNode.removeChild(tooltipElement);
		}

		if (this.closeTimeout) {
			clearTimeout(this.closeTimeout);
			this.closeTimeout = null;
		}
	};

	render() {
		const { children } = this.props;
		const { isOpen } = this.state;

		return (
			<_PositionAwareTooltip isOpen={isOpen} onExited={this.handleCloseEnd}>
				{children}
			</_PositionAwareTooltip>
		);
	}
}

export default SVTooltip;

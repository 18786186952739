/**
 * Use this hook to check if user has permission to perform an action that is
 * restricted to users with specific subscription plans.
 */
import useAllowedFeatureState, {
	ALLOWED_STATES,
} from '@apps/www/src/www/hooks/useAllowedFeatureState';
import SVUpgradeRequiredModal from '@apps/www/src/www/modals/SVUpgradeRequiredModal';
import SVModal from '@pkgs/shared-client/components/SVModal';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import AllowedFeature from '@pkgs/shared/enums/AllowedFeature';
import appendQueryParamsToURL from '@pkgs/shared/helpers/appendQueryParamsToURL';
import { useRouter } from 'next/router';

// TODO: (graphql-after) Remove some of these errors from here as the server already return them for add_item, add_board...

function preventDefault(maybeEvent?: any) {
	if (
		maybeEvent &&
		typeof maybeEvent === 'object' &&
		'preventDefault' in maybeEvent &&
		typeof maybeEvent.preventDefault === 'function'
	) {
		maybeEvent.preventDefault();
	}
}

export default function useAllowedFeatureCheckEvent<T>(
	allowedFeature: ValueOf<typeof AllowedFeature> | null | undefined,
	callback?: (arg?: T) => void,
) {
	const router = useRouter();
	const allowedState = useAllowedFeatureState(allowedFeature);

	return useEventCallback((arg?: T) => {
		if (allowedFeature) {
			if (allowedState === ALLOWED_STATES.LOGGED_OUT) {
				// not logged in
				const next = router.asPath !== '/' ? router.asPath : undefined;

				preventDefault(arg);

				return router.push(appendQueryParamsToURL('/login/', next ? { next } : {}));
			} else if (allowedState === ALLOWED_STATES.NOT_ALLOWED) {
				// not allowed

				preventDefault(arg);

				return SVModal.open(SVUpgradeRequiredModal, {
					reasonType: allowedFeature,
				});
			}
		}

		if (callback) {
			return callback(arg);
		}
	});
}

import { useQuery } from '@apollo/client';
import { type Item, type ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import ItemQuery from '@apps/www/src/www/queries/ItemQuery';
import type SVLink from '@pkgs/shared-client/components/SVLink';
import { createSelector } from 'reselect';
import SVGlobalLoadingWrapper from './SVGlobalLoadingWrapper';
import SVGridDetailOverlayContainer from './SVGridDetailOverlayContainer';

const itemSelector = createSelector(
	(item: ItemFragmentFragment) => item,
	(item): [ItemFragmentFragment, ItemFragmentFragment[]] => [item, [item]],
);

type Props = {
	itemShortID: Item['shortID'];
	closeURL?: React.ComponentProps<typeof SVLink>['to'];
};

const SVGridDetailStandaloneContainer = ({ itemShortID, closeURL }: Props) => {
	const { loading, data, error } = useQuery(ItemQuery, {
		variables: { shortID: itemShortID },
	});

	if (error) {
		throw error;
	}

	return (
		<SVGlobalLoadingWrapper isLoading={loading}>
			{() => {
				if (!data) {
					return null;
				}

				const [item, items] = itemSelector(data.itemByShortID);

				return (
					<SVGridDetailOverlayContainer item={item} items={items} closeURL={closeURL} />
				);
			}}
		</SVGlobalLoadingWrapper>
	);
};

export default SVGridDetailStandaloneContainer;

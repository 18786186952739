import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import SVScrollTo from './SVScrollTo';

const USES = {
	SUCCESS: 'success',
	ERROR: 'error',
} as const;

const defaultProps: {
	use: ValueOf<typeof USES>;
	scrollTo: boolean;
} = {
	use: USES.SUCCESS,
	// TODO: Dirty hack. ScrollTo should be aware where is the closest `overflow: scroll` parent falling back to `document`.
	// then this prop shouldn't exist as it's only useful inside modals but Forms don't take that into account. Slight annoying bug...
	scrollTo: true,
};

type Props = React.PropsWithChildren<
	Partial<typeof defaultProps> & {
		className?: string;
	}
>;

const SVMessage = ({ use, scrollTo, className, children }: Props) => {
	const Wrapper = scrollTo ? SVScrollTo : 'div';

	return (
		<Wrapper>
			<p
				className={twMerge(
					clsx(
						'type-small block pb-4 !text-left font-semibold',
						use === USES.SUCCESS && 'text-brand',
						use === USES.ERROR && 'text-danger',
					),
					className,
				)}
			>
				{children}
			</p>
		</Wrapper>
	);
};

SVMessage.USES = USES;

SVMessage.defaultProps = defaultProps;

export default SVMessage;

import { UIStateDefaults, type UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import { ui } from './actionTypes';

type State = {
	isGridSettingsOpen: boolean;
	isNavOpen: boolean;
	lockCount: number;
	isLocked: boolean;
	isGridDetailsInfoShown: boolean;
	isGridUploadOpen: boolean;
	isNotificationsOpen: boolean;
} & typeof UIStateDefaults;

const getInitialState = (): State => {
	return {
		isGridSettingsOpen: false,
		isNavOpen: false,
		lockCount: 0,
		isLocked: false,
		isGridDetailsInfoShown: false,
		isGridUploadOpen: false,
		isNotificationsOpen: false,
		...UIStateDefaults,
	};
};

type Action =
	| ReturnType<typeof openGridSettings>
	| ReturnType<typeof closeGridSettings>
	| ReturnType<typeof openNotifications>
	| ReturnType<typeof closeNotifications>
	| ReturnType<typeof openNav>
	| ReturnType<typeof closeNav>
	| ReturnType<typeof showGridDetailsInfo>
	| ReturnType<typeof hideGridDetailsInfo>
	| ReturnType<typeof openGridUpload>
	| ReturnType<typeof closeGridUpload>
	| ReturnType<typeof closeOverlays>
	| ReturnType<typeof updateUIState>;

export default function uiReducer(state: State = getInitialState(), action: Action): State {
	switch (action.type) {
		case ui.OPEN_GRID_SETTINGS:
		case ui.CLOSE_GRID_SETTINGS:
			return {
				...state,
				isGridSettingsOpen: action.type === ui.OPEN_GRID_SETTINGS,
			};
		case ui.OPEN_NOTIFICATIONS:
		case ui.CLOSE_NOTIFICATIONS:
			return {
				...state,
				isNotificationsOpen: action.type === ui.OPEN_NOTIFICATIONS,
			};
		case ui.OPEN_NAV:
		case ui.CLOSE_NAV:
			return {
				...state,
				isNavOpen: action.type === ui.OPEN_NAV,
			};
		case ui.SHOW_GRID_DETAILS_INFO:
		case ui.HIDE_GRID_DETAILS_INFO:
			return {
				...state,
				isGridDetailsInfoShown: action.type === ui.SHOW_GRID_DETAILS_INFO,
			};
		case ui.OPEN_GRID_UPLOAD:
		case ui.CLOSE_GRID_UPLOAD:
			return {
				...state,
				isGridUploadOpen: action.type === ui.OPEN_GRID_UPLOAD,
			};
		case ui.CLOSE_OVERLAYS:
			return {
				...state,
				isNavOpen: false,
				isGridSettingsOpen: false,
				isGridUploadOpen: false,
			};
		case ui.UPDATE_UI_STATE:
			return {
				...state,
				[action.payload.key]: action.payload.value,
			};
		default:
			return state;
	}
}

// Action creators
export function openGridSettings() {
	return {
		type: ui.OPEN_GRID_SETTINGS,
	};
}

export function closeGridSettings() {
	return {
		type: ui.CLOSE_GRID_SETTINGS,
	};
}

export function openNotifications() {
	return {
		type: ui.OPEN_NOTIFICATIONS,
	};
}

export function closeNotifications() {
	return {
		type: ui.CLOSE_NOTIFICATIONS,
	};
}

export function openNav() {
	return {
		type: ui.OPEN_NAV,
	};
}

export function closeNav() {
	return {
		type: ui.CLOSE_NAV,
	};
}

export function showGridDetailsInfo() {
	return {
		type: ui.SHOW_GRID_DETAILS_INFO,
	};
}

export function hideGridDetailsInfo() {
	return {
		type: ui.HIDE_GRID_DETAILS_INFO,
	};
}

export function openGridUpload() {
	return {
		type: ui.OPEN_GRID_UPLOAD,
	};
}

export function closeGridUpload() {
	return {
		type: ui.CLOSE_GRID_UPLOAD,
	};
}

export function closeOverlays() {
	return {
		type: ui.CLOSE_OVERLAYS,
	};
}

export function updateUIState(key: ValueOf<typeof UIStateKeys>, value: any) {
	return {
		type: ui.UPDATE_UI_STATE,
		payload: {
			key,
			value,
		},
	};
}

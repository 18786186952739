import { type ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import SVReportItemsModal from '@apps/www/src/www/modals/SVReportItemsModal';
import SVModal from '@pkgs/shared-client/components/SVModal';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';

type Props = {
	items: ItemFragmentFragment[];
	onSuccess?: () => void;
	render: (props: { onClick: (event: React.UIEvent) => void }) => JSX.Element;
};

{
	/* <SVActions.Item title="Report as inappropriate" keys={'r'} onClick={handleClick} {...otherProps}>
			Report
		</SVActions.Item> */
}

const SVReportItemsActionContainer = ({ items, onSuccess, render }: Props) => {
	const handleClick = useEventCallback(async () => {
		SVModal.open(SVReportItemsModal, { items, onSuccess });
	});

	return <>{render({ onClick: handleClick })}</>;
};

export default SVReportItemsActionContainer;

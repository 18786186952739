import { useQuery } from '@apollo/client';
import { type User } from '@apps/www/src/__generated__/graphql';
import createAuthUserQuery from '@apps/www/src/www/queries/createAuthUserQuery';

const useAuthUser = <T extends keyof User>(fields: T[]): Pick<User, T | '_id'> | null => {
	const { data } = useQuery(createAuthUserQuery(fields), { fetchPolicy: 'cache-only' });

	return data?.auth?.user || null;
};

export default useAuthUser;

/**
 * Check if user has at least PRO privileges.
 *
 * This indicates that the user either:
 * - Has any active subscription
 * - Is member of a team with a TEAM/ENTERPRISE subscription
 * - Has special flags like isBeta, isModerator...
 */

import useAuthActiveSubscriptionTiers from '@apps/www/src/www/hooks/useAuthActiveSubscriptionTiers';
import SubscriptionTier from '@pkgs/shared/enums/SubscriptionTier';
import subscriptionTiersHasSubscriptionTierPrivileges from '@pkgs/shared/helpers/subscriptionTiersHasSubscriptionTierPrivileges';

export default function useAuthHasAtLeastProPrivileges() {
	const tiers = useAuthActiveSubscriptionTiers();

	return subscriptionTiersHasSubscriptionTierPrivileges(tiers, SubscriptionTier.PRO);
}

import {
	type Board,
	type BoardFragmentFragment,
	type ItemFragmentFragment,
} from '@apps/www/src/__generated__/graphql';
import SVBoardThumbnail from '@pkgs/shared-client/components/SVBoardThumbnail';
import IconCloseSmallSVG from '@pkgs/shared-client/img/icon-close-small-inlined.svg';
import React from 'react';
import { createSelector } from 'reselect';
import { twMerge } from 'tailwind-merge';
import SVButton, { SVButtonSIZES, SVButtonUSES } from './SVButton';
import SVDropdown from './SVDropdown';
import SVDropdownContent from './SVDropdownContent';
import SVIconButton from './SVIconButton';
import clsx from 'clsx';

const shownBoardsSelector = createSelector(
	(props: Props) => props.boards || [],
	(props: Props) => props.selectedBoardIDs,
	(boards, selectedBoardIDs) =>
		boards.filter((board) => {
			return !!selectedBoardIDs.find((selectedBoardID) => {
				return selectedBoardID == board._id;
			});
		}),
);

const availableBoardsSelector = createSelector(
	(props: Props) => props.boards || [],
	(props: Props) => props.selectedBoardIDs,
	(boards, selectedBoardIDs) =>
		boards.filter((board) => {
			return !selectedBoardIDs.find((selectedBoardID) => {
				return selectedBoardID == board._id;
			});
		}),
);

const _ItemButton = React.memo(
	({
		board,
		onRemove,
		canRemove,
	}: { board: BoardFragmentFragment; canRemove: boolean } & Pick<Props, 'onRemove'>) => (
		<li className="type-label flex h-9 flex-shrink-0 items-center">
			<span
				className={twMerge(
					'flex h-9 items-center space-x-2 whitespace-nowrap rounded-lg bg-gray-700 p-1.5 leading-[26px] text-gray-200 ',
					canRemove ? 'pr-3' : 'pr-4',
				)}
			>
				<SVBoardThumbnail
					className="h-6 w-6 rounded-md"
					thumbnails={board.thumbnails}
					isPrivate={false}
				/>
				<span>{board.name}</span>
				{canRemove && (
					<SVIconButton
						onClick={() => onRemove(board)}
						src={IconCloseSmallSVG}
						iconClassName="h-[10px] w-[10px] min-w-[10px] min-h-[10px]"
						label="Remove"
					/>
				)}
			</span>
		</li>
	),
);

const _AddButton = React.memo(
	React.forwardRef<
		SVDropdown,
		{ availableBoards: BoardFragmentFragment[] } & Pick<Props, 'onAdd' | 'onNew'>
	>(({ availableBoards, onAdd, onNew }, ref) => (
		<SVDropdown
			ref={ref}
			key="add"
			triggerType={SVDropdown.TRIGGER_TYPES.CLICK}
			positionStrategy={SVDropdown.POSITION_STRATEGIES.DYNAMIC}
			renderTrigger={({ isOpen: _, ...props }) => (
				<div {...props} className="-mt-2 pt-2">
					<SVButton
						className="flex-center h-[36px] rounded-lg py-0"
						use={SVButtonUSES.PRIMARY}
						size={SVButtonSIZES.TINY}
						Component="button"
					>
						Add to board
					</SVButton>
				</div>
			)}
			renderContent={() => (
				<div className="bg-gray-900">
					<SVDropdownContent.Boards
						boards={availableBoards}
						onBoardClick={onAdd}
						onNewBoard={onNew}
						showTitle={false}
					/>
				</div>
			)}
			maxHeight={520}
		/>
	)),
);

type Props = {
	boards: BoardFragmentFragment[];
	selectedBoardIDs: Board['_id'][];
	items: ItemFragmentFragment[];
	onRemove: (board: BoardFragmentFragment) => void;
	onAdd: (board: BoardFragmentFragment) => void;
	onNew: React.MouseEventHandler;
	canRemove?: (board: BoardFragmentFragment) => boolean;
};

class SVTagList extends React.Component<Props> {
	dropdownRef = React.createRef<SVDropdown>();

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.items != nextProps.items && this.dropdownRef.current) {
			this.dropdownRef.current.close();
		}
	}

	render() {
		const { onRemove, onAdd, onNew, canRemove } = this.props;

		const shownBoards = shownBoardsSelector(this.props);
		const availableBoards = availableBoardsSelector(this.props);

		return (
			<ul className={clsx('flex w-full items-center', shownBoards.length > 0 && 'gap-x-2')}>
				<div className="flex w-auto max-w-[85%] flex-row-reverse items-center gap-x-2 gap-y-2 overflow-hidden">
					{shownBoards.map((board) => (
						<div className="flex-shrink-0" key={board._id}>
							<_ItemButton
								board={board}
								onRemove={onRemove}
								canRemove={!canRemove || canRemove(board)}
							/>
						</div>
					))}
				</div>
				<_AddButton
					ref={this.dropdownRef}
					availableBoards={availableBoards}
					onAdd={onAdd}
					onNew={onNew}
				/>
			</ul>
		);
	}
}

export default SVTagList;

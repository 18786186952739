import IconCloseSVG from '@pkgs/shared-client/img/icon-close-inlined.svg';
import clsx from 'clsx';
import SVButton, { SVButtonUSES } from './SVButton';
import SVIconButton from './SVIconButton';
import SVLink from './SVLink';
import SVSelectFilesButton from './SVSelectFilesButton';

type Props = {
	isLogged: boolean;
	onSelectFiles: (files: FileList) => void;
	isDropping: boolean;
	onClose: () => void;
};

const SVUploadOverlayContent = ({ isLogged, onSelectFiles, isDropping, onClose }: Props) => {
	return (
		<div
			className={clsx(
				'flex-center theme-dark text-primary flex-1 flex-col self-stretch justify-self-stretch bg-black',
				isDropping && 'ring-primary ring-8 ring-inset',
			)}
		>
			{!isDropping && (
				<div className="flex w-full justify-end p-4">
					<SVIconButton onClick={onClose} src={IconCloseSVG} label="Close" />
				</div>
			)}
			<div className="flex-center padding-6 type-huge hover-none:invisible flex-1 text-center font-semibold">
				{isDropping ? 'Sweet, now let it go.' : `Just drop your images or videos here.`}
			</div>
			{!isDropping && (
				<div className="flex-center -sm:flex-col pt-4 pb-12">
					<SVButton
						Component={SVSelectFilesButton}
						multiple={true}
						onSelect={onSelectFiles}
						use={SVButtonUSES.DEFAULT}
						isLogged={isLogged}
					>
						Select from device
					</SVButton>
					<span className="px-5 py-3">or</span>
					<SVButton
						Component={SVLink}
						to="/import/"
						use={SVButtonUSES.DEFAULT}
						onClick={onClose}
					>
						Import from Pinterest
					</SVButton>
				</div>
			)}
		</div>
	);
};

export default SVUploadOverlayContent;

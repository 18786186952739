import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type TrackRelatedItemClickMutationMutation,
	type TrackRelatedItemClickMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const TrackRelatedItemClickMutation: TypedDocumentNode<
	TrackRelatedItemClickMutationMutation,
	TrackRelatedItemClickMutationMutationVariables
> = gql`
	mutation TrackRelatedItemClickMutation($input: TrackRelatedItemClickInput!) {
		trackRelatedItemClick(input: $input)
	}
`;

export default TrackRelatedItemClickMutation;

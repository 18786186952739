/**
 * Shows a global loading status if `isLoading` prop is true or global error status if `error` prop is provided.
 */

import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import { useEffect } from 'react';
import { useUnmount } from 'react-use';

type Props = {
	isLoading: boolean;
	children: () => JSX.Element | null;
};

const SVGlobalLoadingWrapper = ({ isLoading, children }: Props) => {
	const [showLoading, setShowLoading] = useUIState(UIStateKeys.SHOW_LOADING);

	useEffect(() => {
		if (showLoading !== isLoading) {
			setShowLoading(isLoading);
		}
	}, [isLoading, showLoading, setShowLoading]);

	useUnmount(() => {
		setShowLoading(false);
	});

	if (showLoading || isLoading) {
		return null;
	}

	return children();
};

export default SVGlobalLoadingWrapper;

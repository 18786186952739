import { twMerge } from 'tailwind-merge';

const videoTagString = (props: Props) => {
	const classString = props.className ? ` class="${props.className}"` : ``;
	const muted = props.muted ? ` muted` : ``;
	const autoPlay = props.autoPlay ? ` autoplay` : ``;
	const playsInline = props.playsInline ? ` playsinline` : ``;
	const loop = props.loop ? ` loop` : ``;
	const poster = props.poster ? ` poster="${props.poster}"` : ``;
	const width = props.width ? ` width="${props.width}"` : ``;
	const height = props.height ? ` height="${props.height}"` : ``;
	const responsiveStyles =
		props.width && props.height
			? 'style="position: absolute; left: 0; top: 0; width: 100%; height: 100%;"'
			: ``;

	const sources = props.sources.map(
		(source) =>
			`<source src="${source}" type="${
				source.endsWith('.mp4') ? 'video/mp4' : 'video/webm'
			}">`,
	);

	return `<video ${responsiveStyles}${classString}${muted}${autoPlay}${playsInline}${loop}${poster}${width}${height}>${sources}Bummer, your browser does not support the video tag.</video>`;
};

type Props = {
	sources: string[];
	videoClassName?: string;
	muted?: boolean;
	autoPlay?: boolean;
	playsInline?: boolean;
	loop?: boolean;
	poster?: string;
	className?: string;
	width?: number;
	height?: number;
};

const SVVideo = ({
	sources,
	videoClassName,
	muted,
	autoPlay,
	playsInline,
	loop,
	poster,
	className,
	width,
	height,
}: Props) => (
	<div
		className={twMerge('relative w-full', className)}
		style={{
			...(height && width ? { paddingBottom: `${(height / width) * 100}%` } : {}),
		}}
	>
		<span
			dangerouslySetInnerHTML={{
				__html: videoTagString({
					sources,
					className: videoClassName,
					muted,
					autoPlay,
					playsInline,
					loop,
					poster,
					width,
					height,
				}),
			}}
		/>
	</div>
);

export default SVVideo;

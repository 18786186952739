import formatCount from '@pkgs/shared-client/helpers/formatCount';

export default function plural(
	count: number,
	singularLabel: string,
	pluralLabel?: string | null,
	omitCount?: boolean,
) {
	pluralLabel = pluralLabel || singularLabel + 's';

	return (
		(omitCount ? '' : formatCount(count) + ' ') + (count === 1 ? singularLabel : pluralLabel)
	);
}

import IconLoadingIndicatorSVG from '@pkgs/shared-client/img/loading-indicator-inlined.svg';
import clsx from 'clsx';

const SIZES = {
	PAGE: 'page',
	DEFAULT: 'default',
} as const;

const defaultProps: {
	size: ValueOf<typeof SIZES>;
} = {
	size: SIZES.DEFAULT,
};

type Props = Partial<typeof defaultProps> & {
	size?: ValueOf<typeof SIZES>;
};

const SVLoadingIndicator = ({ size }: Props) => {
	return (
		<div
			role="progressbar"
			className={clsx(
				'flex-center text-primary',
				size === SIZES.PAGE &&
					'flex-center z-index-max bg-background p-nav-height fixed inset-0 h-full w-full',
				size !== SIZES.PAGE && 'p-12',
			)}
		>
			<IconLoadingIndicatorSVG className={clsx(size !== SIZES.PAGE && 'h-10 w-10')} />
		</div>
	);
};

SVLoadingIndicator.defaultProps = defaultProps;

SVLoadingIndicator.SIZES = SIZES;

export default SVLoadingIndicator;

/**
 * Returns the authUser board role from the current board or grid page
 * Also applies to other pages that are not particularly boards but can have roles (like own items, feed, search...)
 */
import BoardUserRole from '@pkgs/shared/enums/BoardUserRole';
import useAuthUserID from './useAuthUserID';
import useUserAndBoardPageQuery from './useUserAndBoardPageQuery';

const useGridPageRole = (): ValueOf<typeof BoardUserRole> | null => {
	const authUserID = useAuthUserID();
	const { user: pageUser, board: pageBoard } = useUserAndBoardPageQuery();

	// For pages that are not boards, like user own items
	if (pageUser && authUserID === pageUser._id) {
		return BoardUserRole.OWNER;
	}

	if (pageBoard?.role) {
		return pageBoard.role;
	}

	return null;
};

export default useGridPageRole;

import { useApolloClient } from '@apollo/client';
import usePaymentButtonInfo from '@apps/www/src/www/hooks/usePaymentButtonInfo';
import StartStripeCheckoutMutation from '@apps/www/src/www/queries/StartStripeCheckoutMutation';
import StartStripeUpdateBillingMethodMutation from '@apps/www/src/www/queries/StartStripeUpdateBillingMethodMutation';
import UpdateBillingPlanMutation from '@apps/www/src/www/queries/UpdateBillingPlanMutation';
import SVButton, { SVButtonSIZES, SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import PaymentButtonType from '@pkgs/shared/enums/PaymentButtonType';
import { getSubscriptionTierAndBillingCycleKey } from '@pkgs/shared/plans';
import { forwardRef, useImperativeHandle, useState } from 'react';

type Props = {
	className?: string;
	id?: string;
	size?: ValueOf<typeof SVButtonSIZES>;
	submitLabel?: string;
} & Parameters<typeof usePaymentButtonInfo>[0];

const SVStripePaymentButtonContainer = forwardRef(function SVStripePaymentButtonContainer(
	{ className, id, size = SVButtonSIZES.WIDE, submitLabel = 'Continue with card', ...otherProps }: Props,
	ref,
) {
	const client = useApolloClient();
	const [isLoading, setIsLoading] = useState(false);

	const { buttonType, type, plan, hasActivePlan } = usePaymentButtonInfo(otherProps);

	const handleCheckoutClick = useEventCallback(async () => {
		if (!plan) {
			return;
		}

		setIsLoading(true);

		try {
			let redirectURL: string | undefined | null = undefined;

			// Only update plan if user is already subscribed
			if (buttonType === PaymentButtonType.SUBSCRIBE && hasActivePlan) {
				const { data } = await client.mutate({
					mutation: UpdateBillingPlanMutation,
					variables: {
						input: {
							type,
							tierAndBillingCycle: getSubscriptionTierAndBillingCycleKey(plan),
						},
					},
				});

				redirectURL = data?.updateBillingPlan.redirectURL;

				// If successful, just redirect to billing page
				if (data && !redirectURL) {
					redirectURL = '/billing/';
				}
			} else if (buttonType === PaymentButtonType.EDIT_PAYMENT_METHOD) {
				const { data } = await client.mutate({
					mutation: StartStripeUpdateBillingMethodMutation,
					variables: {
						input: {
							type,
						},
					},
				});

				redirectURL = data?.startStripeUpdateBillingMethod.url;
			} else {
				// New subscription or renewal
				const { data } = await client.mutate({
					mutation: StartStripeCheckoutMutation,
					variables: {
						input: {
							type,
							planID: plan.stripePlanID,
						},
					},
				});

				redirectURL = data?.startStripeCheckout.url;
			}

			if (redirectURL) {
				const url = redirectURL;

				setTimeout(() => {
					// Redirect to checkout page
					window.location.href = url;
				});

				// Let it show as loading while we redirect...
				return;
			}
		} catch (e) {
			const error = e instanceof Error ? e : new Error('Unknown error');
			alert(error.message);
		}

		setIsLoading(false);
	});

	useImperativeHandle(ref, () => ({
		handleCheckoutClick,
	}));

	if (buttonType === PaymentButtonType.EDIT_PAYMENT_METHOD) {
		submitLabel = 'Change your payment method';
	} else if (buttonType === PaymentButtonType.SUBSCRIBE && hasActivePlan) {
		submitLabel = 'Change to this plan';
	}

	return (
		<SVButton
			use={
				buttonType === PaymentButtonType.EDIT_PAYMENT_METHOD
					? SVButtonUSES.DEFAULT
					: SVButtonUSES.PRIMARY
			}
			size={size}
			onClick={handleCheckoutClick}
			isLoading={isLoading}
			id={id}
			className={className}
		>
			{submitLabel}
		</SVButton>
	);
});

export default SVStripePaymentButtonContainer;

import { useApolloClient, useMutation } from '@apollo/client';
import { type UserFragmentFragment } from '@apps/www/src/__generated__/graphql';
import useAllowedBoardsToSave from '@apps/www/src/www/hooks/useAllowedBoardsToSave';
import useAllowedFeatureCheckEvent from '@apps/www/src/www/hooks/useAllowedFeatureCheckEvent';
import useAuthUser from '@apps/www/src/www/hooks/useAuthUser';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import SVNewBoardModal from '@apps/www/src/www/modals/SVNewBoardModal';
import BoardItemsQuery from '@apps/www/src/www/queries/BoardItemsQuery';
import DeleteItemsMutation from '@apps/www/src/www/queries/DeleteItemsMutation';
import SaveItemsMutation from '@apps/www/src/www/queries/SaveItemsMutation';
import TeamFeedItemsQuery from '@apps/www/src/www/queries/TeamFeedItemsQuery';
import UserItemsQuery from '@apps/www/src/www/queries/UserItemsQuery';
import SVDropdown from '@pkgs/shared-client/components/SVDropdown';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import type { LikeItem } from '@pkgs/shared-client/components/SVGrid';
import SVModal from '@pkgs/shared-client/components/SVModal';
import fieldNameFromQuery from '@pkgs/shared-client/helpers/fieldNameFromQuery';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import AllowedFeature from '@pkgs/shared/enums/AllowedFeature';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';
import { useEffect } from 'react';
import SVWithTooltipErrorBoundary, {
	type Props as SVWithTooltipErrorBoundaryProps,
} from './SVWithTooltipErrorBoundary';

type ButtonProps = {
	isSaved: boolean;
	isLoading: boolean;
	itemsCount: number;
	onClick: (event: React.UIEvent) => void;
};

type Props = {
	items: LikeItem[];
	renderButton: (props: ButtonProps) => JSX.Element;
} & SVWithTooltipErrorBoundaryProps;

const _SVSaveButtonContainer = ({ renderButton, items, showTooltipError }: Props) => {
	const client = useApolloClient();
	const isLoggedIn = useIsLoggedIn();
	const boards = useAllowedBoardsToSave(items);
	const authUser = useAuthUser(['username']);

	const [saveItems, { loading: saveLoading, error: saveError }] = useMutation(SaveItemsMutation);
	const [deleteItems, { loading: deleteLoading, error: deleteError }] =
		useMutation(DeleteItemsMutation);

	const isSaved = !items.some((item) => !item.asset.isSaved); // true, unless there is one unsaved item
	const itemsCount = items.reduce((sum, item) => sum + item.asset.itemsCount, 0);

	const handleSaveItems = useEventCallback(async (boardID: string | null = null) => {
		if (!items.length) {
			return;
		}

		if (!isSaved || !authUser) {
			await saveItems({
				variables: {
					input: {
						itemIDs: items.map((item) => item._id),
						boardID,
					},
				},
			});

			if (authUser) {
				let fieldName = fieldNameFromQuery(UserItemsQuery, {
					username: authUser.username,
				});

				if (fieldName) {
					client.cache.evict({
						id: 'ROOT_QUERY',
						fieldName,
						broadcast: false,
					});
				}

				if (boardID) {
					fieldName = fieldNameFromQuery(BoardItemsQuery, { boardID });

					if (fieldName) {
						client.cache.evict({
							id: 'ROOT_QUERY',
							fieldName,
							broadcast: false,
						});
					}
				}

				// Remove team items feed query from cache to refetch when necessary.
				// In case the team feed includes the user own saves
				fieldName = fieldNameFromQuery(TeamFeedItemsQuery);
				if (fieldName) {
					client.cache.evict({
						id: 'ROOT_QUERY',
						fieldName,
						broadcast: false,
					});
				}
			}
		} else {
			const { data } = await deleteItems({
				variables: {
					input: {
						itemIDs: items.map((item) => item._id),
					},
				},
			});

			// Evict own item from cache so lists get updated
			data?.deleteItems.deletedItemIDs.forEach((deletedItemID) => {
				client.cache.evict({
					id: client.cache.identify({ __typename: 'Item', _id: deletedItemID }),
				});
			});
		}

		client.cache.gc();
	});

	const handleSave = useEventCallback((event: React.UIEvent) => {
		if (!items.length) {
			return;
		}

		handleSaveItems();

		if (event) {
			event.preventDefault();
			event.stopPropagation();
			return false;
		}
	});

	const handleSaveToBoard = useEventCallback(
		(board: ArrayElement<UserFragmentFragment['boards']>) => {
			if (!items.length) {
				return;
			}

			handleSaveItems(board._id);
		},
	);

	const handleSaveToNewBoard = useAllowedFeatureCheckEvent(AllowedFeature.ADD_BOARD, () => {
		SVModal.open(SVNewBoardModal, {
			items,
			ownershipType: BoardOwnershipType.USER,
		});
	});

	const anyError = saveError || deleteError;

	useEffect(() => {
		showTooltipError(anyError);
	}, [showTooltipError, anyError]);

	const buttonProps = {
		isSaved,
		isLoading: saveLoading || deleteLoading,
		itemsCount,
		onClick: handleSave,
	};

	if (isSaved || !isLoggedIn) {
		return <>{renderButton(buttonProps)}</>;
	}

	return (
		<SVDropdown
			triggerType={SVDropdown.TRIGGER_TYPES.LONGPRESS}
			renderTrigger={({ isOpen: _, ...props }) => renderButton({ ...props, ...buttonProps })}
			renderContent={() => (
				<SVDropdownContent.Boards
					boards={boards}
					onNewBoard={handleSaveToNewBoard}
					onBoardClick={handleSaveToBoard}
				/>
			)}
			maxHeight={520}
		/>
	);
};

const SVSaveButtonContainer = SVWithTooltipErrorBoundary(_SVSaveButtonContainer);

export default SVSaveButtonContainer;

import {
	useApolloClient,
	useQuery,
	type OperationVariables,
	type QueryHookOptions,
	type TypedDocumentNode,
} from '@apollo/client';
import fieldNameFromQuery from '@pkgs/shared-client/helpers/fieldNameFromQuery';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import get from 'lodash/get';
import set from 'lodash/set';
import { useEffect, useRef, useState } from 'react';
import { useUnmount } from 'react-use';

function getResultsKey<T extends AnyObject>(
	node: T | undefined,
	stack: string[] = [],
): string | null {
	if (!node) {
		return null;
	}

	// Iterate over the data object tree until it reaches the expected results object
	const keys = Object.keys(node);

	for (let i = 0, l = keys.length; i < l; i++) {
		const key = keys[i];
		const value = node[key];

		if (typeof value === 'object') {
			if (value.items && value.pageInfo) {
				return stack.concat(key).join('.');
			} else {
				stack.push(key);
				const maybeKey = getResultsKey(value, stack);
				stack.pop();

				if (maybeKey) {
					return maybeKey;
				}
			}
		}
	}

	return null;
}

function setAndCopy<T extends AnyObject>(object: T, path: string, value: any) {
	let current = object;

	path.split('.').every((key) => {
		const value = current[key];

		if (!value) {
			return false;
		}

		const newValue = { ...value };

		// @ts-expect-error
		current[key] = newValue;

		current = newValue;

		return true;
	});

	set(object, path, value);
}

const getResultsData = <
	T extends AnyObject,
	TResultsDataType extends AnyObject = ExtractResultsDataType<T>,
	TItems = ExtractItemType<TResultsDataType>,
>(
	data: T,
	resultsKey: string,
): {
	items: TItems;
	pageInfo: {
		nextCursor: string;
	};
} => {
	return get(data, resultsKey);
};

type ExtractResultsDataType<T> = T extends {
	items: any;
	pageInfo: {
		nextCursor?: string | null;
	};
}
	? T
	: T extends AnyObject
	? ExtractResultsDataType<T[Extract<keyof T, string>]>
	: never;

type ExtractItemType<T> = T extends { items: infer TItems } ? TItems : never;

// Cleanup the cache on unmount if desired fetch policy is `no-cache`
const useEvictOnUmount = <T extends object, V extends OperationVariables>(
	query: TypedDocumentNode<T, V>,
	variablesWithoutCursor: V | undefined,
	enabled: boolean,
) => {
	const client = useApolloClient();
	const fieldName = fieldNameFromQuery(query, variablesWithoutCursor);

	const fieldNamesRef = useRef<Set<string>>(new Set<string>());

	useEffect(() => {
		if (fieldName) {
			if (enabled) {
				fieldNamesRef.current.add(fieldName);
			} else {
				fieldNamesRef.current.delete(fieldName);
			}
		}
	}, [enabled, fieldName]);

	useUnmount(() => {
		if (enabled) {
			fieldNamesRef.current.forEach((fieldName) => {
				client.cache.evict({
					id: 'ROOT_QUERY',
					fieldName,
					broadcast: false,
				});
			});

			client.cache.gc();
		}
	});
};

const usePaginatedQuery = <T extends object, V extends OperationVariables>(
	query: TypedDocumentNode<T, V>,
	queryOptions: QueryHookOptions<T, V> = {},
) => {
	const variablesWithoutCursor = queryOptions.variables;

	if (variablesWithoutCursor && 'cursor' in variablesWithoutCursor) {
		delete variablesWithoutCursor.cursor;
	}

	const isPaginatingRef = useRef<boolean>(false);
	const [paginationError, setPaginationError] = useState<Error | null>(null);
	const { data, loading, error, fetchMore } = useQuery(query, {
		...queryOptions,
		variables: variablesWithoutCursor,
		notifyOnNetworkStatusChange: true,
		// Needs to use cache-first otherwise it can't paginate
		fetchPolicy: 'cache-first',
	});

	// If desired fetch policy is no-cache, then we cleanup the cache on unmount
	useEvictOnUmount(query, variablesWithoutCursor, queryOptions.fetchPolicy === 'no-cache');

	const resultsKey = getResultsKey(data);
	const paginatedData = resultsKey && data ? getResultsData(data, resultsKey) : null;

	const handlePagination = useEventCallback(async () => {
		if (!resultsKey || !paginatedData || isPaginatingRef.current) {
			return;
		}

		isPaginatingRef.current = true;

		try {
			await fetchMore({
				variables: {
					...queryOptions.variables,
					cursor: paginatedData.pageInfo.nextCursor,
				},
				updateQuery: (prev, { fetchMoreResult }) => {
					if (!fetchMoreResult) {
						return prev;
					}

					const merged = { ...prev };

					setAndCopy(merged, resultsKey, {
						...getResultsData(prev, resultsKey),
						items: [
							...getResultsData(prev, resultsKey).items,
							...getResultsData(fetchMoreResult, resultsKey).items,
						],
						pageInfo: getResultsData(fetchMoreResult, resultsKey).pageInfo,
					});

					return merged;
				},
			});
		} catch (e) {
			const error = e instanceof Error ? e : new Error('Unknown error');
			setPaginationError(error);
		}

		isPaginatingRef.current = false;
	});

	const anyError = error || paginationError;

	if (anyError) {
		throw anyError;
	}

	return {
		items: paginatedData?.items,
		loading: loading || !data,
		paginate: paginatedData?.pageInfo.nextCursor && !loading ? handlePagination : null,
	} as const;
};

export default usePaginatedQuery;

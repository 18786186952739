import { BoardFragmentFragment } from '@apps/www/src/__generated__/graphql';
import IconLockSVG from '@pkgs/shared-client/img/icon-lock-inlined.svg';
import { twMerge } from 'tailwind-merge';

const _ThumbItem = ({ imageURL }: { imageURL: string }) => (
	<div
		className="flex-1 basis-1/2 bg-gray-800 bg-cover bg-center bg-no-repeat"
		style={{ backgroundImage: `url('${imageURL}')` }}
	/>
);

const _PrivateIcon = () => (
	<div className="absolute left-2 top-2 flex items-center justify-center rounded-full bg-black/50 p-2">
		<IconLockSVG className="text-primary h-4 w-4" />
	</div>
);

export default function SVBoardThumbnail({
	thumbnails,
	isPrivate,
	className,
}: Pick<BoardFragmentFragment, 'thumbnails' | 'isPrivate'> & { className?: string }) {
	return (
		<div
			className={twMerge(
				'duration-over theme-dark relative bg-gray-800 transition-opacity ease-out group-hover:opacity-80',
				className,
			)}
		>
			<div className="absolute flex h-full w-full flex-col items-stretch space-y-0.5">
				{(thumbnails?.length || 0) > 0 ? (
					<_ThumbItem imageURL={thumbnails[0].image.thumbnail} />
				) : null}
				{(thumbnails?.length || 0) > 1 ? (
					<div className="flex basis-1/2 flex-row items-stretch space-x-0.5">
						<_ThumbItem imageURL={thumbnails[1].image.thumbnail} />
						{(thumbnails?.length || 0) > 2 ? (
							<_ThumbItem imageURL={thumbnails[2].image.thumbnail} />
						) : null}
					</div>
				) : null}
			</div>
			{isPrivate && <_PrivateIcon />}
		</div>
	);
}

/**
 * Unlogged or free users shouldn't be able to right click on the website, adding an incovenience barrier
 * to prevent people from save the inspiration for free.
 */

import useAllowedFeatureCheckEvent from '@apps/www/src/www/hooks/useAllowedFeatureCheckEvent';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import AllowedFeature from '@pkgs/shared/enums/AllowedFeature';
import { useEffect } from 'react';

export default function SVPreventRightClickController() {
	const isLoggedIn = useIsLoggedIn();

	const checkRightClick = useAllowedFeatureCheckEvent(AllowedFeature.RIGHT_CLICK);

	const handleRightClick = useEventCallback((e: MouseEvent) => {
		// If not logged in, just block
		if (!isLoggedIn) {
			e.preventDefault();
			return false;
		}

		return checkRightClick(e);
	});

	useEffect(() => {
		document.addEventListener('contextmenu', handleRightClick);

		return () => {
			document.removeEventListener('contextmenu', handleRightClick);
		};
	}, [handleRightClick]);

	return null;
}

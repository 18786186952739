import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type DeleteItemsMutationMutation,
	type DeleteItemsMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';
import BoardFragment from './fragments/BoardFragment';

const DeleteItemsMutation: TypedDocumentNode<
	DeleteItemsMutationMutation,
	DeleteItemsMutationMutationVariables
> = gql`
	${BoardFragment}

	mutation DeleteItemsMutation($input: DeleteItemsInput!) {
		deleteItems(input: $input) {
			auth {
				user {
					_id
					boards {
						...BoardFragment
					}
					itemsCount
				}
				settings {
					_id
					needsUpgradeReason
				}
			}
			assets {
				_id
				isSaved
				itemsCount
				ownBoards {
					_id
				}
			}
			deletedItemIDs
		}
	}
`;

export default DeleteItemsMutation;

import reducers, { type RootState } from '@apps/www/src/www/reducers';
import { composeWithDevTools } from '@redux-devtools/extension';
import { useMemo } from 'react';
import { applyMiddleware, createStore, type Store } from 'redux';
import promise from 'redux-promise';
import thunk from 'redux-thunk';

let store: Store<RootState> | undefined;

const middlewares = [promise, thunk];

function initStore(initialState: Partial<RootState> = {}): Store<RootState> {
	const store = createStore<RootState>(
		reducers,
		initialState as RootState,
		composeWithDevTools(applyMiddleware(...middlewares)) as any,
	);

	return store;
}

export const initializeStore = (
	preloadedState: Partial<RootState> | undefined = undefined,
): ReturnType<typeof initStore> => {
	const _store = store ?? initStore(preloadedState);

	// After navigating to a page with an initial Redux state, merge that state
	// with the current state in the store, and create a new store
	// TODO: (next) If we need to use `getServerSideProps` on any page, fix this first. this causes a bug in the provider that it can't have a different store after the initial provided one
	// if (preloadedState && store) {
	// 	_store = initStore({
	// 		...store.getState(),
	// 		...preloadedState,
	// 	});
	// 	// Reset the current store
	// 	store = undefined;
	// }

	// For SSG and SSR always create a new store
	if (typeof window === 'undefined') return _store;
	// Create the store once in the client
	if (!store) store = _store;

	return _store;
};

export function useStore(initialState: Partial<RootState>) {
	const store = useMemo(() => initializeStore(initialState), [initialState]);
	return store;
}

import { useMutation } from '@apollo/client';
import { type ItemFragmentFragment, type List } from '@apps/www/src/__generated__/graphql';
import useAllowedFeatureCheckEvent from '@apps/www/src/www/hooks/useAllowedFeatureCheckEvent';
import NewListMutation from '@apps/www/src/www/queries/NewListMutation';
import type SVActions from '@pkgs/shared-client/components/SVActions';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import type AllowedFeature from '@pkgs/shared/enums/AllowedFeature';
import React, { useEffect, useRef } from 'react';
import sha256 from 'sha256';
import SVWithTooltipErrorBoundary, {
	type Props as SVWithTooltipErrorBoundaryProps,
} from './SVWithTooltipErrorBoundary';

const getItemsHash = (items: ItemFragmentFragment[]) => {
	return sha256(
		items
			.map((item) => item._id)
			.sort()
			.join('-'),
	);
};

type Props = React.PropsWithChildren<
	{
		items: ItemFragmentFragment[];
		onCreate: (listURL: List['url']) => void;
		action?: ValueOf<typeof AllowedFeature>;
	} & (
		| {
				Component: typeof SVDropdownContent.Links.Item;
				title?: string;
		  }
		| {
				Component: typeof SVActions.Item;
				title?: string;
		  }
		| {
				Component: typeof SVDropdownContent.Links.ItemPreventClose;
				title?: string;
		  }
	)
>;

const _SVCreateListActionContainer = ({
	Component,
	items,
	action,
	onCreate,
	showTooltipError,
	forwardedRef,
	...otherProps
}: Props &
	SVWithTooltipErrorBoundaryProps & {
		forwardedRef: React.Ref<HTMLButtonElement & HTMLDivElement>;
	}) => {
	const lastItemsHashRef = useRef<string | null>(null);
	const lastItemsURLRef = useRef<string | null>(null);

	const [newList, { loading, error }] = useMutation(NewListMutation);

	const handleClick = useAllowedFeatureCheckEvent(action, async () => {
		const itemsHash = getItemsHash(items);

		if (itemsHash !== lastItemsHashRef.current) {
			lastItemsHashRef.current = itemsHash;

			const { data } = await newList({
				variables: {
					input: { itemIDs: items.map((item) => item._id) },
				},
			});

			lastItemsURLRef.current = data?.newList.url || null;
		}

		if (lastItemsURLRef.current) {
			onCreate(lastItemsURLRef.current);
		}
	});

	useEffect(() => {
		showTooltipError(error);
	}, [showTooltipError, error]);

	return (
		<Component
			Component={Component === SVDropdownContent.Links.Item ? 'button' : undefined}
			ref={forwardedRef}
			onClick={handleClick}
			isLoading={loading}
			{...otherProps}
		/>
	);
};
const _SVCreateListActionContainerComposed = SVWithTooltipErrorBoundary(
	_SVCreateListActionContainer,
);

const SVCreateListActionContainer = React.forwardRef<HTMLButtonElement & HTMLDivElement, Props>(
	(props, forwardedRef) => (
		<_SVCreateListActionContainerComposed {...props} forwardedRef={forwardedRef} />
	),
);

export default SVCreateListActionContainer;

import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type ReportItemsMutationMutation,
	type ReportItemsMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const ReportItemsMutation: TypedDocumentNode<
	ReportItemsMutationMutation,
	ReportItemsMutationMutationVariables
> = gql`
	mutation ReportItemsMutation($input: ReportItemsInput!) {
		reportItems(input: $input) {
			reportedItemIDs
		}
	}
`;

export default ReportItemsMutation;

import { gql } from '@apollo/client';

const BoardCollaboratorUserFragment = gql`
	fragment BoardCollaboratorUserFragment on User {
		_id
		username
		name
		avatarURL
		url
		isPro
	}
`;

export default BoardCollaboratorUserFragment;

import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type DismissMegaphoneMutationMutation,
	type DismissMegaphoneMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const DismissMegaphoneMutation: TypedDocumentNode<
	DismissMegaphoneMutationMutation,
	DismissMegaphoneMutationMutationVariables
> = gql`
	mutation DismissMegaphoneMutation($input: DismissMegaphoneInput!) {
		dismissMegaphone(input: $input) {
			_id
		}
	}
`;

export default DismissMegaphoneMutation;

const BoardUserRole = {
	// Same as admin but is the original board creator
	OWNER: 'owner',
	// Can delete board, edit details, invite and remove users, add/remove any item from board (plus editor)
	ADMIN: 'admin',
	// Can add items to board, only remove own items from board
	EDITOR: 'editor',
	// Can only view items, even private items (also use this while user is pending invite accept)
	VIEWER: 'viewer',
} as const;

export default BoardUserRole;

import { useApolloClient } from '@apollo/client';
import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import useAuthUserID from '@apps/www/src/www/hooks/useAuthUserID';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import createUpdateAuthSettingsMutation from '@apps/www/src/www/queries/createUpdateAuthSettingsMutation';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import { useRouter } from 'next/router';

const useOnboard = (): [boolean, () => void] => {
	const [isUIOpen, setUIOpen] = useUIState(UIStateKeys.MODAL_ONBOARD_OPEN);
	const authUserID = useAuthUserID();
	const authSettings = useAuthSettings(['onboard']);
	const client = useApolloClient();
	const router = useRouter();

	const isHomepage = router.pathname === '/';
	const onboardDismissed = !authSettings || authSettings?.onboard;
	const isOpen = (isUIOpen || !onboardDismissed) && isHomepage;

	const dismiss = useEventCallback(() => {
		if (!onboardDismissed && authUserID && authSettings) {
			client.mutate({
				mutation: createUpdateAuthSettingsMutation(['onboard']),
				variables: { input: { onboard: true } },
				optimisticResponse: {
					updateAuthSettings: {
						__typename: 'Auth',
						_id: authUserID,
						// @ts-ignore fix createUpdateAuthSettingsMutation return type
						settings: {
							__typename: 'AuthSettings',
							_id: authSettings._id,
							onboard: true,
						},
					},
				},
			});
		}

		setUIOpen(false);
	});

	return [isOpen, dismiss];
};

export default useOnboard;

import SVModal, { useModalClose } from '@pkgs/shared-client/components/SVModal';
import SVOnboardingModalContent from '@pkgs/shared-client/components/SVOnboardingModalContent';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import { useState } from 'react';

const _Modal = SVModal.create(() => {
	const close = useModalClose();
	const [step, setStep] = useState<1 | 2 | 3 | 4>(1);
	const handleNext = useEventCallback(() => {
		if (step === 4) {
			close();
			return;
		}

		// @ts-expect-error TS is dumb here
		setStep(step + 1);
	});

	const Component = SVOnboardingModalContent[`Step${step}`];

	return <Component onNext={handleNext} />;
});

const SVOnboardingModalContainer = () => {
	// const [isOpen, dismiss] = useOnboard();

	// if (!isOpen) {
	// 	return null;
	// }

	// return <SVModal.Visible Component={_Modal} onClose={dismiss} />;

	// Disabled onboarding for now, too ugly
	return null;
};

export default SVOnboardingModalContainer;

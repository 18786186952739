import { gql } from '@apollo/client';
import BoardCollaboratorUserFragment from './BoardCollaboratorUserFragment';

const BoardCollaboratorFragment = gql`
	${BoardCollaboratorUserFragment}

	fragment BoardCollaboratorFragment on BoardCollaborator {
		_id
		user {
			...BoardCollaboratorUserFragment
		}
		role
	}
`;

export default BoardCollaboratorFragment;

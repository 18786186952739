import type React from 'react';
import { Children, isValidElement } from 'react';

const hasChildren = (element: JSX.Element) =>
	// @ts-ignore FIX children
	isValidElement(element) && Boolean(element.props.children);

const childToString = (child: any): string => {
	if (typeof child === 'undefined' || child === null || typeof child === 'boolean') {
		return '';
	}

	if (JSON.stringify(child) === '{}') {
		return '';
	}

	return child.toString();
};

const childrenOnlyText = (children: React.ReactNode) => {
	const isArray = children instanceof Array;

	if (!isArray && !isValidElement(children)) {
		return childToString(children);
	}

	return (isArray ? children : Children.toArray(children))
		.reduce((text: string, child) => {
			let newText = '';

			if (isValidElement(child) && hasChildren(child)) {
				// @ts-ignore FIX children
				newText = childrenOnlyText(child.props.children);
			} else if (isValidElement(child) && !hasChildren(child)) {
				newText = ' ';
			} else {
				newText = childToString(child);
			}

			return text.concat(newText);
		}, '')
		.trim();
};

export default childrenOnlyText;

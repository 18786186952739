import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
	TeamFragmentFragment,
	type ItemDetailsQuery as ItemDetailsQueryQuery,
	type ItemFragmentFragment,
} from '@apps/www/src/__generated__/graphql';
import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import useFeatureFlag from '@apps/www/src/www/hooks/useFeatureFlag';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import SVEditItemModal from '@apps/www/src/www/modals/SVEditItemModal';
import DeleteCommentMutation from '@apps/www/src/www/queries/DeleteCommentMutation';
import ItemDetailsQuery from '@apps/www/src/www/queries/ItemDetailsQuery';
import NewCommentMutation from '@apps/www/src/www/queries/NewCommentMutation';
import ToggleLikeCommentMutation from '@apps/www/src/www/queries/ToggleLikeCommentMutation';
import internalUpdateAssetTags from '@apps/www/src/www/queries/internalUpdateAssetTagsMutation';
import { type RootState } from '@apps/www/src/www/reducers';
import { hideGridDetailsInfo, showGridDetailsInfo } from '@apps/www/src/www/reducers/ui';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import SVGridDetailOverlayContent from '@pkgs/shared-client/components/SVGridDetailOverlayContent';
import { createElementIDWithShortID } from '@pkgs/shared-client/components/SVGridItem';
import SVItemBoardsList from '@pkgs/shared-client/components/SVItemBoardsList';
import SVKeyboardKey from '@pkgs/shared-client/components/SVKeyboardKey';
import type SVLink from '@pkgs/shared-client/components/SVLink';
import SVModal from '@pkgs/shared-client/components/SVModal';
import { scrollToElement } from '@pkgs/shared-client/helpers/dom';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import ViewportKind from '@pkgs/shared/enums/ViewportKind';
import { useRouter, type NextRouter } from 'next/router';
import React from 'react';
import withFullscreen from 'react-fullscreenable';
import { ConnectedProps, connect } from 'react-redux';
import { useUnmount } from 'react-use';
import SVDeleteItemsActionContainer from './SVDeleteItemsActionContainer';
import SVItemBoardsContainer from './SVItemBoardsContainer';
import SVPageMeta from './SVPageMeta';
import SVReportItemsActionContainer from './SVReportItemsActionContainer';
import SVShareActionContainer from './SVShareActionContainer';
import SVToggleItemsPrivacyActionContainer from './SVToggleItemsPrivacyActionContainer';

const mapStateToProps = (state: RootState) => ({
	isInfoShown: state.ui.isGridDetailsInfoShown,
	uploadingItemsLength: state.gridUpload.items.length,
});

const connector = connect(mapStateToProps, {
	showGridDetailsInfo,
	hideGridDetailsInfo,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

const scrollToItem = (item: ItemFragmentFragment) => {
	const itemElement = document.querySelector(
		`#${createElementIDWithShortID(item.shortID)}`,
	) as HTMLElement;

	if (itemElement) {
		scrollToElement(itemElement, 0, false);
	}
};

type InnerComposedProps = PropsFromRedux &
	InnerProps & {
		isFullscreen: boolean;
		toggleFullscreen: () => void;
		commentMode: boolean;
		newCoordsComment: null | { x: number; y: number };
	};

class _SVGridDetailOverlayContainerInner extends React.Component<InnerComposedProps> {
	UNSAFE_componentWillReceiveProps(nextProps: InnerComposedProps) {
		// Close modal if user starts uploading something
		if (nextProps.uploadingItemsLength > this.props.uploadingItemsLength) {
			nextProps.router.push(this.props.closeURL);
		}
	}

	componentDidUpdate() {
		const { items, item } = this.props;

		// Preload next and previous images
		if (items && items.length) {
			const itemIndex = items.indexOf(item);

			if (itemIndex < items.length - 1) {
				this.props.onPreloadItem(items[itemIndex + 1]);
			}

			if (itemIndex > 0) {
				this.props.onPreloadItem(items[itemIndex - 1]);
			}

			// Scroll grid behind to keep current item visible and paginate properly
			scrollToItem(item);
		}
	}

	handleToggleInfo = () => {
		if (this.props.isInfoShown) {
			this.props.hideGridDetailsInfo();
		} else {
			this.props.showGridDetailsInfo();
		}
	};

	handleDeleteOrReportSuccess = () => {
		this.props.router.replace(this.props.closeURL, undefined, { scroll: false });
	};

	handleEdit = () => {
		SVModal.open(SVEditItemModal, {
			_id: this.props.item._id,
			name: this.props.item.name,
			pageURL: this.props.item.pageURL,
		});
	};

	handleDeleteComment = (commentID: string) => {
		this.props.deleteComment(commentID);
	};

	handleToggleLikeComment = (_id: string) => {
		this.props.toggleLikeComment(_id);
	};

	commentAdd = (comment: {
		assetID: string;
		content: string;
		isTeamOnly: boolean;
		parentId?: string | null;
		position?: { x: number; y: number };
	}) => {
		this.props.handleCommentAdd(comment);
	};

	_renderDropdownContent = () => {
		const {
			item,
			isLoggedIn,
			// isInfoShown,
		} = this.props;

		const isOwner = item.isOwner;

		const actionItems = [item];

		return (
			<SVDropdownContent.Links>
				{isLoggedIn && isOwner && (
					<SVToggleItemsPrivacyActionContainer
						items={actionItems}
						render={(props) => <SVDropdownContent.Links.ItemToggle {...props} />}
					/>
				)}
				{!item.isPrivate && (
					<SVShareActionContainer
						Component={SVDropdownContent.Links.ItemPreventClose}
						title="Share image"
						url={item.url}
					>
						Share
					</SVShareActionContainer>
				)}
				{isLoggedIn && isOwner && (
					<SVDeleteItemsActionContainer
						items={actionItems}
						onSuccess={this.handleDeleteOrReportSuccess}
						render={(props) => (
							<SVDropdownContent.Links.ItemPreventClose
								keys="backspace"
								title="Delete image"
								{...props}
							>
								Delete
							</SVDropdownContent.Links.ItemPreventClose>
						)}
					/>
				)}
				{isLoggedIn && !isOwner && (
					<SVReportItemsActionContainer
						items={actionItems}
						onSuccess={this.handleDeleteOrReportSuccess}
						render={(props) => (
							<SVDropdownContent.Links.ItemPreventClose
								title="Report as inappropriate"
								keys={'r'}
								{...props}
							>
								Report
							</SVDropdownContent.Links.ItemPreventClose>
						)}
					/>
				)}
			</SVDropdownContent.Links>
		);
	};

	render() {
		const {
			closeURL: _,
			items,
			item,
			isLoggedIn,
			isFullscreen,
			toggleFullscreen,
			isSmallAny: ____,
			router,
			isLoadingDetails,
			itemDetails,
			userTeam,
			canSeeComments,
			...otherProps
		} = this.props;

		if (!item) {
			return null;
		}

		let prevURL: React.ComponentProps<typeof SVLink>['to'] | null = null;
		let prevURLAs: string | null = null;
		let nextURL: React.ComponentProps<typeof SVLink>['to'] | null = null;
		let nextURLAs: string | null = null;
		const isOwner = item.isOwner;

		if (items && items.length) {
			const itemIndex = items.indexOf(item);

			if (itemIndex > 0) {
				prevURL = {
					pathname: router.pathname,
					query: { ...router.query, itemShortID: items[itemIndex - 1].shortID },
				};
				prevURLAs = items[itemIndex - 1].url;
			}

			if (itemIndex < items.length - 1) {
				nextURL = {
					pathname: router.pathname,
					query: { ...router.query, itemShortID: items[itemIndex + 1].shortID },
				};
				nextURLAs = items[itemIndex + 1].url;
			}
		}

		const boardList = isLoggedIn ? (
			<SVItemBoardsContainer Component={SVItemBoardsList} items={[item]} />
		) : null;

		const meta = {
			title: `${item.user.name} - ${item.name}`,
			image: itemDetails?.asset.ogImage || undefined,
		};

		return (
			<>
				<SVPageMeta {...meta} />
				<SVGridDetailOverlayContent
					isLoadingDetails={isLoadingDetails}
					isFullscreen={isFullscreen}
					isOwner={isOwner}
					prevURL={prevURL}
					prevURLAs={prevURLAs}
					nextURL={nextURL}
					nextURLAs={nextURLAs}
					onToggleInfo={this.handleToggleInfo}
					onEdit={this.handleEdit}
					closeURL={this.props.closeURL}
					renderDropdownContent={this._renderDropdownContent}
					boardList={boardList}
					handleToggleLikeComment={this.handleToggleLikeComment}
					commentAdd={this.commentAdd}
					userTeam={userTeam}
					canSeeComments={canSeeComments}
					handleDeleteComment={this.handleDeleteComment}
					{...SVGridDetailOverlayContent.itemToProps(item, itemDetails)}
					{...otherProps}
				>
					<SVKeyboardKey onTrigger={toggleFullscreen} keys={'n'} />
				</SVGridDetailOverlayContent>
			</>
		);
	}
}

const SVGridDetailOverlayContainerInner: React.ComponentType<InnerProps> = connector(
	withFullscreen()(_SVGridDetailOverlayContainerInner),
);

type Props = {
	item: ItemFragmentFragment;
	items: ItemFragmentFragment[];
	closeURL?: React.ComponentProps<typeof SVLink>['to'];
};

const SVGridDetailOverlayContainer = ({ item, closeURL, ...props }: Props) => {
	const client = useApolloClient();
	const isLoggedIn = useIsLoggedIn();
	const [viewportKind] = useUIState(UIStateKeys.VIEWPORT_KIND);
	const router = useRouter();
	const [skipItemDetails] = useUIState(UIStateKeys.SKIP_ITEM_DETAILS);
	const [newComment] = useMutation(NewCommentMutation);
	const [toggleLike] = useMutation(ToggleLikeCommentMutation);
	const [deleteComment] = useMutation(DeleteCommentMutation);
	const [updateAssetTags] = useMutation(internalUpdateAssetTags);
	const canSeeComments = useFeatureFlag('comments');

	const userTeam = useAuthTeam();

	const isSmallAny = viewportKind === ViewportKind.MOBILE;

	let effectiveCloseURL = closeURL;

	if (!effectiveCloseURL) {
		effectiveCloseURL = item.user.url;
	} else {
		effectiveCloseURL = effectiveCloseURL || '/';
	}

	// @ts-expect-error
	if (effectiveCloseURL?.query?.commentID) {
		// @ts-expect-error
		delete effectiveCloseURL.query.commentID;
	}

	const handlePreloadItem = useEventCallback(async (item: ItemFragmentFragment) => {
		SVGridDetailOverlayContent.preloadItem(item);

		try {
			await client.query({
				query: ItemDetailsQuery,
				variables: { shortID: item.shortID },
			});
		} catch (e) {
			// Ignore error
		}
	});

	const handleUpdateAssetTags = useEventCallback(async (assetID: string, tags: string[]) => {
		await updateAssetTags({
			variables: { input: { assetID, tags } },
		});
	});

	useUnmount(() => {
		if (item) {
			scrollToItem(item);
		}
	});

	// No need to check for errors here as details are not that important for the experience
	const { loading: loadingDetails, data: itemDetails } = useQuery(ItemDetailsQuery, {
		variables: { shortID: item.shortID },
		skip: skipItemDetails,
	});

	const toggleLikeComment = useEventCallback(async (commentID: string) => {
		await toggleLike({
			variables: {
				input: {
					commentID: commentID,
				},
			},
		});
	});

	const handleDeleteComment = useEventCallback(async (commentID: string) => {
		await deleteComment({
			variables: {
				input: {
					commentID: commentID,
				},
			},
		});

		client.cache.evict({
			id: client.cache.identify({ __typename: 'Comment', _id: commentID }),
		});

		client.cache.gc();
	});

	const handleCommentAdd = useEventCallback(
		async (params: {
			assetID: string;
			content: string;
			isTeamOnly: boolean;
			parentId?: string | null;
			position?: { x: number; y: number };
		}) => {
			await newComment({
				variables: {
					input: {
						assetID: params.assetID,
						content: params.content,
						isTeamOnly: params.isTeamOnly,
						parentID: params.parentId,
						position: params.position,
					},
				},
			});
			if (!params.parentId) {
				const commentList = document.getElementById('infoSideBar') as HTMLElement;

				if (commentList) {
					setTimeout(() => {
						scrollToElement(commentList, commentList.scrollHeight, true, true);
					}, 100);
				}
			}
		},
	);

	return (
		<SVGridDetailOverlayContainerInner
			{...props}
			closeURL={effectiveCloseURL}
			item={item}
			itemDetails={itemDetails?.itemByShortID || null}
			isLoadingDetails={loadingDetails}
			isLoggedIn={isLoggedIn}
			handleUpdateAssetTags={handleUpdateAssetTags}
			isSmallAny={isSmallAny}
			router={router}
			onPreloadItem={handlePreloadItem}
			toggleLikeComment={toggleLikeComment}
			handleCommentAdd={handleCommentAdd}
			canSeeComments={canSeeComments}
			deleteComment={handleDeleteComment}
			userTeam={userTeam}
		/>
	);
};

type InnerProps = Pick<Props, 'item' | 'items'> & {
	closeURL: React.ComponentProps<typeof SVLink>['to'];
	itemDetails: ItemDetailsQueryQuery['itemByShortID'] | null;
	isLoadingDetails: boolean;
	isLoggedIn: boolean;
	isSmallAny: boolean;
	router: NextRouter;
	onPreloadItem: (item: ItemFragmentFragment) => void;
	handleUpdateAssetTags: (assetID: string, tags: string[]) => void;
	toggleLikeComment: (_id: string) => void;
	handleCommentAdd: (params: {
		assetID: string;
		content: string;
		isTeamOnly: boolean;
		parentId?: string | null;
		position?: { x: number; y: number };
	}) => void;
	canSeeComments: boolean;
	deleteComment: (commentID: string) => void;
	userTeam: TeamFragmentFragment | null | undefined;
};

SVGridDetailOverlayContainer.scrollToItem = scrollToItem;

export default SVGridDetailOverlayContainer;

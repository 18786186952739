import { Asset } from '@apps/www/src/__generated__/graphql';
import { type GridColumnsConfigID, type GridSpacingsConfigID } from '@pkgs/shared/constants';
import { useMemo } from 'react';
import SVGrid from './SVGrid';
import SVGridItem from './SVGridItem';

const RATIOS = [
	0.8, 1, 0.5, 0.9, 0.95, 0.8, 1, 1.2, 0.6, 0.65, 0.45, 0.85, 0.8, 0.9, 1.2, 1.1, 0.9, 0.85, 0.95,
	1, 0.75, 0.7, 0.6, 0.8, 1.2, 1.1, 1.1, 1.05, 1.2, 0.6, 0.65, 0.45, 0.85, 0.8, 0.9, 1.2, 1.1,
	0.85, 0.95, 1, 0.75, 0.7, 0.6, 0.8, 1.2, 1.1, 1.1, 1.05, 1.2, 0.6, 0.65, 0.45,
];

type Props = {
	spacingConfigID: GridSpacingsConfigID;
	columnsConfigID: GridColumnsConfigID;
	animation?: boolean;
	count?: number; // number of skeleton items in the grid
};

const SVGridLoading = ({
	spacingConfigID,
	columnsConfigID,
	animation = true,
	count = RATIOS.length,
}: Props) => {
	const items = useMemo(() => {
		return RATIOS.slice(0, Math.min(RATIOS.length, count)).map((ratio, index) => {
			const c = 0 + ((ratio - 0.6) / (1.2 - 0.6)) * 90;

			return {
				_id: String(index),
				shortID: String(index),
				asset: {
					image: { ratio, thumbnail: '', original: '' },
					colors: [{ color: `rgba(${c},${c},${c},1)` }],
					isSaved: false,
					type: 'image' as Asset['type'],
					itemsCount: 0,
				},
				url: '',
				isOwner: false,
			};
		});
	}, [count]);

	return (
		<SVGrid
			spacingConfigID={spacingConfigID}
			columnsConfigID={columnsConfigID}
			items={items}
			isStatic={true}
			isSkeleton={true}
			keyExtractor={(item) => item._id}
			renderItem={(item, props) => (
				<SVGridItem
					{...SVGridItem.itemToProps(item)}
					{...props}
					forceThumbnail={true}
					animation={animation}
				/>
			)}
		/>
	);
};

export default SVGridLoading;

import { gql, type TypedDocumentNode } from '@apollo/client';
import { type AuthQueryQuery, type AuthSettings } from '@apps/www/src/__generated__/graphql';
import { wrap } from 'optimism';

const getFieldsArray = (fields: (keyof AuthSettings)[]) => {
	const fieldsArray = fields.sort();

	if (!fieldsArray.includes('_id')) {
		fieldsArray.push('_id');
	}

	return fieldsArray;
};

type QueryType<K extends keyof AuthSettings> = Omit<AuthQueryQuery, 'auth'> & {
	auth: Omit<AuthQueryQuery['auth'], 'user' | 'settings'> & {
		settings: Pick<AuthSettings, K>;
	};
};

const createAuthSettingsQuery = wrap(
	<T extends keyof AuthSettings>(fields: T[]): TypedDocumentNode<QueryType<T | '_id'>> => {
		const fieldsArray = getFieldsArray(fields);

		return gql`
			query AuthSettingsQuery_${fieldsArray.join('_')} {
				auth {
					settings {
						${fieldsArray.join('\n')}
					}
				}
			}
		`;
	},
	{
		makeCacheKey: (fields) => getFieldsArray(fields).join('-'),
	},
);

export default createAuthSettingsQuery;

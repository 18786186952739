/**
 * Use this for query parameters that can be either a string or an array of strings.
 */

export default function parseQueryParam(param: string | string[] | undefined): string | undefined {
	if (param === undefined) {
		return undefined;
	} else if (Array.isArray(param)) {
		return param.length > 0 ? param[0] : undefined;
	}

	return param;
}

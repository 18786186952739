import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type ToggleLikeCommentMutationMutation,
	type ToggleLikeCommentMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const ToggleLikeCommentMutation: TypedDocumentNode<
	ToggleLikeCommentMutationMutation,
	ToggleLikeCommentMutationMutationVariables
> = gql`
	mutation ToggleLikeCommentMutation($input: ToggleLikeCommentInput!) {
		toggleLikeComment(input: $input) {
			comment {
				_id
				isLiked
				likesCount
			}
		}
	}
`;

export default ToggleLikeCommentMutation;

import { useApolloClient } from '@apollo/client';
import {
	type BoardItemsQueryQuery,
	type UserItemsQueryQuery,
	type UserQueryQuery,
} from '@apps/www/src/__generated__/graphql';
import BoardItemsQuery from '@apps/www/src/www/queries/BoardItemsQuery';
import ReorderItemsMutation from '@apps/www/src/www/queries/ReorderItemsMutation';
import UserItemsQuery from '@apps/www/src/www/queries/UserItemsQuery';
import { type RootState } from '@apps/www/src/www/reducers';
import { sortLoading, sortSuccess, toggleItemSort } from '@apps/www/src/www/reducers/grid';
import type { Props as SVGridProps } from '@pkgs/shared-client/components/SVGrid';
import {
	GRID_ITEM_CLASS_NAME,
	createElementIDWithShortID,
	getShortIDFromElementID,
} from '@pkgs/shared-client/components/SVGridItem';
import SVGridItemSorting from '@pkgs/shared-client/components/SVGridItemSorting';
import { offsetLeft, offsetTop } from '@pkgs/shared-client/helpers/dom';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import type ItemsSortMethod from '@pkgs/shared/enums/ItemsSortMethod';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

type Position = {
	x: number;
	y: number;
	y2?: number;
};

type Item = ArrayElement<NonNullable<SVGridProps['items']>>;

const calcDistance = (middleX: number, middleY: number, position: Position) => {
	if (!position.y2) {
		return Math.sqrt(Math.pow(position.x - middleX, 2) + Math.pow(position.y - middleY, 2));
	} else if (middleY <= position.y) {
		return Math.sqrt(Math.pow(position.x - middleX, 2) + Math.pow(position.y - middleY, 2));
	} else if (middleY >= position.y2) {
		return Math.sqrt(Math.pow(position.x - middleX, 2) + Math.pow(position.y2 - middleY, 2));
	}

	return Math.abs(position.x - middleX);
};

const mapStateToProps = (state: RootState) => ({
	sortingItemID: state.grid.sortingItemID,
});

const connector = connect(mapStateToProps, {
	toggleItemSort,
	sortSuccess,
	sortLoading,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type InnerProps = Pick<Props, 'sortSuccess' | 'toggleItemSort' | 'sortingItemID'> & {
	items: NonNullable<Props['items']>;
	onSaveSwapItems: (itemA: Item, itemB: Item) => Promise<void>;
	onSwapItems: (itemA: Item, itemB: Item) => void;
};

type State = {
	sortingItem: Item | null;
};

type ElementPosition = {
	element: HTMLElement;
	item: Item;
	position: Position;
};

class _SVGridSortingContainerInner extends React.Component<InnerProps, State> {
	state: State = {
		sortingItem: null,
	};

	elementRef = React.createRef<HTMLDivElement>();
	mouse = {
		x: 0,
		y: 0,
	};
	elementStart = {
		x: 0,
		y: 0,
		width: 0,
		height: 0,
	};
	mouseStart = {
		x: 0,
		y: 0,
	};
	mouseCheck = {
		x: 0,
		y: 0,
	};
	startIndex = 0;
	currentIndex = 0;

	elementsPositions: ElementPosition[] = [];

	componentDidMount() {
		document.addEventListener('mousemove', this.handleMouseMove);

		this._checkState(this.props);

		this.props.sortSuccess();
	}

	componentWillUnmount() {
		document.removeEventListener('mousemove', this.handleMouseMove);

		this._endSorting();
	}

	UNSAFE_componentWillReceiveProps(nextProps: InnerProps) {
		this._checkState(nextProps);
	}

	componentDidUpdate() {
		this._updateElementsPositionsCache();
		this._updatePosition();
	}

	handleMouseMove = (event: MouseEvent) => {
		this.mouse.x = event.pageX;
		this.mouse.y = event.pageY;

		this._updatePosition();
	};

	handleScroll = () => {
		this._updatePosition();
	};

	handleMouseUp = () => {
		if (!this.state.sortingItem) {
			return;
		}

		if (this.currentIndex != this.startIndex) {
			const itemA = this.state.sortingItem;
			const itemB =
				this.currentIndex > this.startIndex
					? this.props.items[this.currentIndex - 1]
					: this.props.items[this.currentIndex + 1];

			this.props.onSaveSwapItems(itemA, itemB);
		}

		this.props.toggleItemSort(this.state.sortingItem._id, false);
	};

	_updatePosition = (force = false) => {
		if (!this.state.sortingItem && !force) {
			return;
		}

		const elementX = this.elementStart.x + this.mouse.x - this.mouseStart.x;
		const elementY = this.elementStart.y + this.mouse.y - this.mouseStart.y;

		const element = this.elementRef.current;

		if (element) {
			element.style.left = elementX + 'px';
			element.style.top = elementY + 'px';
		}

		const middleX = elementX + this.elementStart.width * 0.5;
		const middleY = elementY + this.elementStart.height * 0.5;

		const distanceSinceLastCheck = Math.sqrt(
			Math.pow(this.mouseCheck.x - this.mouse.x, 2) +
				Math.pow(this.mouseCheck.y - this.mouse.y, 2),
		);

		if (distanceSinceLastCheck > 15) {
			this.mouseCheck.x = this.mouse.x;
			this.mouseCheck.y = this.mouse.y;

			const nearest: {
				elementData: ElementPosition;
				distance: number;
			} | null = this.elementsPositions.reduce(
				(
					nearest: {
						elementData: ElementPosition;
						distance: number;
					} | null,
					elementData,
				) => {
					const distance = calcDistance(middleX, middleY, elementData.position);
					// const distance = Math.sqrt(Math.pow(elementData.position.x - middleX, 2) + Math.pow(elementData.position.y - middleY, 2), 2);

					if (!nearest || distance < nearest.distance) {
						nearest = {
							elementData,
							distance,
						};
					}

					return nearest;
				},
				null,
			);

			if (
				this.state.sortingItem &&
				nearest &&
				nearest.elementData.item.shortID != this.state.sortingItem.shortID
			) {
				this.props.onSwapItems(this.state.sortingItem, nearest.elementData.item);
			}
		}
	};

	_startSorting = (sortingItem: Item) => {
		document.addEventListener('mouseup', this.handleMouseUp);
		window.addEventListener('scroll', this.handleScroll);

		const originalElement = document.querySelector(
			`#${createElementIDWithShortID(sortingItem.shortID)}`,
		) as HTMLElement;
		const metrics = getComputedStyle(originalElement);

		const element = this.elementRef.current;

		if (element) {
			element.style.width = metrics.width;
			element.style.height = metrics.height;
		}

		this.mouseStart.x = this.mouse.x;
		this.mouseStart.y = this.mouse.y;

		this.mouseCheck.x = this.mouseStart.x;
		this.mouseCheck.y = this.mouseStart.y;

		this.elementStart.x = offsetLeft(originalElement);
		this.elementStart.y = offsetTop(originalElement);
		this.elementStart.width = parseInt(metrics.width);
		this.elementStart.height = parseInt(metrics.height);

		const item = this.props.items.find((item) => item._id == sortingItem._id);
		if (item) {
			this.startIndex = this.props.items.indexOf(item);
			this.currentIndex = this.startIndex;
		}

		this._updateElementsPositionsCache();

		this._updatePosition(true);
	};

	_endSorting = () => {
		document.removeEventListener('mouseup', this.handleMouseUp);
		window.removeEventListener('scroll', this.handleScroll);
	};

	_checkState = (props: InnerProps) => {
		const { items, sortingItemID } = props;

		const sortingItem =
			(sortingItemID &&
				items &&
				items.length &&
				items.find((item) => item._id === sortingItemID)) ||
			null;

		this.setState({
			sortingItem,
		});

		if (sortingItem && !this.state.sortingItem) {
			this._startSorting(sortingItem);
		} else if (this.state.sortingItem && !sortingItem) {
			this._endSorting();
		} else if (sortingItem) {
			const index = items.indexOf(sortingItem);

			if (this.currentIndex != index) {
				this._updateElementsPositionsCache();

				this.currentIndex = index;
			}
		}
	};

	_updateElementsPositionsCache = () => {
		// TODO: PERFORMANCE: filter elements that are inside view only?
		this.elementsPositions = Array.from(document.querySelectorAll(`.${GRID_ITEM_CLASS_NAME}`))
			.map((el) => {
				const element = el as HTMLElement;
				const itemID = getShortIDFromElementID(element.id);

				if (!itemID) {
					return null;
				}

				const item = this.props.items.find((item) => item.shortID == itemID);

				if (!item) {
					return null;
				}

				const metrics = getComputedStyle(element);

				const width = parseInt(metrics.width);
				const height = parseInt(metrics.height);

				const position: Position = {
					x: offsetLeft(element) + width * 0.5,
					y: offsetTop(element) + height * 0.5,
				};

				if (height > width) {
					const diff = (height - width) * 0.5;

					position.y2 = position.y + diff;
					position.y -= diff;
				}

				return {
					element,
					item,
					position,
				};
			})
			.filter(Boolean)
			// filter out uploading/queued items
			.filter((data) => !('upload' in data.item));
	};

	render() {
		const { sortingItem } = this.state;

		return (
			<SVGridItemSorting
				ref={this.elementRef}
				color={
					(sortingItem &&
						sortingItem.asset.colors.length &&
						sortingItem.asset.colors[0].color) ||
					undefined
				}
				thumbnail={(sortingItem && sortingItem.asset.image.thumbnail) || undefined}
			/>
		);
	}
}

type Props = PropsFromRedux & {
	items: NonNullable<SVGridProps['items']>;
	board: ArrayElement<UserQueryQuery['userByUsername']['boards']> | null;
	user: UserQueryQuery['userByUsername'];
	sortMethod: ValueOf<typeof ItemsSortMethod>;
};

const _SVGridSortingContainer = ({
	user,
	board,
	sortMethod,
	sortLoading,
	sortSuccess,
	...props
}: Props) => {
	const client = useApolloClient();

	const handleSwapItems = useEventCallback((fromItem: Item, toItem: Item) => {
		function updateData<TData extends AnyObject, TKey extends keyof TData = keyof TData>({
			data,
			dataKey,
		}: {
			data: TData;
			dataKey: TKey;
		}): TData {
			const items = [...data[dataKey].items.items];

			const fromItem2 = items.find((item) => item._id == fromItem._id);
			const fromItemIndex = items.indexOf(fromItem2);
			const toItem2 = items.find((item) => item._id == toItem._id);
			const toItemIndex = items.indexOf(toItem2);

			items.splice(fromItemIndex, 1);
			items.splice(toItemIndex, 0, fromItem2);

			return {
				...data,
				[dataKey]: {
					...data[dataKey],
					items: {
						...data[dataKey].items,
						items,
					},
				},
			};
		}

		// Update queries and swap items
		if (board) {
			client.cache.updateQuery(
				{
					query: BoardItemsQuery,
					variables: {
						boardID: board._id,
						sortMethod,
					},
				},
				(data) => {
					if (!data) {
						return null;
					}

					return updateData<BoardItemsQueryQuery>({
						data,
						dataKey: 'boardByID',
					});
				},
			);
		} else {
			client.cache.updateQuery(
				{
					query: UserItemsQuery,
					variables: {
						username: user.username,
						sortMethod,
					},
				},
				(data) => {
					if (!data) {
						return null;
					}

					return updateData<UserItemsQueryQuery>({
						data,
						dataKey: 'userByUsername',
					});
				},
			);
		}
	});

	const handleSaveSwapItems = useEventCallback(async (fromItem: Item, toItem: Item) => {
		sortLoading();

		try {
			await client.mutate({
				mutation: ReorderItemsMutation,
				variables: {
					input: {
						fromItemID: fromItem._id,
						toItemID: toItem._id,
						boardID: board?._id,
					},
				},
			});
			// eslint-disable-next-line no-empty
		} catch (e) {}

		sortSuccess();
	});

	return (
		<_SVGridSortingContainerInner
			{...props}
			sortSuccess={sortSuccess}
			onSwapItems={handleSwapItems}
			onSaveSwapItems={handleSaveSwapItems}
		/>
	);
};

const SVGridSortingContainer = connector(_SVGridSortingContainer);

export default SVGridSortingContainer;

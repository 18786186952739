import { gql, type TypedDocumentNode } from '@apollo/client';
import {
    type DeleteCommentMutationMutation,
    type DeleteCommentMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const DeleteCommentMutation: TypedDocumentNode<
    DeleteCommentMutationMutation,
    DeleteCommentMutationMutationVariables
> = gql`
	mutation DeleteCommentMutation($input: DeleteCommentInput!) {
		deleteComment(input: $input) {
            success
		}
	}
`;

export default DeleteCommentMutation;

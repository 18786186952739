import IntersectionObserverAdmin from 'intersection-observer-admin';
import React from 'react';

const SUPPORTS_INTERSECTION_OBSERVER =
	typeof window === 'object' && 'IntersectionObserver' in window;
const EMPTY_GIF =
	'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
const OBSERVER_OPTIONS = { rootMargin: '200px', threshold: 0.01 };

const cache = new Map();
const globalObserver = new IntersectionObserverAdmin();

type Props = {
	src: HTMLImageElement['src'];
	alt?: HTMLImageElement['alt'];
	className?: string;
	style?: React.HTMLProps<HTMLImageElement>['style'];
	decoding?: HTMLImageElement['decoding'];
};

class SVLazyImage extends React.PureComponent<Props> {
	state = {
		inView: !SUPPORTS_INTERSECTION_OBSERVER,
	};
	imageRef = React.createRef<HTMLImageElement>();
	_mounted = false;

	componentDidMount() {
		this._mounted = true;

		if (this.state.inView || cache.has(this.props.src) || !SUPPORTS_INTERSECTION_OBSERVER) {
			return;
		}

		if (this.imageRef.current) {
			globalObserver.addEnterCallback(this.imageRef.current, () => {
				this._removeObserver();

				setTimeout(() => {
					if (!this._mounted) {
						return;
					}

					this.setState({
						inView: true,
					});
				}, 0);
			});
			globalObserver.observe(this.imageRef.current, OBSERVER_OPTIONS);
		}
	}

	componentWillUnmount() {
		this._mounted = false;

		this._removeObserver();
	}

	componentDidUpdate() {
		const { src } = this.props;

		if (this.state.inView && !cache.has(src)) {
			cache.set(src, true);
		}
	}

	_removeObserver() {
		if (this.imageRef.current) {
			globalObserver.unobserve(this.imageRef.current, OBSERVER_OPTIONS);
		}
	}

	render() {
		const { src: imageSrc, alt, className, style, decoding } = this.props;
		const { inView } = this.state;

		const src = inView || cache.get(imageSrc) ? imageSrc : EMPTY_GIF;

		return (
			<img
				src={String(src)}
				ref={this.imageRef}
				alt={alt}
				className={className}
				style={style}
				decoding={decoding}
			/>
		);
	}
}

export default SVLazyImage;
